<template>
  <div class="container">
    <div class="top">
      <div class="buttons">
        <button :class="{'active': cur === 0}" @click="changeCur(0)">找专家</button>
        <button :class="{'active': cur === 1}" @click="changeCur(1)">找项目</button>
      </div>
      <p class="title">领先人才项目库 · 汇聚全球英才 · 探寻创新项目</p>
      <div class="input-line">
        <input class="input" v-model="searchKey" placeholder="请输入关键字" @keydown.enter="toSearch()"/>
        <div class="button" @click="toSearch()">
          <i class="el-icon-search" style="color:#fff;font-size: 16px;"></i>
        </div>
      </div>
      <div class="hot">
        <img src="@/assets/images/TPS/icon-0.png" style="width: 14px;height: 18px;margin-right: 8px;" v-if="hotSearch.length > 0"/>
        <img src="@/assets/images/TPS/icon-1.png" style="width: 38px;height: 16px;margin-right: 8px;" v-if="hotSearch.length > 0"/>
        <div class="option" v-for="(item,index) in hotSearch" :key="index" >
          <a target="_self" :title="item" :href="searchAHref(item)">{{ item }}</a>
        </div>
      </div>
    </div>
    <div class="input">
      <div class="filters">
        <div class="filter-line">
          <div class="filter">
            <p>行业分类：</p>
            <el-select v-model="filters1[4].value" class="e-select" placeholder="请选择" :disabled="filters1[4].val.length === 0" clearable >
              <el-option
                v-for="(item,index) in filters1[4].val"
                :key="index"
                :label="item.val"
                :value="item.id"/>
            </el-select>
          </div>
          <div class="filter">
            <p>成熟度：</p>
            <el-select v-model="filters1[1].value" class="e-select" placeholder="请选择" :disabled="filters1[1].val.length === 0" clearable >
              <el-option
                v-for="(item,index) in filters1[1].val"
                :key="index"
                :label="item.val"
                :value="item.id"/>
            </el-select>
          </div>
          <div class="filter">
            <p>八大领域：</p>
            <el-select v-model="filters1[0].value" class="e-select" placeholder="请选择" :disabled="filters1[0].val.length === 0" clearable >
              <el-option
                v-for="(item,index) in filters1[0].val"
                :key="index"
                :label="item.val"
                :value="item.id"/>
            </el-select>
          </div>
          <div class="filter">
            <p>技术类型：</p>
            <el-select v-model="filters1[2].value" class="e-select" placeholder="请选择" :disabled="filters1[2].val.length === 0" clearable >
              <el-option
                v-for="(item,index) in filters1[2].val"
                :key="index"
                :label="item.val"
                :value="item.id"/>
            </el-select>
          </div>
        </div>
        <div class="filter-line">
          <div class="filter">
            <p>合作类型：</p>
            <el-select v-model="filters1[3].value" class="e-select" placeholder="请选择" :disabled="filters1[3].val.length === 0" clearable >
              <el-option
                v-for="(item,index) in filters1[3].val"
                :key="index"
                :label="item.val"
                :value="item.id"/>
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="datas" v-if="total > 0">
        <div class="data">
          <SearchData :data="''" :keyword="$route.query.k ? $route.query.k: ''" style="width: 1200px;" :backGroundFFFFFF="false"  @setShowData="setShowData" ref="SearchData" :dynamics="total" :type="1"></SearchData>
          <DemandLine v-if="showData" />
        </div>
      </div>
      <div class="list-container">
        <div>
          <div class="map-container">
            <div class="map">
              <div class="map-controller flex-cc">
                <div class="left flex-cc">
                  <img src="@/assets/images/map/map-icon.png" alt="" style="width: 18px;height: 16px;margin-top: 6px;margin-right: 6px;">
                  数据看板
                </div>
                <div class="right" v-if="!showMap" @click="showMap = !showMap">
                  展开<i class="el-icon-arrow-down"></i>
                </div>
                <div class="right" v-else-if="showMap" @click="showMap = !showMap">
                  收起<i class="el-icon-arrow-up"></i>
                </div>
              </div>
              <div class="pie-data" v-if="showMap && pieData.length !== 0" >
                <div v-for="(item,index) in pieData" class="line" :key="index" >
                  <p class="name text-ol-ell">{{ item.name }}</p>
                  <el-progress :percentage="100" :stroke-width="16" :color="index % 2 === 1 ? '#44B46F' : '#2280FF'" stroke-linecap="butt" :style="`width: ${ 9 * item.percent}px;`"></el-progress>
                  <p class="percent text-ol-ell">{{ item.percent }}%</p>
                </div>
              </div>
              <div style="height: 335px;" v-else-if="showMap && pieData.length === 0" v-loading="pieData.length === 0">
              </div>
            </div>
          </div>
          <div class="map-container">
            <div class="experts">
              <div class="map-controller flex-cc">
                <div class="left flex-cc">
                  <img src="@/assets/images/map/map-icon.png" alt="" style="width: 18px;height: 16px;margin-top: 6px;margin-right: 6px;">
                  项目列表
                </div>
                <div class="right" @click="toSearch(true)">
                  查看全部项目
                </div>
              </div>
              <div v-if="loading">
                <div v-for="i in pageSize" :key="i" class="p-empty" :class="{'no-top': i === 1}" v-loading="loading"></div>
              </div>
              <div v-else>
                <div class="p-empty recommends" v-for="(item, index) in recommendList" :key="item.dynamicId" :class="{'no-top': index === 0}">
                  <div class="thumb" @click="toDetail(item)">
                    <Image :imgId="item.dynamicId" :src="item.thumb" :alt="item.name" />
                  </div>
                  <div class="infos">
                    <p class="title text-ol-ell">
                      <a target="_blank" :title="item.title" :href="detailAHref(item)">{{ item.title || '-' }}</a>
                    </p>
                    <p class="detail text-ol-ell">{{ item.detail || '-' }}</p>
                    <div class="flex-cc types">
                      <p class="type" style="margin-right: 30px;">合作类型：{{ item.cooperationType || '-'}}</p>
                      <p class="type">技术成熟度：{{ item.technologyMaturity || '-'}}</p>
                    </div>
                    <div class="keys">
                      <div v-for="(key,index) in item.keyWords" :key="index" class="key text-ol-ell" @click="toSearch(false,key)">
                        {{key}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pagination-container" style="width: 886px;" v-if="recommendList.length > 0">
                  <div class="pagination" style="width: 886px;">
                    <el-pagination
                      background
                      layout="prev, pager, next,jumper"
                      :total="total"
                      v-model:current-page="offset"
                      :page-size="pageSize"
                      @current-change="pageChange" >
                    </el-pagination>
                    <div class="page-size-number">
                      {{pageSize}}条/页
                    </div>
                  </div>
                </div>
                <div style="background-color: #ffffff;padding: 9px 0;" v-if="recommendList.length === 0">
                  <el-empty description="暂无相关专家"></el-empty>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right-recommend">
          <div class="img-button">
            <div class="button" @click="conversation()"></div>
            <img src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/10/29/1701238819216495" />
          </div>
          <div class="recommend-experts">
            <div class="title flex-cc">
              <img src="@/assets/images/map/map-icon.png" alt="" style="width: 18px;height: 16px;margin-top: 3px;margin-right: 6px;">
              专家推荐
            </div>
            <div v-if="techLoading" v-loading="techLoading">
              <div v-for="i in 2" :key="i" class="p-empty" :class="{'no-top': i === 1}"></div>
            </div>
            <div v-else>
              <div v-for="expert in list" :key="expert.id" class="expert">
                <div class="flex-cc">
                  <Image :imgId="expert.id" :src="expert.headImage" :isHeadImg="true" class="other-avatar" :isExpert="true" @click="toRecommendDetail(expert)"></Image>
                  <div class="right">
                    <p class="flex-cc text-ol-ell">
                      <span class="name" @click="toRecommendDetail(expert)">{{ expert.name }}</span>
                      <span class="education" v-if="expert.education">{{ expert.education }}</span>
                      <span class="position" v-if="expert.position">{{ expert.position }}</span>
                    </p>
                    <p class="belong text-ol-ell">
                      工作单位：{{ expert.belong }}
                    </p>
                  </div>
                </div>
                <div v-html="expert.direction" v-if="expert.direction" class="direction text-otl-ell"></div>
              </div>
              <div style="background-color: #ffffff;" v-if="list.length === 0">
                <el-empty description="暂无相关项目"></el-empty>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DemandLine from "./components/DemandLine.vue"
import SearchData from "./components/SearchData.vue";
import { getLocalInfo } from '@/utils/talent-project-system/login';
import { randomExpert } from "@/api/organization";
import { getHotSearch,areaAdmin } from "@/api/search";
import { searchTechnology } from "@/api/TPS";
import { delHtmlTag,routerWindow } from '@/utils/tool';
import { getSelectFatherSonByType } from "@/api/config";
import { ElMessage } from "element-plus";

export default{
  components:{
    DemandLine,
    SearchData,
  },
  data(){
    return{
      showData: false,
      total: 0,
      pageSize: 20,
      offset: 1,
      industry: '',
      maturity: '',
      area: '',
      type: '',
      cooperate: '',
      searchKey: '',
      showMap: false,
      cur: 0,
      info: {},
      loading: false,
      techLoading: false,
      popVisible: false,
      list: [],
      recommendList: [],
      hotSearch: [],
      pieData: [],
      filters1: [{
        val: [],
        value: '',
        loading: true,
      },{
        val: [],
        value: '',
        loading: true,
      },{
        val: [],
        value: '',
        loading: true,
      },{
        val: [],
        value: '',
        loading: true,
      },{
        val: [],
        value: '',
        loading: true,
      }],
    }
  },
  computed:{
    indus(){
      return this.filters1[4].value
    },
    matu(){
      return this.filters1[1].value
    },
    ar(){
      return this.filters1[0].value
    },
    ty(){
      return this.filters1[2].value
    },
    coo(){
      return this.filters1[3].value
    },
  },
  watch:{
    coo(val){
      this.$router.push({
        paths: '/talent-project-projects',
        query:{
          k: this.searchKey,
          i: this.industry,
          m: this.maturity,
          a: this.area,
          t: this.type,
          c: val,
          o: 1,
        }
      })
    },
    ty(val){
      this.$router.push({
        paths: '/talent-project-projects',
        query:{
          k: this.searchKey,
          i: this.industry,
          m: this.maturity,
          a: this.area,
          t: val,
          c: this.cooperate,
          o: 1,
        }
      })
    },
    ar(val){
      this.$router.push({
        paths: '/talent-project-projects',
        query:{
          k: this.searchKey,
          i: this.industry,
          m: this.maturity,
          a: val,
          t: this.type,
          c: this.cooperate,
          o: 1,
        }
      })
    },
    matu(val){
      this.$router.push({
        paths: '/talent-project-projects',
        query:{
          k: this.searchKey,
          i: this.industry,
          m: val + '',
          a: this.area,
          t: this.type,
          c: this.cooperate,
          o: 1,
        }
      })
    },
    indus(val){
      this.$router.push({
        paths: '/talent-project-projects',
        query:{
          k: this.searchKey,
          i: val + '',
          m: this.maturity,
          a: this.area,
          t: this.type,
          c: this.cooperate,
          o: 1,
        }
      })
    },
    $route(to){
      if(to.path !== '/talent-project-projects') return
      this.getQuery()
    }
  },
  mounted(){
    this.info = getLocalInfo()?.memberInfo
    this.getQuery()
    this.getAreaAdmin()
  },
  methods:{
    getAreaAdmin(){
      areaAdmin().then(res=>{
        this.pieData = res.data.list.sort((a,b)=>a.count-b.count).map((item)=>{
          const num = Math.ceil(item.count * 100 / res.data.total)
          return{
            percent: num,
            value: item.count,
            name: item.text
          }
        }).sort((a,b)=>b.percent-a.percent).reverse()
      })
    },
    conversation(){
      this.$store.commit("message/SET_OPEN_SERVICE_WINDOW");
    },
    setShowData(val){  // 判断是否展示数据栏
      this.showData = val
    },
    pageChange(val){
      // 切换页码
      this.$router.push({
        paths: '/talent-project-projects',
        query:{
          k: this.searchKey,
          i: this.industry,
          m: this.maturity,
          a: this.area,
          t: this.type,
          c: this.cooperate,
          o: val,
        }
      })
    },
    toDetail(item){
      switch (this.cur) {
        case 0:
          routerWindow(this.$router,"/talent-project-detail",{expertId: item.id,})
          break
        case 1:
          routerWindow(this.$router,"/talent-project-detail",{dynamicId: item.dynamicId,})
          break
        default:
        break
      }
    },
    toSearch(type=false,word){
      if(!this.searchKey && !type && !word){
        ElMessage.warning('请输入关键词!')
        return
      }
      else if(type){
        routerWindow(this.$router,"/talent-project-projects",{})
      }
      else if(word){
        this.$router.push({
          paths: '/talent-project-projects',
          query:{
            k: word,
            i: this.industry,
            m: this.maturity,
            a: this.area,
            t: this.type,
            c: this.cooperate,
            o: 1,
          }
        })
      }
      else{
        this.$router.push({
          paths: '/talent-project-projects',
          query:{
            k: this.searchKey,
            i: this.industry,
            m: this.maturity,
            a: this.area,
            t: this.type,
            c: this.cooperate,
            o: 1,
          }
        })
      }
    },
    recommendAHref(item){
      if(!item) return '/talent-project-talents'
      switch (this.cur) {
        case 0:
          return `/talent-project-detail?dynamicId=${item.dynamicId}`
        case 1:
          return `/talent-project-detail?expertId=${item.id}`
        default:
          return '/talent-project-talents'
      }
    },
    detailAHref(item){
      if(!item) return '/talent-project-talents'
      switch (this.cur) {
        case 0:
          return `/talent-project-detail?expertId=${item.id}`
        case 1:
          return `/talent-project-detail?dynamicId=${item.dynamicId}`
        default:
          return '/talent-project-talents'
      }
    },
    searchAHref(item){
      if(!item) return '/talent-project-talents'
      switch (this.cur) {
        case 0:
          return `/talent-project-talents?k=${item}`
        case 1:
          return `/talent-project-projects?k=${item}`
        default:
          return '/talent-project-talents'
      }
    },
    getFilter(){
      getSelectFatherSonByType(93).then(res=>{
        this.filters1[0].val = res.data
        if(this.area){
          this.filters1[0].value = res.data.find((item)=>item.id + '' === this.area + '')?.id
        }
      }).finally(()=>{
        this.filters1[0].loading = false
      })
      getSelectFatherSonByType(14).then(res=>{ // 获取技术成熟度
        this.filters1[1].val = res.data
        if(this.maturity){
          this.filters1[1].value = res.data.find((item)=>item.id + '' === this.maturity + '')?.id
        }
        this.setTechnologyMaturity()
      }).finally(()=>{
        this.filters1[1].loading = false
      })
      getSelectFatherSonByType(92).then(res=>{ // 获取技术类型
        this.filters1[2].val = res.data
        if(this.type){
          this.filters1[2].value = res.data.find((item)=>item.id + '' === this.type + '')?.id
        }
      }).finally(()=>{
        this.filters1[2].loading = false
      })
      getSelectFatherSonByType(15).then(res=>{  // 获取合作类型
        this.filters1[3].val = res.data
        this.setCooperationType()
        if(this.cooperate){
          this.filters1[3].value = res.data.find((item)=>item.id + '' === this.cooperate + '')?.id
        }
      }).finally(()=>{
        this.filters1[3].loading = false
      })
      getSelectFatherSonByType(6).then(res=>{ // 获取行业
        this.filters1[4].val = res.data
        if(this.industry){
          this.filters1[4].value = res.data.find((item)=>item.id + '' === this.industry + '')?.id
        }
      }).finally(()=>{
        this.filters1[4].loading = false
      })
    },
    setTechnologyMaturity(){
      if(this.recommendList.length === 0 || this.filters1[1].val.length === 0) return
      this.recommendList = this.recommendList.map((item)=>{
        item.technologyMaturity = this.filters1[1].val.find((jtem)=>jtem.id + '' === item.technologyMaturity + '')?.val || item.technologyMaturity
        return item
      })
    },
    setCooperationType(){
      if(this.recommendList.length === 0 || this.filters1[3].val.length === 0) return
      this.recommendList = this.recommendList.map((item)=>{
        item.cooperationType = this.filters1[3].val.find((jtem)=>jtem.id + '' === item.cooperationType + '')?.val || item.cooperationType
        return item
      })
    },
    changeCur(cur){
      if(cur !== 0) return
      routerWindow(this.$router,"/talent-project-talents",{
        k: this.searchKey
      })
    },
    getQuery(){
      const cur = this.$route.query.type || '1'
      this.searchKey = this.$route.query.k || ''
      const offset = this.$route.query.o || '1'
      this.industry = this.$route.query.i || ''
      this.maturity = this.$route.query.m || ''
      this.area = this.$route.query.a || ''
      this.type = this.$route.query.t || ''
      this.cooperate = this.$route.query.c || ''
      this.offset = Number(offset)
      this.cur = Number(cur)
      if(this.hotSearch.length === 0){
        getHotSearch().then(res=>{
          this.getFilter()
          this.hotSearch = res.data.values.slice(0,5)
          this.getList()
        })
      }
      else this.getList()
    },
    getList(){
      this.loading = true
      switch (this.cur) {
        case 1:
          searchTechnology({
            areaId: this.area,
            achievementType: this.type,
            cooperationType: this.cooperate,
            industryIds: this.industry ? [this.industry] : [],
            keyword: this.searchKey,
            limit: this.pageSize,
            offset: this.offset,
            technologyMaturity: this.maturity,
          }).then(res=>{
            this.total = res.data.total
            this.recommendList = res.data.result.map(item=>{
              item.title = delHtmlTag(item.title).trim()
              return item
            })
            this.setTechnologyMaturity()
            this.setCooperationType()
          }).finally(()=>{
            this.loading = false
          })
          this.getRecommend()
          break;
        default:
          break;
      }
    },
    getRecommend(){
      this.techLoading = true
      const num = Math.floor(Math.random() * 4)
      const key = this.hotSearch[num]
      this.techLoading = true
      randomExpert(this.searchKey || key,this.info.id).then(res=>{
        this.list = res.data.slice(0,4).map((item)=>{
          item.loading = false
          return item
        })
      }).finally(()=>{
        this.techLoading = false
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.container{
  background: #F2F6FF;
  padding-bottom: 100px;
  .top{
    width: 100%;
    background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/10/15/1700010158912192');
    background-size: 100% 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 47px 0 31px;
    .buttons{
      display: flex;
      margin-bottom: 12px;
      button{
        margin: 0 3px;
        width: 130px;
        height: 42px;
        background: #FFFFFF;
        border-radius: 6px;
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #111111;
        cursor: pointer;
        border: none;
      }
      .active{
        background-color: $--tps-theme !important;
        color: #FFFFFF !important;
      }
    }
    .title{
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #111111;
      line-height: 30px;
      margin-bottom: 18px;
    }
    .input-line{
      display: flex;
      margin-bottom: 17px;
      .input{
        margin-bottom: 0px !important;
        width: 799px;
        height: 42px;
        background: #FFFFFF;
        border-radius: 4px 0 0 4px;
        border: none;
        box-sizing: border-box;
        padding: 11px;
        font-size: 14px;
        color: #111111;
      }
      .button{
        width: 84px;
        height: 42px;
        background: $--tps-theme;
        border-radius: 0px 4px 4px 0px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .hot{
      width: 883px;
      height: 18px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .option{
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #313F58;
        line-height: 16px;
        margin: 0 10px;
        a{
          color: #313F58;
          text-decoration: none;
          &:visited{
            color: #313F58 !important;
          }
        }
      }
    }
  }
  .datas{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .input{
    display: flex;
    justify-content: center;
    background-color: #ffffff;
    margin-bottom: 36px;
    .filters{
      width: 1200px;
      box-sizing: border-box;
      padding: 18px 20px;
      .filter-line{
        display: flex;
        align-items: center;
        margin: 20px 0;
        .filter{
          align-items: center;
          display: flex;
          margin-right: 36px;
          p{
            margin-right: 5px;
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #111111;
            line-height: 20px;
            width: 70px;
            white-space: nowrap;
            text-align: right;
          }
          :deep(.el-input__inner){
            width: 100px !important;
            font-size: 14px;
          }
        }
      }
    }
  }
  .list-container{
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    .right-recommend{
      width: 300px;
      .empty{
        width: 100%;
      }
      .p-empty{
        width: calc(100% - 30px);
        height: 132px;
        margin: 0 15px 15px;
        background: #F6F7FB;
      }
      .expert{
        width: calc(100% - 30px);
        height: 132px;
        margin: 0 15px 15px;
        box-sizing: border-box;
        padding: 17px 12px 14px;
        background: #F6F7FB;
        .other-avatar{
          width: 40px;
          height: 40px;
          border-radius: 2px;
          overflow: hidden;
          margin-right: 11px;
          cursor: pointer;
        }
        .right{
          width: 200px;
          .belong{
            margin-top: 12px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 12px;
          }
          .name{
            cursor: pointer;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #111111;
            line-height: 16px;
            display: block;
            margin-right: 12px;
            &:hover{
              color: $--color-theme !important;
            }
          }
          .education{
            height: 16px;
            border-radius: 2px;
            border: 1px solid $--color-theme;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: $--color-theme;
            line-height: 14px;
            padding: 0 4px;
            margin-right: 6px;
          }
          .position{
            height: 16px;
            border-radius: 2px;
            border: 1px solid #44B46F;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #44B46F;
            line-height: 14px;
            padding: 0 4px;
          }
        }
        .direction{
          margin-top: 15px;
          :deep(.title){
            width: 64px;
            height: 22px;
            background: #44B46F;
            border-radius: 2px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 22px;
            text-align: center;
            margin-right: 5px;
            padding: 3px 8px;
          }
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
        }
      }
      .img-button{
        width: 100%;
        position: relative;
        margin-bottom: 11px;
        .button{
          position: absolute;
          cursor: pointer;
          width: 240px;
          height: 30px;
          bottom: 20px;
          left: 50%;
          margin-left: -120px;
        }
        img{
          width: 300px;
          height: 336px;
        }
      }
      .recommend-experts{
        background-color: #FFFFFF;
        width: 100%;
        box-sizing: border-box;
        padding-top: 12px;
        padding-bottom: 9px;
        .title {
          width: 100%;
          box-sizing: border-box;
          background-color: #ffffff;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #111111;
          line-height: 28px;
          padding-left: 20px;
          margin-bottom: 15px;
        }
      }
      .projects{
        background-color: #FFFFFF;
        width: 100%;
        box-sizing: border-box;
        padding-top: 18px;
        padding-left: 20px;
        padding-bottom: 20px;
        padding-right: 24px;
        .title {
          width: 100%;
          box-sizing: border-box;
          background-color: #ffffff;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #111111;
          line-height: 28px;
          margin-bottom: 8px;
        }
        .empty{
          height: 67px;
        }
        .recommend{
          width: 100%;
          box-sizing: border-box;
          position: relative;
          padding: 15px 0;
          padding-left: 20px;
          &::before{
            position: absolute;
            content: '';
            width: 7px;
            height: 7px;
            background: $--tps-theme;
            border-radius: 50%;
            left: 0;
            top: 20px;
          }
          .title{
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            line-height: 14px;
            width: 100%;
            margin-bottom: 11px;
            a{
              color: #111111;
              &:visited{
                color: #111111 !important;
              }
              &:hover{
                color: $--tps-theme !important;
              }
            }
          }
          .infos{
            width: 100%;
            display: flex;
            justify-content: space-between;
            p{
              width: 50%;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 12px;
            }
          }
        }
      }
    }
    .map-container{
      width: 886px;
      box-sizing: border-box;
      margin-right: 13px;
      .no-top{
        margin-top: 0 !important;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
      .empty{
        margin-top: 12px;
        width: 886px;
        height: 145px;
        background: #FFFFFF;
        border-radius: 4px;
      }
      .p-empty{
        margin-top: 12px;
        width: 886px;
        height: 154px;
        background: #FFFFFF;
        border-radius: 4px;
      }
      .recommends{
        width: 100%;
        display: flex;
        box-sizing: border-box;
        padding: 20px;
        .thumb{
          width: 171px;
          height: 114px;
          cursor: pointer;
          margin-right: 17px;
        }
        .infos{
          width: 632px;
          padding-top: 1px;
          .title{
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #111111;
            line-height: 24px;
            width: 100%;
            margin-bottom: 4px;
            a{
              color: #111111;
              &:visited{
                color: #111111 !important;
              }
              &:hover{
                color: $--tps-theme !important;
              }
            }
          }
          .detail{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 24px;
            width: 100%;
            margin-bottom: 8px;
          }
          .types{
            width: 100%;
            display: flex;
            justify-content: flex-start;
            margin-bottom: 4px;
            .type{
              max-width: 50%;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 24px;
            }
          }
          .keys{
            width: 100%;
            display: flex;
            .key{
              border-radius: 2px;
              border: 1px solid #999999;
              cursor: pointer;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
              line-height: 20px;
              padding: 0 10px;
              max-width: 30%;
              margin-right: 10px;
              &:hover{
                border: 1px solid $--tps-theme;
                color: $--tps-theme;
              }
            }
          }
        }
      }
      .map-controller{
        padding-right: 20px !important;
      }
      .right{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999 !important;
        line-height: 20px;
        cursor: pointer;
        &:hover{
          color: $--tps-theme !important;
        }
      }
      .list{
        display: flex;
        box-sizing: border-box;
        align-items: flex-start;
        padding-top: 20px;
        padding-left: 21px;
        .avatar{
          width: 104px;
          height: 104px;
          border-radius: 6px;
          overflow: hidden;
          margin-right: 16px;
          cursor: pointer;
        }
        .infos{
          width: 561px;
          margin-right: 63px;
          padding: 8px 0;
          .names{
            display: flex;
            align-items: flex-end;
            margin-bottom: 17px;
            .name{
              font-size: 20px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              line-height: 26px;
              max-width: 360px;
              margin-right: 7px;
              box-sizing: border-box;
              a{
                color: #111111;
                &:hover{
                  color: $--tps-theme;
                }
                &:visited{
                  color: #111111 !important;
                }
              }
            }
            .position{
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: $--tps-theme;
              line-height: 18px;
              max-width: 90px;
              margin-right: 7px;
              box-sizing: border-box;
            }
            .education{
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #44B46F;
              line-height: 18px;
              max-width: 90px;
              box-sizing: border-box;
            }
          }
          .belong{
            width: 100%;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 16px;
            margin-bottom: 9px;
            span{
              color: #999999;
            }
          }
          .direction{
            width: 100%;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 27px;
            margin-bottom: 9px;
            span{
              color: #999999;
            }
          }
        }
        .buttons{
          height: 105px;
          display: flex;
          align-items: center;
          width: 80px;
          img{
            cursor: pointer;
            margin-bottom: 12px;
          }
          button{
            width: 80px;
            height: 32px;
            background: $--tps-theme;
            border-radius: 4px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            cursor: pointer;
            border: none;
          }
        }
      }
    }
  }
  .map-container{
    width: 100%;
    .experts{
      width: 886px;
    }
    .map{
      background-color: #FFFFFF;
      width: 886px;
      margin: 0 auto;
    }
    .map-controller {
      width: 100%;
      padding-left: 21px;
      padding-right: 47px;
      box-sizing: border-box;
      height: 56px;
      background-color: #ffffff;
      border-bottom: 1px solid #f3f3f3;
      justify-content: space-between;
      .left{
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #111111;
        line-height: 28px;
      }
      .right{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: $--tps-theme;
        cursor: pointer;
      }
    }
    .pie-data{
      background-color: #FFFFFF;
      width: 100%;
      box-sizing: border-box;
      padding: 30px 0;
      .line{
        width: 100%;
        display: flex;
        align-items: center;
        margin: 16px 0;
        .name{
          margin-right: 20px;
          width: 140px;
          text-align: right;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #111111;
        }
        .percent{
          width: 120px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 16px;
          margin-left: 12px;
        }
        :deep(.el-progress){
          .el-progress-bar__inner{
            border-radius: 4px !important;
          }
          .el-progress__text{
            display: none;
          }
        }
      }
    }
  }
}
.filter-class{
  width: 100%;
  align-items: center;
  justify-content: space-between;
  display: flex;
  .filter{
    align-items: center;
    display: flex;
    p{
      margin-right: 5px;
    }
  }
}
.pagination-container{
  width: 100%;
  padding: 30px 0;
  background: #FFFFFF;
  .pagination{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .page-size-number{
      position: relative;
      left: -165px;
      width: 55px;
      height: 25px;
      border-radius: 2px;
      border: 1px solid #666666;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      text-align: center;
      line-height: 25px;
      -moz-user-select:none; /* Firefox私有属性 */
      -webkit-user-select:none; /* WebKit内核私有属性 */
      -ms-user-select:none; /* IE私有属性(IE10及以后) */
      -khtml-user-select:none; /* KHTML内核私有属性 */
      -o-user-select:none; /* Opera私有属性 */
      user-select:none; /* CSS3属性 */
    }
    :deep() .el-pagination{
      button{
        background-color: #FFFFFF;
      }
      li{
        background-color: #FFFFFF;
      }
      .active{
        background-color: $--color-theme;
      }
      .pagination__jump{
        font-size: 14px !important;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666 !important;
      }
      .el-pagination__jump{
        margin-left: 75px;
      }
    }
  }
}
</style>