<template>
  <p class="title">常见问题解答（FAQ）</p>
  <!-- <div class="input-line">
    <div class="line">
      <input v-model="key" placeholder="搜索您的问题 在输入框中输入关键词，快速找到您想要的答案" maxlength="100" class="input" @keyup.enter="newSearch"/>
      <div class="button" @click="newSearch">
        <img src="@/assets/images/G60/announce/search.png" alt="">
      </div>
    </div>
  </div> -->
  <div class="scroll" style="--t: 240s;" :style="`width: ${width + 'px' || '1200px'};`">
    <div>
      <div class="section back-1">
        <p class="question">什么是探角数字科创中心？</p>
        <p class="answer">探角数字科创中心是一个基于大数据和人工智能技术的数字科创SaaS服务平台，致力于为企业提供高效、便捷的协作工具和丰富的科创资源。通过探角数字科创中心，企业可以轻松找到合适的技术专家、发掘创新项目，并享受一站式的科创服务支持，从而加速技术创新和产业升级。</p>
      </div>
      <div class="section back-0">
        <p class="question">探角数字科创中心提供哪些核心功能来支持企业创新？</p>
        <p class="answer">探角数字科创中心提供了一系列强大的核心功能来支持企业创新，包括全面的专家资源访问、海量的创新项目查看、精准的技术需求发布与匹配等。此外，还提供前沿科技资讯、企业团队成员管理、专家沟通记录查看等实用功能，旨在帮助企业更高效地进行技术创新、研发升级和市场竞争。</p>
      </div>
      <div class="section back-2">
        <p class="question">在使用探角过程中遇到问题，应该如何寻求帮助？</p>
        <p class="answer">如果在使用探角数字科创中心过程中遇到任何问题，您可以访问我们的FAQ页面，查找相关问题及答案，这里汇聚了用户常见的问题和解答；其次，您可以直接联系我们的客服团队，我们将竭诚为您提供专业、及时的解答和支持。</p>
      </div>
      <div class="section back-3">
        <p class="question">探角的定价策略是怎样的？是否有灵活的方案选择？</p>
        <p class="answer">探角数字科创中心提供了多种灵活的定价方案以适应不同企业的需求。我们根据企业的规模、需求和预算等因素，设计了多种方案供您选择。同时，我们也提供定制化的服务方案，根据企业的具体需求进行个性化报价，确保您能够以最合适的价格享受到最优质的科创服务。</p>
      </div>
      <div class="section back-4">
        <p class="question">探角数字科创中心主要服务于哪些用户群体？</p>
        <p class="answer">探角数字科创中心专注于为寻求创新能力提升、技术难题解决以及研发加速的企业和园区提供全方位服务。无论您是初创公司、中小企业还是大型企业，我们都能为您量身定制一站式的数字科创解决方案。</p>
      </div>
      <div class="section back-1">
        <p class="question">如果在使用探角过程中遇到问题，我该如何获取技术支持？</p>
        <p class="answer">探角数字科创中心拥有专业的技术支持和客户服务团队，随时准备为您解答疑问、提供帮助。您可以通过在线咨询、或电话等多种方式与我们联系。我们承诺在工作时间内提供迅速、专业的支持，确保您的使用体验顺畅无阻。</p>
      </div>
      <div class="section back-0">
        <p class="question">探角的高级定制版相比企业版有哪些独特的优势？</p>
        <p class="answer">高级定制版在继承企业版所有功能的基础上，进一步提供专属定制功能开发、定制化数据报告、API接口集成以及多层级权限管理等增值服务。这些独特优势旨在满足企业的个性化需求，提供更全面、更高效的科创支持，助力企业实现卓越创新和业务发展。</p>
      </div>
      <div class="section back-2">
        <p class="question">探角数字科创中心是否有优惠活动或折扣？</p>
        <p class="answer">我们会不定期推出优惠活动或折扣，具体信息请持续关注探角数字科创中心官方网站或社交媒体渠道的最新发布。同时，对于长期合作或大量购买的用户，我们也提供更具吸引力的价格优惠和定制化服务方案。</p>
      </div>
      <div class="section back-3">
        <p class="question">探角如何确保用户数据的安全和隐私保护？ </p>
        <p class="answer">探角数字科创中心高度重视用户数据的安全和隐私保护。我们采用业界先进的加密技术和安全措施，确保用户数据在传输和存储过程中的安全性。同时，我们严格遵守相关法律法规，对用户数据进行严格保密处理，并仅限于提供软件服务所需的使用。</p>
      </div>
    </div>
    <div>
      <div class="section back-1">
        <p class="question">什么是探角数字科创中心？</p>
        <p class="answer">探角数字科创中心是一个基于大数据和人工智能技术的数字科创SaaS服务平台，致力于为企业提供高效、便捷的协作工具和丰富的科创资源。通过探角数字科创中心，企业可以轻松找到合适的技术专家、发掘创新项目，并享受一站式的科创服务支持，从而加速技术创新和产业升级。</p>
      </div>
      <div class="section back-0">
        <p class="question">探角数字科创中心提供哪些核心功能来支持企业创新？</p>
        <p class="answer">探角数字科创中心提供了一系列强大的核心功能来支持企业创新，包括全面的专家资源访问、海量的创新项目查看、精准的技术需求发布与匹配等。此外，还提供前沿科技资讯、企业团队成员管理、专家沟通记录查看等实用功能，旨在帮助企业更高效地进行技术创新、研发升级和市场竞争。</p>
      </div>
      <div class="section back-2">
        <p class="question">在使用探角过程中遇到问题，应该如何寻求帮助？</p>
        <p class="answer">如果在使用探角数字科创中心过程中遇到任何问题，您可以访问我们的FAQ页面，查找相关问题及答案，这里汇聚了用户常见的问题和解答；其次，您可以直接联系我们的客服团队，我们将竭诚为您提供专业、及时的解答和支持。</p>
      </div>
      <div class="section back-3">
        <p class="question">探角的定价策略是怎样的？是否有灵活的方案选择？</p>
        <p class="answer">探角数字科创中心提供了多种灵活的定价方案以适应不同企业的需求。我们根据企业的规模、需求和预算等因素，设计了多种方案供您选择。同时，我们也提供定制化的服务方案，根据企业的具体需求进行个性化报价，确保您能够以最合适的价格享受到最优质的科创服务。</p>
      </div>
      <div class="section back-4">
        <p class="question">探角数字科创中心主要服务于哪些用户群体？</p>
        <p class="answer">探角数字科创中心专注于为寻求创新能力提升、技术难题解决以及研发加速的企业和园区提供全方位服务。无论您是初创公司、中小企业还是大型企业，我们都能为您量身定制一站式的数字科创解决方案。</p>
      </div>
      <div class="section back-1">
        <p class="question">如果在使用探角过程中遇到问题，我该如何获取技术支持？</p>
        <p class="answer">探角数字科创中心拥有专业的技术支持和客户服务团队，随时准备为您解答疑问、提供帮助。您可以通过在线咨询、或电话等多种方式与我们联系。我们承诺在工作时间内提供迅速、专业的支持，确保您的使用体验顺畅无阻。</p>
      </div>
      <div class="section back-0">
        <p class="question">探角的高级定制版相比企业版有哪些独特的优势？</p>
        <p class="answer">高级定制版在继承企业版所有功能的基础上，进一步提供专属定制功能开发、定制化数据报告、API接口集成以及多层级权限管理等增值服务。这些独特优势旨在满足企业的个性化需求，提供更全面、更高效的科创支持，助力企业实现卓越创新和业务发展。</p>
      </div>
      <div class="section back-2">
        <p class="question">探角数字科创中心是否有优惠活动或折扣？</p>
        <p class="answer">我们会不定期推出优惠活动或折扣，具体信息请持续关注探角数字科创中心官方网站或社交媒体渠道的最新发布。同时，对于长期合作或大量购买的用户，我们也提供更具吸引力的价格优惠和定制化服务方案。</p>
      </div>
      <div class="section back-3">
        <p class="question">探角如何确保用户数据的安全和隐私保护？ </p>
        <p class="answer">探角数字科创中心高度重视用户数据的安全和隐私保护。我们采用业界先进的加密技术和安全措施，确保用户数据在传输和存储过程中的安全性。同时，我们严格遵守相关法律法规，对用户数据进行严格保密处理，并仅限于提供软件服务所需的使用。</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus';
import { routerWindow } from '../../../../utils/tool';

export default{
  props: ['width'],
  data(){
    return{
      key: '',
    }
  },
  methods:{
    newSearch(){
      if(!this.key){
        ElMessage.warning('请输入关键词')
      }
      else{
        routerWindow(this.$router,'/questions',{key: this.key})
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.title{
  width: 100%;
  font-size: 32px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: #111111;
  line-height: 45px;
  text-align: center;
  margin-bottom: 40px;
}
.input-line{
  display: flex;
  justify-content: center;
  .line{
    position: relative;
  }
  .input{
    width: 697px;
    height: 52px;
    background: #FFFFFF;
    border-radius: 6px;
    border: 1px solid #B4B4B4;
    box-sizing: border-box;
    padding-right: 72px;
    padding-left: 16px;
  }
  .button{
    position: absolute;
    cursor: pointer;
    right: 0px;
    top: 14px;
    padding: 0 23px;
    height: 52px;
    img{
      width: 24px;
      height: 24px;
    }
  }
}
.scroll {
  padding-top: 65px;
  display: flex;
  overflow: hidden;
  // mask-image: linear-gradient(90deg, transparent, #fff 20%, #fff 80%, transparent);
  // -webkit-mask-image: linear-gradient(90deg, transparent, #fff 20%, #fff 80%, transparent);
}

.scroll > div .section {
  &:hover{
    margin-top: -25px;
    position: relative;
    &::after{
      content: '';
      width: 100%;
      height: 50px;
      position: absolute;
      bottom: -50px;
      left: 0;
    }
  }
  display: inline-block;
  margin: 10px;
  border-radius: 5px;
  color: #ffffff;
  text-transform: uppercase;
  transition: background-color 0.5s;
  width: 308px;
  height: 449px;
  box-sizing: border-box;
  padding: 200px 20px 0;
  .question{
    height: 50px;
    font-size: 18px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #111111;
    line-height: 25px;
    width: 100%;
    margin-bottom: 9px;
    white-space: normal !important;
  }
  .answer{
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    white-space: normal !important;
  }
}
.back-0{
  background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/00/22/1705909053408037');
  background-size: 100% 449px;
  background-repeat: no-repeat;
}
.back-1{
  background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/00/22/1705909577474363');
  background-size: 100% 449px;
  background-repeat: no-repeat;
}
.back-2{
  background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/00/22/1705909596764963');
  background-size: 100% 449px;
  background-repeat: no-repeat;
}
.back-3{
  background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/00/22/1705909605866487');
  background-size: 100% 449px;
  background-repeat: no-repeat;
}
.back-4{
  background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/00/22/1705909587527003');
  background-size: 100% 449px;
  background-repeat: no-repeat;
}
.scroll > div span:hover {
  background-color: #4caf50;
}

.img-box img {
  max-width: 150px;
  filter: grayscale(1);
  transition: filter 0.5s;
}
.img-box img:hover {
  filter: grayscale(0);
}
.scroll > div {
  display: flex;
  white-space: nowrap;
  animation: animate var(--t) linear infinite;
  animation-delay: calc(var(--t) * -1);
}
@keyframes animate {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.scroll > div:nth-child(2) {
  animation: animate2 var(--t) linear infinite;
  animation-delay: calc(var(--t) / -2);
}
@keyframes animate2 {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-200%);
  }
}

.scroll:hover > div {
  animation-play-state: paused;
}

</style>