<template>
    <div class="container" :class="{'back0': !smallScreen,'back1': smallScreen,'small-container': smallScreen}">
        <div class="part-1">
            <p class="title">海量科创大数据</p>
            <p class="word">5万+细分领域/1万+科研院所前沿项目/6万+实验室/30万+专家教授/100<br/>万+技术成果</p>
            <p class="title">AI人工智能精准匹配</p>
            <p class="word">根据地区产业、企业精准匹配相关数据，为区域带来价值</p>
            <p class="title">促进科技招商引资</p>
            <p class="word" style="margin-bottom: 0;">自主实现科创数据精准招商，促进以科技为创新创业、招商引资为抓手的<br/>区域经济发展</p>
        </div>
        <div class="part-2">
            <p class="title">精细化科创服务运营赋能</p>
            <div class="window">
                <div class="top flex-cc">
                    <img src="../../assets/images/project/project/img-1.png"/>
                    <div>
                        <p class="p-1">城市数字科创服务展厅</p>
                        <p class="p-2">通过线下+线上的方式，集合科技创新要素，为区域提供科技运营价值</p>
                    </div>
                </div>
                <div class="bottom flex-cc flex-b">
                    <div>
                        <img src="../../assets/images/project/project/img-2.png"/>
                        <p class="p-1">全球化产业研究院</p>
                        <p class="p-2" v-if="!smallScreen">推进专家、人才、技术成果、资本、资源的落地转化</p>
                        <p class="p-2" v-else>推进专家、人才、技术成果、资本、资源<br/>的落地转化</p>
                    </div>
                    <div>
                        <img src="../../assets/images/project/project/img-3.png"/>
                        <p class="p-1">城市品牌名片</p>
                        <p class="p-2" v-if="!smallScreen">产业峰会、论坛、博览会、展会，形成城市品牌，<br/>塑造城市影响力</p>
                        <p class="p-2" v-else>产业峰会、论坛、博览会、展会，形成城<br/>市品牌，塑造城市影响力</p>
                    </div>
                    <div>
                        <img src="../../assets/images/project/project/img-4.png"/>
                        <p class="p-1">区域科创人才工程</p>
                        <p class="p-2" v-if="!smallScreen">科创高级研修班，培养首席科技官与科创领军人<br/>才，塑造人才高地</p>
                        <p class="p-2" v-else>科创高级研修班，培养首席科技官与科创<br/>领军人才，塑造人才高地</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="part-3">
            <p class="title">数字化解决方案赋能</p>
            <div class="window">
                <div class="line line-1">
                    <p class="p-1">专家通系统</p>
                    <p class="p-2">大学教授、科研专家、行业人才链接协同系统，科技人才数据<br/>化、可视化、智能化</p>
                </div>
                <div class="line line-2">
                    <p class="p-1">数字化科创服务中心</p>
                    <p class="p-2">打通科技创新各方要素，专家、项目、成果、需求、资本在线协同，提<br/>质增效，本地企业在线发布需求，线上解决，与专家互动，找各类项<br/>目。所有企业在线发布需求，在线获取解决方案。</p>
                </div>
                <div class="line line-3">
                    <p class="p-1">创新赛道</p>
                    <p class="p-2">科技成果、科技项目在线管理与交流，促进项目合作数字化、高<br/>效化、区块链化</p>
                </div>
                <div class="line line-4">
                    <p class="p-1">个性化线上数字化平台能力</p>
                    <p class="p-2">根据政府、园区原有的线下生态进行线上PC端、小程序、APP个性<br/>化布局，真正实现线上线下一体化，智慧科创融合发展</p>
                </div>
            </div>
        </div>
        <div class="part-6">
            <p class="title">请提交您的“政府或园区”需求</p>
            <div class="form">
                <el-form :model="form" :rules="rules">
                    <el-form-item label="姓名" prop="memberName">
                        <el-input v-model="form.memberName" placeholder="请输入姓名" autocomplete="off" />
                    </el-form-item>
                    <el-form-item label="联系方式" prop="contactWay">
                        <el-input v-model="form.contactWay" placeholder="请输入联系方式" autocomplete="off" maxlength="11" />
                    </el-form-item>
                    <div class="form-code">
                        <el-form-item>
                            <el-input v-model="form.code" autocomplete="off" placeholder="请输入验证码" maxlength="6"/>
                        </el-form-item>
                        <el-button v-if="showCode" type="primary" @click="getPhoneCode">发送验证码</el-button>
                        <span v-else style="color: #B0B5C0">{{count}}S</span>
                    </div>
                    <el-form-item label="政府或园区名称"  prop="contactWay">
                        <el-input v-model="form.companyName" placeholder="请输入政府或园区名称" autocomplete="off" />
                    </el-form-item>
                    <el-form-item label="您的需求" class="form-textarea">
                        <el-input v-model="form.demandContent" resize="none" placeholder="请输入您的需求" autocomplete="off" type="textarea" />
                    </el-form-item>
                    <el-form-item  class="submit-butdiv">
                        <el-button type="primary" @click="submit">立即定制解决方案</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import { getToken } from '@/utils/auth'
import { ElMessage } from 'element-plus'
import { getPhoneCode } from "@/api/user";  // 获取用户信息，更新名字头像
// import {environment} from "@/utils/consts";
import { mapGetters } from 'vuex';
import { getGovernment } from "@/api/activity"

export default {
    data(){
        return{
            rules:{
               memberName:[{ required: true, message: '请输入姓名', trigger: 'blur' },],
               contactWay:[{ required: true, message: '请输入联系方式', trigger: 'blur' },],
               companyName:[{ required: true, message: '请输入单位名称', trigger: 'blur' },]
            },
            imgLoaded: false,   //背景加载
            showCustom: false,  // 定制弹窗
            customName: '', // 姓名
            customPhone: '', // 电话
            dialogFormVisible: false,
            form: {},
            count: 60,
            showCode: true, //是否显示倒计时
            timer: null,
        }
    },
    computed:{
        ...mapGetters([
            'smallScreen',
        ]),
    },
    methods:{
        getPhoneCode () {
            const TIME_COUNT = 60;
            var reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
            console.log(reg.test(this.form.contactWay))
            if(reg.test(this.form.contactWay)==false){
                ElMessage.warning('您输入的手机号码格式不正确，请重新输入');
                return
            }
            getPhoneCode({phone: this.form.contactWay}).then(() => {
                if (!this.timer) {
                    this.count = TIME_COUNT;
                    this.showCode = false;
                    this.timer = setInterval(() => {
                        if (this.count > 0 && this.count <= TIME_COUNT) {
                            this.count --;
                        } else {
                            this.showCode = true;
                            clearInterval(this.timer)
                            this.timer = null
                        }
                    }, 1000)
                }
                ElMessage.success('发送成功')
            }).catch(err => {
                ElMessage.error({ message: err.message})
            })
        },
        submit(){
            const { memberName, contactWay, code,companyName } = this.form
            if(!memberName) {
                ElMessage.warning('名字不能为空')
                return
            }
            if(!contactWay) {
                ElMessage.warning('电话不能为空')
                return
            }
            if(!code) {
                ElMessage.warning('验证码不能为空')
                return
            }
            if(!companyName) {
                ElMessage.warning('单位不能为空')
                return
            }
            getGovernment(this.form).then(()=>{
                ElMessage.success('提交成功')
                this.form = {}
            })
        },
        inputChange(e){  // 只能输入数字
            let a = e.key.replace(/[^\d]/g, "");
            if (!a && e.keyCode !== 8) {
                e.preventDefault();
            }
        },
        imgLoad(){
            this.imgLoaded = true
        },
        checkLogin(type){
            if(getToken() === ""){
                ElMessage.warning('你尚未登录!')
                this.$router.push({path: '/login',query: {redirect: '/industrial-garden'}})
            }
            else{
                // uat: 185580801622017 线上: 180517873975297
                if(type === 1){
                    this.$store.commit("message/SET_OPEN_SERVICE_WINDOW")
                    // let id = (environment === 'tprod' || environment === 'online' ) ? 180517873975297 : 185580801622017
                    // this.$store.dispatch("message/startCommunication", {id: id,serverId:'',title:'',quickChat:false, unlimited: true})  // 在线联系不需要需求id、标题及承接需求quickChat
                }
                else{
                    this.showCustom = true
                }
            }
        },
        goToQuestion(){
            window.open('https://www.wjx.top/vj/tRNSOxy.aspx', "_blank");
        }
    }
}
</script>

<style scoped lang="scss">
.back0{
    background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/06/13/1689231727170557') !important;
}
.back1{
    background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/06/13/1689241703838966') !important;

}
.small-container{
    .part-2{
        .window{
            width: 960px !important;
            .top{
                img{
                    width: 525px;
                    height: 251px;
                    margin-right: 43px !important;
                }
            }
            .bottom{
                width: calc(100% + 60px) !important;
                margin-left: -30px;
                img{
                    width: 294px;
                    height: 232px;
                }
            }
        }
    }
    .part-3{
        .window{
            width: 960px !important;
        }
    }
    .form{
        width: 960px !important;
    }
}
.container{
    width: 100%;
    min-width: 1200px;
    margin-top: 0;
    background: #F7F8FC;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 50px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    .part-1{
        width: 502px;
        margin-top: 507px;
        margin-left: 49.537%;
        background-color: #ffffff;
        box-sizing: border-box;
        padding: 37px 18px 33px 30px;
        margin-bottom: 110px;
        .title{
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2280FF;
            line-height: 22px;
            margin-bottom: 9px;
        }
        .word{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #111111;
            line-height: 24px;
            margin-bottom: 34px;
        }
    }
    .part-2{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title{
            font-size: 32px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #111111;
            line-height: 45px;
            margin-bottom: 66px;
        }
        .window{
            width: 1200px;
            background-color: $--color-theme;
            height: 652px;
            margin-bottom: 82px;
            box-sizing: border-box;
            padding: 50px 30px 0;
            .top{
                margin-bottom: 25px;
                img{
                    margin-right: 54px;
                }
                .p-1{
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 26px;
                    margin-bottom: 10px;
                }
                .p-2{
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 26px;
                }
            }
            .bottom{
                background: #FFFFFF;
                width: 100%;
                box-sizing: border-box;
                padding: 22px 21px 48px;
                .p-1{
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #111111;
                    line-height: 26px;
                    margin-top: 22px;
                    margin-bottom: 6px;
                }
                .p-2{
                    height: 52px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 26px;
                }

            }
        }
    }
    .part-3{
        margin-top: 95px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-image: url('../../assets/images/project/project/part-3-back.png');
        background-size: 838px 628px;
        background-repeat: no-repeat;
        background-position: center bottom;
        margin-bottom: 100px;
        .title{
            font-size: 32px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #111111;
            line-height: 45px;
            margin-bottom: 69px;
        }
        .window{
            width: 1200px;
            height: 770px;
            position: relative;
            .line-1{
                width: 435px;
                box-sizing: border-box;
                padding: 18px 18px 18px 26px;
                position: absolute;
                left: 0;
                top: 53px;
            }
            .line-2{
                width: 496px;
                box-sizing: border-box;
                padding: 29px 22px 29px 30px;
                position: absolute;
                right: 0;
                top: 0;
            }
            .line-3{
                box-sizing: border-box;
                padding: 21px 24px 21px 23px;
                position: absolute;
                left: 0;
                top: 368px;
                width: 450px;
            }
            .line-4{
                box-sizing: border-box;
                padding: 24px 22px 23px 27px;
                position: absolute;
                right: 0;
                top: 470px;
                width: 460px;
            }
            .line{
                background: #FFFFFF;
                .p-1{
                    font-size: 16px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #2280FF;
                    line-height: 16px;
                    margin-bottom: 10px;
                }
                .p-2{
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #111111;
                    line-height: 24px;
                }
            }
        }
    }
    .part-6{
        width: 100%;
        padding-bottom: 102px;
        background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/06/11/1689057048588474');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        .title{
            width: 100%;
            margin: 56px 0 51px;
            text-align: center;
            font-size: 32px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 45px;
        }
    }
    .form{
        margin: 0px auto;
        width: 1200px;
        background: #FFFFFF;
        border-radius: 8px;
        padding: 37px 0 49px;
        p{
            font-size: 44px;
            line-height: 44px;
            margin: 45px 0px;
        }
        .el-form{
            padding: 0px 20px;
        }
        .form-code{
            display: flex;
            justify-content: space-between;
        }
        button{
            background: $--color-theme;
            border: none;
            height: 40px;
            border-radius: 4px;
        }
        :deep(.el-form){
            width: 362px;
            margin: 0 auto;
            .submit-button{
                width: 162px !important;
                margin: 0 auto !important;
            }
        }
        :deep(.el-form-item__label){
            font-size: 14px;
            color: #111111;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            line-height: 14px;
            margin-bottom: 12px;
        }
        :deep(.el-input__inner){
            background: #ffffff;
        }
        :deep(.el-textarea__inner){
            background: #ffffff;
            height: 100%;
        }
        :deep(.el-textarea){
            height: 100px;
        }
    }
    .img-padding{
        padding: 0px 360px;
    }
}
::v-deep .el-input__inner{
                background: #f2f2f2;
            }
::v-deep .el-textarea__inner{
                background: #f2f2f2;
                height: 100%;
            }
::v-deep .el-textarea{
    height: 100px;
}
.button{
    background: #EAF2FD;
    border-radius: 10px;
    color: #4A1BD9;
    padding: 24px 26px;
    font-size: 1.2vw;
    cursor: pointer;
}
.custom-dialog{
    border-radius: 15px;
    .custom-top{
      img{
        width: 1072px;
        margin-top: -100px;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      .title{
        font-size: 40px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        color: #0E0E0E;
      }
      .container-dialog{
        width: 50%;
        margin: 20px;
        .infos{
          font-size: 28px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #4B2F08;
        }
        input{
          background: #FFFEFB;
          border: 2px solid #FFE8BD;
          border-radius: 36px;
          margin-top: 10px;
          width: 100%;
          height: 36px;
          font-size: 24px;
          text-align: center;
        }
      }
      .commit-button{
        width: 460px;
        height: 40px;
        background: #EBD692;
        border-radius: 36px;
        font-size: 30px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #563B15;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        cursor: pointer;
      }
    }
}
</style>