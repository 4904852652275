<template>
  <div class="container">
    <div class="form" v-loading="loading">
      <p class="title">日报</p>
      <div class="form-item">
        <p class="form-tile">一、员工姓名<span>*</span></p>
        <input v-model="name" class="input" placeholder="请输入姓名" @input="changeInput" maxlength="50"/>
      </div>
      <div class="form-item" style="margin-top: 20px;">
        <p class="form-tile">二、今日任务以及进度<span>*</span></p>
        <div class="flex-b" style="margin-top: 20px;">
          <div class="left">
            <div class="table-title">
              任务
            </div>
            <div class="flex-b" v-for="(item,index) in task" :key="index">
              <el-input
                @input="changeInput"
                class="table-input table-left"
                type="textarea"
                rows="2"
                resize="none"
                v-model="item.taskName">
              </el-input>
            </div>
          </div>
          <div class="right">
            <div class="table-title" style="border-left: none;">
              进度
            </div>
            <div class="flex-b" v-for="(item,index) in task" :key="index">
              <el-input
                @input="changeInput"
                class="table-input table-right"
                type="textarea"
                rows="2"
                resize="none"
                v-model="item.progress">
              </el-input>
            </div>
          </div>
        </div>
        <div class="flex" style="margin-top: 20px;">
          <div class="add-line" @click="addLine(2)">
            增加一行
          </div>
          <div class="minus-line" @click="minusLine(2)">
            减少一行
          </div>
        </div>
      </div>
      <div class="form-item" style="margin-top: 20px;">
        <p class="form-tile" style="margin-bottom: 20px;">三、未完成任务及原因<span>*</span></p>
        <el-input
          @input="changeInput"
          class="textarea"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 10}"
          placeholder="请输入未完成任务及原因"
          resize="none"
          maxlength="1000"
          show-word-limit
          v-model="incomplete">
        </el-input>
      </div>
      <div class="form-item" style="margin-top: 20px;">
        <p class="form-tile">四、客户拜访记录</p>
        <div class="flex-b" style="margin-top: 20px;">
          <div class="left small">
            <div class="table-title">
              客户公司名
            </div>
            <div class="table-line" v-for="(item,index) in visits" :key="index">
              <el-input
                @input="changeInput"
                class="table-input"
                type="textarea"
                rows="5"
                resize="none"
                v-model="item.company">
              </el-input>
            </div>
          </div>
          <div class="middle small">
            <div class="table-title">
              联系人
            </div>
            <div class="table-line" v-for="(item,index) in visits" :key="index">
              <el-input
                @input="changeInput"
                class="table-input"
                type="textarea"
                rows="5"
                resize="none"
                v-model="item.contacts">
              </el-input>
            </div>
          </div>
          <div class="middle small">
            <div class="table-title">
              联系电话
            </div>
            <div class="table-line" v-for="(item,index) in visits" :key="index">
              <el-input
                @input="changeInput"
                class="table-input"
                type="textarea"
                rows="5"
                resize="none"
                v-model="item.phone">
              </el-input>
            </div>
          </div>
          <div class="middle small">
            <div class="table-title">
              职位
            </div>
            <div class="table-line" v-for="(item,index) in visits" :key="index">
              <el-input
                @input="changeInput"
                class="table-input"
                type="textarea"
                rows="5"
                resize="none"
                v-model="item.position">
              </el-input>
            </div>
          </div>
          <div class="middle small">
            <div class="table-title">
              沟通事宜
            </div>
            <div class="table-line" v-for="(item,index) in visits" :key="index">
              <el-input
                @input="changeInput"
                class="table-input"
                type="textarea"
                rows="5"
                resize="none"
                v-model="item.thing">
              </el-input>
            </div>
          </div>
          <div class="middle small">
            <div class="table-title">
              沟通结果
            </div>
            <div class="table-line" v-for="(item,index) in visits" :key="index">
              <el-input
                @input="changeInput"
                class="table-input"
                type="textarea"
                rows="5"
                resize="none"
                v-model="item.result">
              </el-input>
            </div>
          </div>
          <div class="middle small">
            <div class="table-title">
              下次沟通时间
            </div>
            <div class="table-line" v-for="(item,index) in visits" :key="index">
              <el-input
                @input="changeInput"
                class="table-input"
                type="textarea"
                rows="5"
                resize="none"
                v-model="item.nextVisitTime">
              </el-input>
            </div>
          </div>
          <div class="right small">
            <div class="table-title" style="border-left: none;">
              沟通方式（面谈、电话等）
            </div>
            <div class="table-line" v-for="(item,index) in visits" :key="index">
              <el-input
                @input="changeInput"
                class="table-input"
                type="textarea"
                rows="5"
                resize="none"
                v-model="item.visitType">
              </el-input>
            </div>
          </div>
        </div>
        <div class="flex" style="margin-top: 20px;">
          <div class="add-line" @click="addLine(4)">
            增加一行
          </div>
          <div class="minus-line" @click="minusLine(4)">
            减少一行
          </div>
        </div>
      </div>
      <div class="form-item" style="margin-top: 20px;">
        <p class="form-tile" style="margin-bottom: 20px;">五、需要协助事项</p>
        <el-input
          @input="changeInput"
          class="textarea"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 10}"
          placeholder="请输入需要协助事项"
          resize="none"
          maxlength="1000"
          show-word-limit
          v-model="assistance">
        </el-input>
      </div>
      <div class="flex-rc">
        <div class="upload-button" @click="upload">
          上传
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage, ElMessageBox } from 'element-plus';
import { dailyLog } from "@/api/user";
import { getLocalUserId } from "@/utils/tool";

export default{
  data(){
    return{
      name: '',
      task: [],
      incomplete: '',
      visits:[],
      assistance: '',
      loading: false,
    }
  },
  mounted(){
    this.setForm()
    // localStorage.getItem('daily_form')
  },
  methods:{
    setForm(){
      this.name = '',
      this.task = [],
      this.incomplete = '',
      this.visits = [],
      this.assistance = '',
      this.task.push({
        taskName: '',
        progress: ''
      })
      this.task.push({
        taskName: '',
        progress: ''
      })
      this.task.push({
        taskName: '',
        progress: ''
      })
      this.task.push({
        taskName: '',
        progress: ''
      })
      this.task.push({
        taskName: '',
        progress: ''
      })
      this.visits.push({
        company: '',
        contacts: '',
        phone: '',
        position: '',
        thing: '',
        result: '',
        nextVisitTime: '',
        visitType: ''
      })
      this.visits.push({
        company: '',
        contacts: '',
        phone: '',
        position: '',
        thing: '',
        result: '',
        nextVisitTime: '',
        visitType: ''
      })
      this.visits.push({
        company: '',
        contacts: '',
        phone: '',
        position: '',
        thing: '',
        result: '',
        nextVisitTime: '',
        visitType: ''
      })
    },
    changeInput(){
      const param = {
        name: this.name,
        task: this.task.filter((item)=> item.taskName && item.progress),
        incomplete: this.incomplete,
        visits: this.visits.filter(element=>element.company && element.contacts && element.phone && element.position && element.thing && element.result && element.nextVisitTime && element.visitType),
        assistance: this.assistance,
      }
      // localStorage.setItem('daily_form',JSON.stringify(param))
      console.log(param,'hehe22');
    },
    addLine(num){
      switch (num) {
        case 2:
          if(this.task.length === 20){
            ElMessage.warning('最多20行!')
            return
          }
          this.task.push({
            taskName: '',
            progress: ''
          })
          break;
        case 4:
          if(this.visits.length === 50){
            ElMessage.warning('最多50行!')
            return
          }
          this.visits.push({
            company: '',
            contacts: '',
            phone: '',
            position: '',
            thing: '',
            result: '',
            nextVisitTime: '',
            visitType: ''
          })
          break;
        default:
          break;
      }
    },
    minusLine(num){
      ElMessageBox.confirm('是否确认删除一行','确认窗口', {
        confirmButtonText: '确定',
        lockScroll: false,  // 防止页面振动
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
        switch (num) {
          case 2:
            if(this.task.length === 1){
              ElMessage.warning('至少得有一行!')
              return
            }
            this.task.splice(-1,1)
            break;
          case 4:
          if(this.visits.length === 1){
              ElMessage.warning('至少得有一行!')
              return
            }
            this.visits.splice(-1,1)
            break;
          default:
            break;
        }
      })
    },
    upload(){
      // 上传日报
      if(!this.name){
        ElMessage.warning('请输入员工姓名!')
        return
      }
      else if(!this.checkTask()){
        ElMessage.warning('请输入完整今日任务及进度!')
        return
      }
      else if(!this.incomplete){
        ElMessage.warning('请输入未完成任务及原因!')
        return
      }
      // else if(!this.checkRecord()){
      //   ElMessage.warning('请输入完整客户拜访记录!')
      //   return
      // }
      // else if(!this.assistance){
      //   ElMessage.warning('请输入需要协助事项!')
      //   return
      // }
      const param = {
        name: this.name,
        task: this.task.filter((item)=> item.taskName && item.progress),
        incomplete: this.incomplete,
        memberId: getLocalUserId(),
        visits: !this.checkRecord() ? [] : this.visits.filter(element=>element.company && element.contacts && element.phone && element.position && element.thing && element.result && element.nextVisitTime && element.visitType),
        assistance: this.assistance,
      }
      this.loading = true
      dailyLog(param).then(res=>{
        if(res.code === "Sx200"){
          ElMessage.success('提交成功')
          this.setForm()
        }
      }).finally(()=>{
        this.loading = false
      })
    },
    checkTask(){
      let flag = false
      for (let index = 0; index < this.task.length; index++) {
        const element = this.task[index];
        if(element.taskName && element.progress){
          flag = true
          break
        }
      }
      return flag
    },
    checkRecord(){
      let flag = false
      for (let index = 0; index < this.visits.length; index++) {
        const element = this.visits[index];
        if(element.company && element.contacts && element.phone && element.position && element.thing && element.result && element.nextVisitTime && element.visitType){
          flag = true
          break
        }
      }
      return flag
    },
  },
}
</script>

<style lang="scss" scoped>
.container{
  width: 100%;
  background-color: #f3f3f3;
  padding: 20px 0;
  .form{
    width: 1200px;
    background-color: #ffffff;
    margin: 0 auto;
    padding: 20px;
    .title{
      width: 100%;
      text-align: center;
      font-size: 20px;
      color: #111111;
    }
    .form-item{
      width: 100%;
      .form-tile{
        width: 100%;
        font-size: 18px;
        color: #111111;
        span{
          color: red;
        }
      }
      .add-line{
        background-color: $--color-theme;
        font-size: 18px;
        color: #ffffff;
        border-radius: 4px;
        padding: 5px 10px;
        margin-right: 15px;
        cursor: pointer;
      }
      .minus-line{
        background-color: rgba(255, 0, 0, 0.616);
        font-size: 18px;
        color: #ffffff;
        border-radius: 4px;
        padding: 5px 10px;
        margin-right: 15px;
        cursor: pointer;
      }
      .input{
        margin-top: 20px;
        width: 100%;
        border: 1px solid #f3f3f3;
        border-radius: 4px;
        font-size: 18px;
        padding: 10px;
        box-sizing: border-box;
      }
      .textarea{
        :deep(textarea){
          font-size: 18px;
          line-height: 36px;
          padding: 5px 15px;
        }
      }
      .small{
        width: calc(12.5% - 0.5px) !important;
        .table-title{
          height: 60px;
        }
      }
      .table-title{
        line-height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .middle{
        width: calc(50% - 0.5px);
        border-right: 1px solid #666666;
        .table-title{
          font-size: 18px;
          color: #111111;
          border: 1px solid #333333;
          border-right: none;
          border-left: none;
          padding: 5px 10px;
        }
        .table-input{
          :deep(textarea){
            border-top: none;
            border-radius: 0;
            border-right: none;
            border-left: none;
            font-size: 18px;
            line-height: 36px;
            padding: 5px 15px;
          }
        }
      }
      .left{
        width: calc(50% - 0.5px);
        border-right: 1px solid #666666;
        .table-title{
          font-size: 18px;
          color: #111111;
          border: 1px solid #333333;
          border-right: none;
          padding: 5px 10px;
        }
        :deep(textarea){
          border-top: none;
          border-radius: 0;
          border-right: none;
          font-size: 18px;
          line-height: 36px;
          padding: 5px 15px;
        }
      }
      .table-left{
        :deep(textarea){
          border-top: none;
          border-radius: 0;
          border-right-width: 1px;
          font-size: 18px;
          line-height: 36px;
          padding: 5px 15px;
        }
      }
      .table-right{
        :deep(textarea){
          border-top: none;
          border-radius: 0;
          border-left-width: 1px;
          font-size: 18px;
          line-height: 36px;
          padding: 5px 15px;
        }
      }
      .right{
        width: calc(50% - 0.5px);
        .table-title{
          font-size: 18px;
          color: #111111;
          border: 1px solid #333333;
          padding: 5px 10px;
        }
        :deep(textarea){
          border-top: none;
          border-radius: 0;
          border-left: none;
          font-size: 18px;
          line-height: 36px;
          padding: 5px 15px;
        }
      }
    }
    .upload-button{
      margin-top: 50px;
      background: $--color-theme;
      color: #ffffff;
      border-radius: 4px;
      padding: 10px 100px;
      font-size: 18px;
      cursor: pointer;
      margin-bottom: 20px;
    }
  }
}
</style>