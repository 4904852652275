<template>
  <div class="new-vip-container flex-dc-rc">
    <!-- 顶部banner -->
    <img src="../../assets/images/vip/type0/vip-top-back.png" alt="" class="banner">
    <!-- 类型窗口 -->
    <div class="vip-types flex">
      <!-- <div class="window flex-rc-cc" :class="{'active': typeSelect === 0}" @click="changeTypeSelect(0)">
        <img src="../../assets/images/vip/type0/vip-option-0.png" alt="" style="width: 42px;height: 34px;margin-right: 11px;">
        <span>专家通</span>
      </div> -->
      <!-- <div class="window flex-rc-cc" :class="{'active': typeSelect === 1}" @click="changeTypeSelect(1)">
        <img src="../../assets/images/vip/type2/vip-option-0.png" alt="" style="width: 34px;height: 27px;margin-right: 6px;">
        <p>专家通vip</p>
      </div> -->
      <!-- <div class="window flex-rc-cc" :class="{'active': typeSelect === 2}" @click="changeTypeSelect(2)">
        <img src="../../assets/images/vip/type3/vip-option-2.png" alt="" style="width: 30px;height: 30px;margin-right: 13px;">
        <p>创新赛道vip</p>
      </div> -->
      <div class="window flex-rc-cc" :class="{'active': typeSelect === 1}" @click="changeTypeSelect(1)">
        <img src="../../assets/images/vip/type3/vip-option-3.png" alt="" style="width: 29px;height: 30px;margin-right: 8px;">
        <p>科企岛精英vip</p>
      </div>
      <!-- <div class="window flex-rc-cc" :class="{'active': typeSelect === 5}" @click="changeTypeSelect(5)">
        <img src="../../assets/images/vip/type3/vip-option-1.png" alt="" style="width: 29px;height: 30px;margin-right: 8px;">
        <span>科企通</span>
      </div> -->
      <!-- <div class="window flex-rc-cc" :class="{'active': typeSelect === 3}" @click="changeTypeSelect(3)">
        <img src="../../assets/images/vip/type3/vip-option-3.png" alt="" style="width: 29px;height: 30px;margin-right: 8px;">
        <span>创新赛道</span>
      </div> -->
      <!-- <div class="window flex-rc-cc" :class="{'active': typeSelect === 4}" @click="changeTypeSelect(4)">
        <img src="../../assets/images/vip/ctt/icon-0.png" alt="" style="width: 34px;height: 30px;margin-right: 7px;">
        <p>创投通vip</p>
      </div> -->
      <!-- <div class="window flex-rc-cc" :class="{'active': typeSelect === 7}" @click="changeTypeSelect(7)">
        <img src="../../assets/images/vip/csy/icon-0.png" alt="" style="width: 32px;height: 32px;margin-right: 8px;">
        <span>数字产学研</span>
      </div> -->
      <!-- 认证会员 -->
      <!-- <div class="window flex-dc-rc type0">
        <div class="top">
          <p class="title">认证会员</p>
          <p class="fs-16">专属线上数字科创中心</p>
          <p class="fs-16">平台专家免费查看</p>
          <p class="tip">
            <a class="cursor-p" href="#vipTable">功能详情>></a>
          </p>
        </div>
        <button class="window-button fs-16 cursor-p" @click="confirm(0)">{{ statusButtonText }}</button>
      </div> -->
      <!-- VIP会员 -->
      <!-- <div class="window flex-dc-rc type1">
        <div class="top">
          <p class="title">数字科创</p>
          <p class="fs-16">在线专家技术诊断</p>
          <p class="fs-16">平台硬核 曝光</p>
          <p class="tip">
            <a class="cursor-p" href="#vipTable">功能详情>></a>
          </p>
        </div>
        <button class="window-button fs-16 cursor-p" @click="confirm(1)">立即抢购</button>
      </div> -->
      <!-- 定制会员 -->
      <!-- <div class="window flex-dc-rc type2">
        <div class="top">
          <p class="title">创新赛道</p>
          <p class="fs-16">专家、项目、资本精准对接</p>
          <p class="fs-16">专属定制服务</p>
          <p class="tip">
            <a class="cursor-p" href="#vipTable">功能详情>></a>
          </p>
        </div>
        <button class="window-button fs-16 cursor-p" @click="confirm(2)">立即申请</button>
      </div> -->
    </div>
    <div class="vip-title flex-cc">
      <img src="../../assets/images/vip/type0/vip-title-left.png" style="width: 61px;height: 41px;margin-right: 13px;"/>
      尊享特权
      <img src="../../assets/images/vip/type0/vip-title-right.png" style="width: 61px;height: 41px;margin-left: 13px;"/>
    </div>
    <!-- 专家通 -->
    <div v-if="typeSelect === 0" class="flex-dc-rc">
      <!-- 介绍 -->
      <div class="flex-cc vip-introduce">
        <div class="introduce-part type-0-0 flex-cc">
          <img src="../../assets/images/vip/type0/vip-0-0-left.png" alt="" style="width: 61px;height: 62px;margin-right: 36px;">
          <div class="right">
            <p class="title">全领域专家在线联络获取解决方案</p>
            <p style="margin-bottom: 13px;">1. 多学科、多部门、多领域专家、教授</p>
            <p>2. 线上联络，疑难解答，线下实况指导</p>
          </div>
        </div>
        <div class="introduce-part type-0-1 flex-cc">
          <img src="../../assets/images/vip/type0/vip-0-1-left.png" alt="" style="width: 62px;height: 62px;margin-right: 35px;">
          <div class="right">
            <p class="title">多方位在线复合筛选，精确查找范围</p>
            <p style="margin-bottom: 13px;">1. 提供从学校、地点等多角度在线筛选</p>
            <p>2. 精确搜索，方便用户定义查看</p>
          </div>
        </div>
      </div>
      <!-- 购买按钮 -->
      <div class="button-window">
        <p>50<span style="font-size: 18px;">次</span></p>
        <p class="tip">每次仅需7.3元</p>
        <p class="price"><span style="font-size: 16px;">￥</span>{{ products[0]?.marketPrice }}</p>
        <div class="time-info">
          {{expertTime.maxCount > 0 ? '您购买数量' + expertTime.maxCount + '，剩余数量' + (expertTime.maxCount - expertTime.currentCount) : '您尚未开通'}}
        </div>
        <div class="button" @click="confirm(1)">
          立即开通
        </div>
      </div>
    </div>
    <!--  精英VIP -->
    <div v-else-if="typeSelect === 1" class="flex-dc-rc">
      <div class="new-bottom-type flex-rc">
        <div class="type" :class="{'active': bottomCur === 0}" @click="bottomCur = 0">专家通</div>
        <div class="type" :class="{'active': bottomCur === 1}" @click="bottomCur = 1">创新赛道</div>
        <div class="type" :class="{'active': bottomCur === 2}" @click="bottomCur = 2">创投通</div>
      </div>
      <div v-if="bottomCur === 0">
        <div class="title-part">
          <img src="../../assets/images/vip/expert/title-icon.png" style="width: 18px;height: 16px;margin-right: 6px;"/>
          专家通
        </div>
        <div class="title-part" style="padding-left: 30px;font-size: 20px;">
          数据看板
        </div>
        <ChinaMap :mapHeight="385" ></ChinaMap>
        <!-- 介绍 -->
        <div class="title-part" style="padding-left: 30px;font-size: 20px;margin-top: 10px;">
          5大权益
        </div>
        <div class="flex-cc vip-introduce">
          <div class="introduce-part expert-1 flex-cc" style="margin-bottom: 30px;">
            <img src="../../assets/images/vip/expert/icon1.png" alt="" style="width: 44px;height: 43px;margin-right: 37px;">
            <div class="right">
              <p class="title">专家通</p>
              <p>全领域专家在线联络，多方位复合筛选，精准查找</p>
            </div>
          </div>
          <!-- <div class="introduce-part expert-2 flex-cc" style="margin-bottom: 30px;">
            <img src="../../assets/images/vip/expert/icon2.png" alt="" style="width: 51px;height: 47px;margin-right: 33px;">
            <div class="right">
              <p class="title">创新赛道</p>
              <p>全国上万家科研院所前沿项目在线对接，第一时</p>
              <p>间捕获未来独角兽</p>
            </div>
          </div> -->
          <div class="introduce-part expert-4 flex-cc" style="margin-bottom: 30px;">
            <img src="../../assets/images/vip/expert/icon4.png" alt="" style="width: 46px;height: 46px;margin-right: 35px;">
            <div class="right">
              <p class="title">海量数据、专家、项目、成果</p>
              <p>百万级专家教授，千万级技术成果 1万+硬科技</p>
              <p>项目，第一时间查看对接，发现行业新机会</p>
            </div>
          </div>
          <div class="introduce-part expert-3 flex-cc" style="margin-bottom: 30px;">
            <img src="../../assets/images/vip/expert/icon3.png" alt="" style="width: 46px;height: 46px;margin-right: 35px;">
            <div class="right">
              <p class="title">专属数字科创中心</p>
              <p>一人创建，多人同享</p>
            </div>
          </div>
          <!-- <div class="introduce-part expert-5 flex-cc" style="margin-bottom: 30px;">
            <img src="../../assets/images/vip/expert/icon5.png" alt="" style="width: 44px;height: 46px;margin-right: 41px;">
            <div class="right">
              <p class="title">项目、成果、需求多维度推荐</p>
              <p>资本、企业、专家教授精准推荐，快速获得解决方案</p>
            </div>
          </div> -->
          <div class="introduce-part expert-6 flex-cc" style="margin-bottom: 30px;">
            <img src="../../assets/images/vip/expert/icon6.png" alt="" style="width: 50px;height: 38px;margin-right: 34px;">
            <div class="right">
              <p class="title">在线专家技术诊断</p>
              <p>行业权威专家技术诊断，解决技术难题，实现技</p>
              <p>术升级</p>
            </div>
          </div>
          <div class="introduce-part expert-7 flex-cc">
            <img src="../../assets/images/vip/expert/icon7.png" alt="" style="width: 48px;height: 48px;margin-right: 35px;">
            <div class="right">
              <p class="title">金牌管家一对一专属服务</p>
              <p>提供电话，在线咨询，专家对接，需求对接等专</p>
              <p>属服务</p>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="bottomCur === 1">
        <div class="title-part">
          <img src="../../assets/images/vip/expert/title-icon.png" style="width: 18px;height: 16px;margin-right: 6px;"/>
          创新赛道
        </div>
        <div class="flex-cc vip-introduce custom-wrap" style="margin: 16px auto 0;">
          <div class="custom-window flex-dc-cc" style="margin-bottom: 24px;">
            <img src="../../assets/images/vip/type3/vip-3-0.png" />
            项目孵化
          </div>
          <div class="custom-window flex-dc-cc" style="margin-bottom: 24px;">
            <img src="../../assets/images/vip/type3/vip-3-1.png" />
            项目精准对接
          </div>
          <div class="custom-window flex-dc-cc" style="margin-bottom: 24px;">
            <img src="../../assets/images/vip/type3/vip-3-2.png" />
            专家对接
          </div>
          <div class="custom-window flex-dc-cc" style="margin-bottom: 24px;">
            <img src="../../assets/images/vip/type3/vip-3-3.png" />
            技术研报
          </div>
          <div class="custom-window flex-dc-cc" style="margin-bottom: 24px;">
            <img src="../../assets/images/vip/type3/vip-3-4.png" />
            项目诊断
          </div>
          <div class="custom-window flex-dc-cc">
            <img src="../../assets/images/vip/type3/vip-3-5.png" />
            定制管理系统
          </div>
          <div class="custom-window flex-dc-cc">
            <img src="../../assets/images/vip/type3/vip-3-6.png" />
            项目监管
          </div>
          <div class="custom-window flex-dc-cc">
            <img src="../../assets/images/vip/type3/vip-3-7.png" />
            项目验收
          </div>
          <div class="custom-window flex-dc-cc">
            <img src="../../assets/images/vip/type3/vip-3-8.png" />
            专属定制频道
          </div>
          <div class="custom-window flex-dc-cc">
            <img src="../../assets/images/vip/type3/vip-3-9.png" />
            共建科创中心
          </div>
        </div>
        <div class="flex-cc vip-introduce">
          <div class="introduce-part expert-2 flex-cc" style="margin-bottom: 30px;">
            <img src="../../assets/images/vip/expert/icon2.png" alt="" style="width: 51px;height: 47px;margin-right: 33px;">
            <div class="right">
              <p class="title">项目通</p>
              <p>全国上万家科研院所前沿项目在线对接，第一时</p>
              <p>间捕获未来独角兽</p>
            </div>
          </div>
          <div class="introduce-part expert-5 flex-cc" style="margin-bottom: 30px;">
            <img src="../../assets/images/vip/expert/icon5.png" alt="" style="width: 44px;height: 46px;margin-right: 41px;">
            <div class="right">
              <p class="title">项目、成果、需求多维度推荐</p>
              <p>资本、企业、专家教授精准推荐，快速获得解决方案</p>
            </div>
          </div>
          <div class="introduce-part expert-8 flex-cc" style="margin-bottom: 30px;">
            <img src="../../assets/images/vip/expert/icon8.png" alt="" style="width: 46px;height: 46px;margin-right: 33px;">
            <div class="right">
              <p class="title">海量数据、专家、项目、成果</p>
              <p>百万级专家教授，千万级技术成果 1万+硬科技</p>
              <p>项目，第一时间查看对接，发现行业新机会</p>
            </div>
          </div>
          <div class="introduce-part expert-7 flex-cc" style="margin-bottom: 30px;">
            <img src="../../assets/images/vip/expert/icon7.png" alt="" style="width: 48px;height: 48px;margin-right: 35px;">
            <div class="right">
              <p class="title">金牌管家一对一专属服务</p>
              <p>提供电话，在线咨询，专家对接，需求对接等专</p>
              <p>属服务</p>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="bottomCur === 2" style="width: 1200px;">
        <div class="title-part">
          <img src="../../assets/images/vip/expert/title-icon.png" style="width: 18px;height: 16px;margin-right: 6px;"/>
          创投通
        </div>
        <div class="flex-rc-cc" style="padding: 0 8px;margin-top: 16px;">
          <div class="ctt-1 flex-cc" style="margin-bottom: 30px;">
            <img src="../../assets/images/vip/ctt/icon-1.png" alt="" style="width: 37px;height: 39px;margin-right: 36px;">
            <p class="title">为企业匹配投融资专家与投融资机构并开通<br/>专家通</p>
          </div>
          <div class="ctt-2 flex-cc" style="margin-bottom: 30px;">
            <img src="../../assets/images/vip/ctt/icon-2.png" alt="" style="width: 37px;height: 37px;margin-right: 38px;">
            <p class="title" style="width: 342px;">为投融资机构推荐硬科技项目</p>
          </div>
        </div>
      </div>
      <div class="button-window">
        <p>1<span style="font-size: 18px;">年</span></p>
        <p class="tip">每天仅需{{ priceEveryday(products[2]?.marketPrice) }}元</p>
        <p class="price"><span style="font-size: 16px;">￥</span>{{ products[2]?.marketPrice }}</p>
        <div class="time-info">
          {{ vipEndTime?.length > 0 && userInfo.isVip && vipType + '' === '4' ? 'VIP到期时间：' + vipEndTime : '您尚未开通' }}
        </div>
        <div class="button" @click="confirm(2)">
          立即开通
        </div>
      </div>
      <!-- 申请按钮 -->
      <!-- <div class="custom-button" @click="confirm(4)">
        立即申请
      </div> -->
    </div>
    <!-- 创新赛道 -->
    <div v-else-if="typeSelect === 2" class="flex-dc-rc">
      <!-- 介绍 -->
      <div class="flex-cc vip-introduce custom-wrap">
        <div class="custom-window flex-dc-cc" style="margin-bottom: 24px;">
          <img src="../../assets/images/vip/type3/vip-3-0.png" />
          项目孵化
        </div>
        <div class="custom-window flex-dc-cc" style="margin-bottom: 24px;">
          <img src="../../assets/images/vip/type3/vip-3-1.png" />
          项目精准对接
        </div>
        <div class="custom-window flex-dc-cc" style="margin-bottom: 24px;">
          <img src="../../assets/images/vip/type3/vip-3-2.png" />
          专家对接
        </div>
        <div class="custom-window flex-dc-cc" style="margin-bottom: 24px;">
          <img src="../../assets/images/vip/type3/vip-3-3.png" />
          技术研报
        </div>
        <div class="custom-window flex-dc-cc" style="margin-bottom: 24px;">
          <img src="../../assets/images/vip/type3/vip-3-4.png" />
          项目诊断
        </div>
        <div class="custom-window flex-dc-cc">
          <img src="../../assets/images/vip/type3/vip-3-5.png" />
          定制管理系统
        </div>
        <div class="custom-window flex-dc-cc">
          <img src="../../assets/images/vip/type3/vip-3-6.png" />
          项目监管
        </div>
        <div class="custom-window flex-dc-cc">
          <img src="../../assets/images/vip/type3/vip-3-7.png" />
          项目验收
        </div>
        <div class="custom-window flex-dc-cc">
          <img src="../../assets/images/vip/type3/vip-3-8.png" />
          专属定制频道
        </div>
        <div class="custom-window flex-dc-cc">
          <img src="../../assets/images/vip/type3/vip-3-9.png" />
          共建科创中心
        </div>
      </div>
      <div class="flex-cc vip-introduce">
        <div class="introduce-part expert-2 flex-cc" style="margin-bottom: 30px;">
          <img src="../../assets/images/vip/expert/icon2.png" alt="" style="width: 51px;height: 47px;margin-right: 33px;">
          <div class="right">
            <p class="title">项目通</p>
            <p>全国上万家科研院所前沿项目在线对接，第一时</p>
            <p>间捕获未来独角兽</p>
          </div>
        </div>
        <div class="introduce-part expert-5 flex-cc" style="margin-bottom: 30px;">
          <img src="../../assets/images/vip/expert/icon5.png" alt="" style="width: 44px;height: 46px;margin-right: 41px;">
          <div class="right">
            <p class="title">项目、成果、需求多维度推荐</p>
            <p>资本、企业、专家教授精准推荐，快速获得解决方案</p>
          </div>
        </div>
        <div class="introduce-part expert-8 flex-cc" style="margin-bottom: 30px;">
          <img src="../../assets/images/vip/expert/icon8.png" alt="" style="width: 46px;height: 46px;margin-right: 33px;">
          <div class="right">
            <p class="title">海量数据、专家、项目、成果</p>
            <p>百万级专家教授，千万级技术成果 1万+硬科技</p>
            <p>项目，第一时间查看对接，发现行业新机会</p>
          </div>
        </div>
        <div class="introduce-part expert-7 flex-cc" style="margin-bottom: 30px;">
          <img src="../../assets/images/vip/expert/icon7.png" alt="" style="width: 48px;height: 48px;margin-right: 35px;">
          <div class="right">
            <p class="title">金牌管家一对一专属服务</p>
            <p>提供电话，在线咨询，专家对接，需求对接等专</p>
            <p>属服务</p>
          </div>
        </div>
      </div>
      <!-- 购买按钮 -->
      <div class="button-window">
        <p>1<span style="font-size: 18px;">年</span></p>
        <p class="tip">每天仅需{{ priceEveryday(products[1]?.marketPrice) }}元</p>
        <p class="price"><span style="font-size: 16px;">￥</span>{{ products[1]?.marketPrice }}</p>
        <div class="time-info" v-if="!projectTime.vipEndTime">
          {{'您尚未开通'}}
        </div>
        <div class="time-info" v-else>
          {{ '到期时间：' + projectTime.vipEndTime}}
        </div>
        <div class="button" @click="confirm(1)">
          立即开通
        </div>
      </div>
    </div>
    <!-- 数字科创 -->
    <div v-else-if="typeSelect === -2" class="flex-dc-rc">
      <!-- 地图 -->
      <div class="title-part">
        <img src="../../assets/images/vip/expert/title-icon.png" style="width: 18px;height: 16px;margin-right: 6px;"/>
        数据看板
      </div>
      <ChinaMap :mapHeight="385" ></ChinaMap>
      <!-- 介绍 -->
      <div class="title-part">
        <img src="../../assets/images/vip/expert/title-icon.png" style="width: 18px;height: 16px;margin-right: 6px;"/>
        5大权益
      </div>
      <div class="flex-cc vip-introduce">
        <div class="introduce-part expert-1 flex-cc" style="margin-bottom: 30px;">
          <img src="../../assets/images/vip/expert/icon1.png" alt="" style="width: 44px;height: 43px;margin-right: 37px;">
          <div class="right">
            <p class="title">专家通</p>
            <p>全领域专家在线联络，多方位复合筛选，精准查找</p>
          </div>
        </div>
        <!-- <div class="introduce-part expert-2 flex-cc" style="margin-bottom: 30px;">
          <img src="../../assets/images/vip/expert/icon2.png" alt="" style="width: 51px;height: 47px;margin-right: 33px;">
          <div class="right">
            <p class="title">创新赛道</p>
            <p>全国上万家科研院所前沿项目在线对接，第一时</p>
            <p>间捕获未来独角兽</p>
          </div>
        </div> -->
        <div class="introduce-part expert-4 flex-cc" style="margin-bottom: 30px;">
          <img src="../../assets/images/vip/expert/icon4.png" alt="" style="width: 46px;height: 46px;margin-right: 35px;">
          <div class="right">
            <p class="title">海量数据、专家、项目、成果</p>
            <p>百万级专家教授，千万级技术成果 1万+硬科技</p>
            <p>项目，第一时间查看对接，发现行业新机会</p>
          </div>
        </div>
        <div class="introduce-part expert-3 flex-cc" style="margin-bottom: 30px;">
          <img src="../../assets/images/vip/expert/icon3.png" alt="" style="width: 46px;height: 46px;margin-right: 35px;">
          <div class="right">
            <p class="title">专属数字科创中心</p>
            <p>一人创建，多人同享</p>
          </div>
        </div>
        <!-- <div class="introduce-part expert-5 flex-cc" style="margin-bottom: 30px;">
          <img src="../../assets/images/vip/expert/icon5.png" alt="" style="width: 44px;height: 46px;margin-right: 41px;">
          <div class="right">
            <p class="title">项目、成果、需求多维度推荐</p>
            <p>资本、企业、专家教授精准推荐，快速获得解决方案</p>
          </div>
        </div> -->
        <div class="introduce-part expert-6 flex-cc" style="margin-bottom: 30px;">
          <img src="../../assets/images/vip/expert/icon6.png" alt="" style="width: 50px;height: 38px;margin-right: 34px;">
          <div class="right">
            <p class="title">在线专家技术诊断</p>
            <p>行业权威专家技术诊断，解决技术难题，实现技</p>
            <p>术升级</p>
          </div>
        </div>
        <div class="introduce-part expert-7 flex-cc">
          <img src="../../assets/images/vip/expert/icon7.png" alt="" style="width: 48px;height: 48px;margin-right: 35px;">
          <div class="right">
            <p class="title">金牌管家一对一专属服务</p>
            <p>提供电话，在线咨询，专家对接，需求对接等专</p>
            <p>属服务</p>
          </div>
        </div>
      </div>
      <!-- 购买按钮 -->
      <div class="button-window">
        <p>1<span>年</span></p>
        <p class="tip">每天仅需{{ priceEveryday(products[0]?.marketPrice) }}元</p>
        <p class="price"><span style="font-size: 16px;">￥</span>{{ products[0]?.marketPrice }}</p>
        <div class="time-info">
          {{ vipEndTime?.length > 0 && userInfo.isVip && vipType + '' === '5' ? 'VIP到期时间：' + vipEndTime : '您尚未开通' }}
        </div>
        <div class="button" @click="confirm(0)">
          立即开通
        </div>
      </div>
    </div>
    <!-- 创新赛道 -->
    <div v-else-if="typeSelect === -1" class="flex-dc">
      <!-- 介绍 -->
      <div class="flex-cc vip-introduce" style="width: 1080px;padding: 0;">
        <div class="custom-window flex-dc-cc" style="margin-bottom: 24px;">
          <img src="../../assets/images/vip/type3/vip-3-0.png" />
          项目孵化
        </div>
        <div class="custom-window flex-dc-cc" style="margin-bottom: 24px;">
          <img src="../../assets/images/vip/type3/vip-3-1.png" />
          项目精准对接
        </div>
        <div class="custom-window flex-dc-cc" style="margin-bottom: 24px;">
          <img src="../../assets/images/vip/type3/vip-3-2.png" />
          专家对接
        </div>
        <div class="custom-window flex-dc-cc" style="margin-bottom: 24px;">
          <img src="../../assets/images/vip/type3/vip-3-3.png" />
          技术研报
        </div>
        <div class="custom-window flex-dc-cc" style="margin-bottom: 24px;">
          <img src="../../assets/images/vip/type3/vip-3-4.png" />
          项目诊断
        </div>
        <div class="custom-window flex-dc-cc">
          <img src="../../assets/images/vip/type3/vip-3-5.png" />
          定制管理系统
        </div>
        <div class="custom-window flex-dc-cc">
          <img src="../../assets/images/vip/type3/vip-3-6.png" />
          项目监管
        </div>
        <div class="custom-window flex-dc-cc">
          <img src="../../assets/images/vip/type3/vip-3-7.png" />
          项目验收
        </div>
        <div class="custom-window flex-dc-cc">
          <img src="../../assets/images/vip/type3/vip-3-8.png" />
          专属定制频道
        </div>
        <div class="custom-window flex-dc-cc">
          <img src="../../assets/images/vip/type3/vip-3-9.png" />
          共建科创中心
        </div>
      </div>
      <!-- 申请按钮 -->
      <div class="custom-button" @click="confirm(3)">
        立即申请
      </div>
    </div>
    <!-- 新数字科创 -->
    <div v-else-if="typeSelect === 3" class="flex-dc-rc">
      <!-- 介绍 -->
      <div class="flex-cc vip-introduce">
        <div class="introduce-part expert-1 flex-cc" style="margin-bottom: 30px;">
          <img src="../../assets/images/vip/expert/icon1.png" alt="" style="width: 44px;height: 43px;margin-right: 37px;">
          <div class="right">
            <p class="title">专家通</p>
            <p>全领域专家在线联络，多方位复合筛选，精准查找</p>
          </div>
        </div>
        <div class="introduce-part expert-2 flex-cc" style="margin-bottom: 30px;">
          <img src="../../assets/images/vip/expert/icon2.png" alt="" style="width: 51px;height: 47px;margin-right: 33px;">
          <div class="right">
            <p class="title">创新赛道</p>
            <p>全国上万家科研院所前沿项目在线对接，第一时</p>
            <p>间捕获未来独角兽</p>
          </div>
        </div>
        <div class="introduce-part expert-4 flex-cc" style="margin-bottom: 30px;">
          <img src="../../assets/images/vip/expert/icon4.png" alt="" style="width: 46px;height: 46px;margin-right: 35px;">
          <div class="right">
            <p class="title">海量数据、专家、项目、成果</p>
            <p>百万级专家教授，千万级技术成果 1万+硬科技</p>
            <p>项目，第一时间查看对接，发现行业新机会</p>
          </div>
        </div>
        <div class="introduce-part expert-3 flex-cc" style="margin-bottom: 30px;">
          <img src="../../assets/images/vip/expert/icon3.png" alt="" style="width: 46px;height: 46px;margin-right: 35px;">
          <div class="right">
            <p class="title">专属数字科创中心</p>
            <p>一人创建，多人同享</p>
          </div>
        </div>
        <div class="introduce-part expert-5 flex-cc" style="margin-bottom: 30px;">
          <img src="../../assets/images/vip/expert/icon5.png" alt="" style="width: 44px;height: 46px;margin-right: 41px;">
          <div class="right">
            <p class="title">项目、成果、需求多维度推荐</p>
            <p>资本、企业、专家教授精准推荐，快速获得解决方案</p>
          </div>
        </div>
        <div class="introduce-part expert-6 flex-cc" style="margin-bottom: 30px;">
          <img src="../../assets/images/vip/expert/icon6.png" alt="" style="width: 50px;height: 38px;margin-right: 34px;">
          <div class="right">
            <p class="title">在线专家技术诊断</p>
            <p>行业权威专家技术诊断，解决技术难题，实现技</p>
            <p>术升级</p>
          </div>
        </div>
        <!-- <div class="introduce-part expert-5 flex-cc">
          <img src="../../assets/images/vip/expert/icon5.png" alt="" style="width: 44px;height: 46px;margin-right: 41px;">
          <div class="right">
            <p class="title">项目、成果、需求多维度推荐</p>
            <p>资本、企业、专家教授精准推荐，快速获得解决方案</p>
          </div>
        </div> -->
        <div class="introduce-part expert-7 flex-cc">
          <img src="../../assets/images/vip/expert/icon7.png" alt="" style="width: 48px;height: 48px;margin-right: 35px;">
          <div class="right">
            <p class="title">金牌管家一对一专属服务</p>
            <p>提供电话，在线咨询，专家对接，需求对接等专</p>
            <p>属服务</p>
          </div>
        </div>
      </div>
      <div class="button-window">
        <p>1<span style="font-size: 18px;">年</span></p>
        <p class="tip">每天仅需{{ priceEveryday(products[2]?.marketPrice) }}元</p>
        <p class="price"><span style="font-size: 16px;">￥</span>{{ products[2]?.marketPrice }}</p>
        <div class="time-info">
          {{ vipEndTime?.length > 0 && userInfo.isVip && vipType + '' === '4' ? 'VIP到期时间：' + vipEndTime : '您尚未开通' }}
        </div>
        <div class="button" @click="confirm(2)">
          立即开通
        </div>
      </div>
      <!-- 申请按钮 -->
      <!-- <div class="custom-button" @click="confirm(4)">
        立即申请
      </div> -->
    </div>
    <!-- 科企通 -->
    <div v-else-if="typeSelect === 5" class="flex-dc-rc">
      <!-- 介绍 -->
      <div class="flex-cc vip-introduce" style="padding: 0 8px;">
        <div class="introduce-part kqt-1 flex-cc" style="margin-bottom: 30px;">
          <img src="../../assets/images/vip/kqt/icon1.png" alt="" style="width: 34px;height: 42px;margin-right: 42px;">
          <div class="right">
            <p class="title">联合实验室</p>
          </div>
        </div>
        <div class="introduce-part kqt-2 flex-cc" style="margin-bottom: 30px;">
          <img src="../../assets/images/vip/kqt/icon2.png" alt="" style="width: 49px;height: 42px;margin-right: 22px;">
          <div class="right">
            <p class="title">研究生实习基地</p>
          </div>
        </div>
        <div class="introduce-part kqt-3 flex-cc" style="margin-bottom: 30px;">
          <img src="../../assets/images/vip/kqt/icon3.png" alt="" style="width: 44px;height: 44px;margin-right: 37px;">
          <div class="right">
            <p class="title">产学研技术转化</p>
          </div>
        </div>
        <div class="introduce-part kqt-4 flex-cc" style="margin-bottom: 30px;">
          <img src="../../assets/images/vip/kqt/icon4.png" alt="" style="width: 35px;height: 43px;margin-right: 37px;">
          <div class="right">
            <p class="title">科技金融服务</p>
          </div>
        </div>
        <div class="introduce-part kqt-5 flex-cc" style="margin-bottom: 30px;">
          <img src="../../assets/images/vip/kqt/icon5.png" alt="" style="width: 34px;height: 42px;margin-right: 21px;">
          <div class="right">
            <p class="title">荣誉技术职称申请</p>
          </div>
        </div>
        <div style="width: 380px;"></div>
      </div>
      <!-- 申请按钮 -->
      <div class="custom-button" @click="confirm(5)">
        立即申请
      </div>
    </div>
    <!-- 创投通 -->
    <div v-else-if="typeSelect === 4" class="flex-dc-rc">
      <!-- 介绍 -->
      <div class="flex-cc" style="padding: 0 8px;">
        <div class="ctt-1 flex-cc" style="margin-bottom: 30px;">
          <img src="../../assets/images/vip/ctt/icon-1.png" alt="" style="width: 37px;height: 39px;margin-right: 36px;">
          <p class="title">为企业匹配投融资专家与投融资机构并开通<br/>专家通</p>
        </div>
        <div class="ctt-2 flex-cc" style="margin-bottom: 30px;">
          <img src="../../assets/images/vip/ctt/icon-2.png" alt="" style="width: 37px;height: 37px;margin-right: 38px;">
          <p class="title" style="width: 342px;">为投融资机构推荐硬科技项目</p>
        </div>
      </div>
      <div class="button-window">
        <p>1<span style="font-size: 18px;">年</span></p>
        <p class="tip">每天仅需{{ priceEveryday(products[3]?.marketPrice) }}元</p>
        <p class="price"><span style="font-size: 16px;">￥</span>{{ products[3]?.marketPrice }}</p>
        <div class="time-info">
          {{ vipEndTime?.length > 0 && userInfo.isVip && vipType + '' === '8' ? 'VIP到期时间：' + vipEndTime : '您尚未开通' }}
        </div>
        <div class="button" @click="confirm(3)">
          立即开通
        </div>
      </div>
      <!-- 申请按钮 -->
      <!-- <div class="custom-button" @click="confirm(6)">
        立即申请
      </div> -->
    </div>
    <!-- 数字产学研 -->
    <div v-else-if="typeSelect === 7" class="flex-dc-rc">
      <!-- 介绍 -->
      <div class="flex-cc" style="padding: 0 8px;">
        <div class="csy-1 flex-cc" style="margin-bottom: 30px;">
          <img src="../../assets/images/vip/csy/icon-1.png" alt="" style="width: 35px;height: 39px;margin-right: 38px;">
          <p class="title">高效率、低成本、多维度推广技术成果，有<br/>效促进合作对接</p>
        </div>
        <div class="csy-2 flex-cc" style="margin-bottom: 30px;">
          <img src="../../assets/images/vip/csy/icon-2.png" alt="" style="width: 38px;height: 38px;margin-right: 36px;">
          <p class="title">企业资源任您挑选，实现技术转移、转化，<br/>达成合作</p>
        </div>
      </div>
      <!-- 申请按钮 -->
      <div class="custom-button" @click="confirm(7)">
        立即申请
      </div>
    </div>
    <!-- 会员购买弹窗 -->
    <div
      @click="showPurchaseDialog = false"
      class="pay-modal"
      v-if="showPurchaseDialog">
      <div class="pay-window" @click.stop="">
        <div class="pay-window-title">
          VIP会员购买
          <img
            src="../../assets/images/vip/close-button.png"
            alt=""
            @click="showPurchaseDialog = false"
          />
        </div>
        <div class="pay-window-content" v-if="progressIndex === 0">
          <div class="pay-info-item">
            <div class="pay-info-item-label">产品名称：</div>
            <div class="">
              <el-select
                disabled
                v-model="selectedOptionIndex"
                placeholder="请选择"
                style="width: 100%">
                <el-option
                  v-for="(o, i) in products"
                  :key="i"
                  :label="o.name"
                  :value="i"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="pay-info-item">
            <div class="pay-info-item-label">产品类型：</div>
            <div class="pay-info-item-value">
              {{ selectedOption.name }}
            </div>
          </div>
          <div class="pay-info-item">
            <div class="pay-info-item-label">总额：</div>
            <div
              class="pay-info-item-value price"
              v-if="products[selectedOptionIndex].name === '金牌VIP'"
            >
              {{ selectedOption.salePrice * quantity }} 元
            </div>
            <div class="pay-info-item-value price" v-else>
              {{ selectedOption.marketPrice }} 元
            </div>
          </div>
          <div class="ready-pay-button-group">
            <div
              @click.stop="nextPayProgress(payTypeWechat)"
              class="wechat-button button"
            >
              <img src="../../assets/images/vip/wechat-new.png" alt="" />
              <div>微信支付</div>
            </div>
            <div
              @click.stop="nextPayProgress(payTypeAlipay)"
              class="alipay-button button"
            >
              <img src="../../assets/images/vip/alipay-new.png" alt="" />
              <div>支付宝支付</div>
            </div>
          </div>
          <!-- 1.7 改为a标签 -->
          <div class="bottom-tips">
            付费即代表您同意<a class="url-font" target="_blank" href="/nrsm"
              >《科企岛平台内容须知》</a
            >。<a class="url-font" target="_blank" href="/vipxy"
              >《科企岛付费VIP会员协议》</a
            >，您可以在会员 有效期内，享受全部会员特权
          </div>
        </div>
        <div
          class="pay-window-content wait-for-payment"
          v-if="progressIndex === 1"
          v-loading="loading">
          <div class="pay-info-item">
            <div class="pay-info-item-label">订单编号：</div>
            <div class="pay-info-item-value">{{ orderInfo.orderNo }}</div>
          </div>
          <div class="pay-info-item">
            <div class="pay-info-item-label">应付金额：</div>
            <div class="pay-info-item-value" v-if="products[selectedOptionIndex].name === '金牌VIP'">
              ￥ {{ selectedOption.salePrice * quantity }}
            </div>
            <div class="pay-info-item-value" v-else>
              ￥ {{ selectedOption.marketPrice }}
            </div>
          </div>
          <div class="timer">
            请在<span>{{ timer }}</span
            >内完成付款
          </div>
          <div class="pay-link">
            <div ref="alipay-block" v-show="showAlipayBlock"></div>
            <div class="qrcode-container" v-show="showQRCode">
              <qrcode-vue :value="QRCodeValue" :size="75"></qrcode-vue>
            </div>
          </div>
          <div
            class="ready-pay-button-group"
            style="margin-top: 0px; margin-bottom: 10px"
          >
            <el-button
              @click="checkPaymentStatus()"
              class="pay-check-button button"
              icon="el-icon-check"
              >已成功支付</el-button
            >
            <el-button
              @click="resetProgress()"
              type="info"
              class="button"
              icon="el-icon-warning"
              >取消支付</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 定制弹层 -->
    <el-dialog
      v-model="vipCustomDialog"
      center
      :show-close="false"
      :top="'15vh'">
      <div class="dialog-top">
        获取科企岛定制会员特权
        <div class="close-btn" @click="vipCustomDialog = false">
          <i class="el-icon-close"></i>
        </div>
      </div>
      <el-form :model="vipCustomForm" class="form-container">
        <el-form-item :label-width="formLabelWidth">
          <div class="form-item form-margin">姓名</div>
          <el-input
            placeholder="请输入姓名"
            v-model="vipCustomForm.memberName"
            autocomplete="off"
            :maxlength="100"
            show-word-limit
          />
        </el-form-item>
        <el-form-item :label-width="formLabelWidth">
          <div class="form-item">手机 <span class="require">*</span></div>
          <el-input
            placeholder="请输入手机号码"
            v-model="vipCustomForm.contactWay"
            autocomplete="off"
            @input="handlePhoneChange"
            maxlength="11"
          />
        </el-form-item>
        <el-form-item :label-width="formLabelWidth">
          <div class="pos">
            <el-input
              placeholder="请输入验证码"
              v-model="vipCustomForm.code"
              autocomplete="off"
              @input="handleCaptcha"
              maxlength="6"
            />
            <span v-if="showCurCode" @click="getCustomPhoneCode"
              >发送验证码</span
            >
            <span v-else class="count-down">{{ count }}S</span>
          </div>
        </el-form-item>
        <span class="dialog-footer">
          <div class="submit-button" type="primary" @click="customSubmit">
            提交
          </div>
        </span>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import to from 'await-to-js';
import { allProducts,getPaymentParams,createOrder } from "@/api/G60";
import { paymentStatus } from "@/api/order";
import { memberDetails,getPhoneCode,getUserRightTimes } from "@/api/user";
import { customizedVipApi,szkcVIP,keQiTongVIP,chuangTouTongVIP,numberIur } from "@/api/pay"; // customizedVipLoginApi
import { ElMessage } from 'element-plus';
import QrcodeVue from 'qrcode.vue';
import { timeFromList } from "@/utils/time";
import { mapGetters } from 'vuex';
import ChinaMap from "@/components/ChinaMap/ChinaMap.vue";
import { getG60Id } from "@/utils/G60/login";

export default {
  components: { QrcodeVue,ChinaMap },
  created(){
    this.getUserInfo()
    this.getProduct()
    if(this.$route.query.setType?.length > 0){
      // this.typeSelect = Number(this.$route.query.setType)
      // if(this.typeSelect > 4) this.typeSelect = 1 // 超出范围
      this.typeSelect = 1
    }
    else{
      this.typeSelect = 1
    }
  },
  data(){
    return{
      vipType: -1, // -1 无 4 数字科创  5 专家通 7 创新赛道 8 创投通
      bottomCur: 0, // 下方选项
      vipEndTime: '', // VIP到期时间
      expertTime:{  // 专家通次数
        currentCount: 0,
        maxCount: 0
      },
      projectTime:{ // 项目通次数
        currentCount: 0,
        vipEndTime: '',
        maxCount: 0
      },
      typeSelect: 0,  // VIP 选项
      verifyStatus: -1, // 认证会员状态
      quantity: 5,  // 个数
      loading: false, // 购买参数加载
      showAlipayBlock: false, // 是否展示支付宝模块
      QRCodeValue: '', // 二维码值,
      showQRCode: false,  // 是否展示二维码
      orderCount: undefined, // 订单倒计时 计时器
      digitTimer: 300,  // 订单付款倒计时 300S
      count: 60,  // 倒计时
      orderInfo: {  // 订单信息
        orderId: '',
        orderNo: ''
      },
      payTypeWechat: 7, // 微信购买符
      payTypeAlipay: 6, // 支付宝购买副
      showCurCode: true, // 是否显示倒计时
      timerCode: null,  // 验证码倒计时
      vipCustomDialog: false, // 定制会员弹层
      showPurchaseDialog: false,  // 是否展示购买弹窗
      selectedOptionIndex: 0, // 选择的产品项
      vipCustomForm: {},  // 定制VIP表单
      progressIndex: 0, // 过程页
      products: [], // 产品
      userInfo: {}, // 用户信息
    }
  },
  computed: {
     ...mapGetters(['reloadUser']),
    statusButtonText(){
      // 立即认证按钮文案
      switch (this.verifyStatus + '') {
        case '1':
          return '认证中'
        case '2':
          return '认证成功'
        case '3':
          return '重新认证'
        default:
          if(this.userInfo.organizationId){
            return '认证中'
          }
          return '免费认证'
      }
    },
    timer() { // 倒计时展示值
      let second = this.digitTimer % 60
      return `0${Math.floor(this.digitTimer / 60)}: ${second >= 10 ? second : '0' + second}`
    },
    selectedOption() {  // 选择的商品
      return this.products[this.selectedOptionIndex]
    }
  },
  watch:{
    reloadUser(){
      // 重新编辑完刷新用户状态
      this.getUserInfo()
    },
    selectedOptionIndex(){
      // 选项切换
      this.quantity = 5
    },
    digitTimer(val){
      // 订单超时提醒
      if(val === 0){
        ElMessage.error('订单已经超时,请重新支付!')
        this.resetProgress()  // 重置订单
      }
    }
  },
  beforeUnmount(){
    clearInterval(this.orderCount) // 离开前清除定时器
  },
  methods:{
    priceEveryday(price){
      // 每日的价格
      return (price / 365).toFixed(1)
    },
    changeTypeSelect(type){
      // 切换VIP类型
      this.typeSelect = type
      this.$router.push({
        path: '/vip',
        query:{
          setType: type
        }
      })
    },
    timeFromList,
    generateWechatQRCode(val) {
      // 获取微信购买二维码
      this.showQRCode = true
      this.QRCodeValue = val
    },
    generateAliPayBlock(code) {
      // 获取支付宝购买模块
      this.showAlipayBlock = true
      let alipayBLock = this.$refs["alipay-block"]
      alipayBLock.innerHTML = code
      document.forms.punchout_form.submit()
    },
    getCustomPhoneCode(){
      // 获取定制会员手机号
      const TIME_COUNT = 60;
      if (!this.vipCustomForm.contactWay) {
        ElMessage.warning('请输入手机号码！')
        return
      }
      getPhoneCode({phone: this.vipCustomForm.contactWay}).then(() => {
        if (!this.timerCode) {
          this.count = TIME_COUNT;
          this.showCurCode = false;
          this.timerCode = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count --;
            } else {
              this.showCurCode = true;
              clearInterval(this.timerCode)
              this.timerCode = null
            }
          }, 1000)
        }
        ElMessage.success('发送成功')
      }).catch(err => {
        console.log(err);
      })
    },
    customSubmit(){
      // 定制会员提交
      const { memberName, contactWay, code } = this.vipCustomForm
      if(!memberName) {
        ElMessage.warning('名字不能为空')
        return
      }
      if(!contactWay) {
        ElMessage.warning('手机不能为空')
        return
      }
      if(!code) {
        ElMessage.warning('验证码不能为空')
        return
      }
      customizedVipApi(this.vipCustomForm).then(()=>{
        ElMessage.success('提交成功')
        this.vipCustomDialog = false
        this.vipCustomForm = {}
        clearInterval(this.timerCode)
        this.timerCode = null
        this.showCurCode = true
      })
    },
    async getUserInfo(){
      // 获取用户信息
      const [err,res] = await to(memberDetails(getG60Id()))
      if(err){
        console.log('获取用户信息失败',err);
        return
      }
      this.userInfo = res.data
      if(!this.userInfo.organizationId){
        // 无组织
        this.verifyStatus = 0
      }
      else if(this.userInfo.organizationType === 1){
        this.vipEndTime = this.timeFromList(
          res.data.organizationVipEndTime?.splice(0, 3)
        );
      }
      else{
        this.vipEndTime = this.timeFromList(
          res.data.vipEndTime?.slice(0, 3)
        );
      }
      this.vipType = this.userInfo.organizationVipType || this.userInfo.vipType // VIP 类型设置
      // const [err1,res1] = await to(getUserVerifyStatus({id:this.userInfo.id}))
      // if(err1){
      //   console.log('获取用户审核状态失败',err);
      //   return
      // }
      // this.verifyStatus = res1.data.verifyStatus
      this.getUserTimes()
    },
    async getUserTimes(){
      const [err2,res2] = await to(getUserRightTimes(this.userInfo.id))
      if(err2){
        console.error('获取用户权限错误：',err2);
        return
      }
      else if(res2.data.length > 0){
        res2.data.forEach((type)=>{
          switch (type.typeName) {
            case '专家通':
              this.expertTime.currentCount = type.currentCount
              this.expertTime.maxCount = type.maxCount
              break;
            case '创新赛道':
              this.projectTime.currentCount = type.currentCount
              this.projectTime.maxCount = type.maxCount
              break;
            default:
              if(type.type === 'projectTime' && type.vipEndTime){
                this.projectTime.vipEndTime = type.vipEndTime.slice(0,3).map((t)=>t >= 10 ? t + '': '0' + t).join('-')
              }
              break;
          }
        })
      }
    },
    async getProduct() {
      // 获取产品列表
      this.products = []
      const [err,res] = await to(allProducts())
      if(err){
        console.log('获取付费产品失败',err);
        return
      }
      this.products = res.data.result.filter(item=>item.name !== '定制会员' && item.name !== '定制服务')
      console.log(this.products,'hehe2');
    },
    async startPurchaseProgress(i) {
      // 开始购买过程
      if(this.userInfo.id){
        // if(i === 3){
        //   // 定制会员
        //   let data = {}
        //   data.contactWay = this.userInfo.phone
        //   data.memberName = this.userInfo.name
        //   const [err,res] = await to(customizedVipLoginApi(data))
        //   if(err){
        //     console.log('定制会员错误',err);
        //     return
        //   }
        //   else if(res.code=='Sx200'){
        //     ElMessage.success('提交成功')
        //   }
        // } else
        if(i === 4){
          // 数字科创
          let data = {}
          data.contactWay = this.userInfo.phone
          data.memberName = this.userInfo.name
          const [err,res] = await to(szkcVIP(data))
          if(err){
            console.log('数字科创VIP错误',err);
            return
          }
          else if(res.code=='Sx200'){
            ElMessage.success('提交成功')
          }
        } else if(i === 5){
          // 科企通
          let data = {}
          data.contactWay = this.userInfo.phone
          data.memberName = this.userInfo.name
          const [err,res] = await to(keQiTongVIP(data))
          if(err){
            console.log('科企通VIP错误',err);
            return
          }
          else if(res.code=='Sx200'){
            ElMessage.success('提交成功')
          }
        } else if(i === 6){
          // 创投通
          let data = {}
          data.contactWay = this.userInfo.phone
          data.memberName = this.userInfo.name
          const [err,res] = await to(chuangTouTongVIP(data))
          if(err){
            console.log('科企通VIP错误',err);
            return
          }
          else if(res.code=='Sx200'){
            ElMessage.success('提交成功')
          }
        } else if(i === 7){
          // 数字产学研
          let data = {}
          data.contactWay = this.userInfo.phone
          data.memberName = this.userInfo.name
          const [err,res] = await to(numberIur(data))
          if(err){
            console.log('科企通VIP错误',err);
            return
          }
          else if(res.code=='Sx200'){
            ElMessage.success('提交成功')
          }
        } else{
          if(this.progressIndex === 1 && this.selectedOptionIndex !== i){ // 如果已经有订单，且与选择的内容不同
            ElMessage.warning('之前订单已经取消!')
            this.selectedOptionIndex = i
            this.resetProgress()
            this.showPurchaseDialog = true
            return
          }
          this.selectedOptionIndex = i
          this.showPurchaseDialog = true
        }
      }else{ // 若未登录前往登录
        if(i == 1){
          this.vipCustomDialog = true
          this.vipCustomForm = {}
        }else{
          ElMessage.warning('你尚未登录!')
          this.$router.push({path:'/login',query: {'redirect': window.location.href}})
        }
        return
      }
    },
    resetProgress() { // 重置购买过程
      this.digitTimer = 300 // 重置计时器
      clearInterval(this.orderCount) // 清除定时器
      this.progressIndex = 0
      this.showQRCode = false
      this.showAlipayBlock = false
    },
    async nextPayProgress(payType) {
      // 购买下一过程
      try {
        payType
        this.loading = true
        this.progressIndex = 1
        // 获取订单相关信息
        let res = await createOrder(this.selectedOption.id,this.selectedOptionIndex + 1 + '' !== '2' ? 5 : 1)
        this.orderInfo = res.data
        let paramsRes = await getPaymentParams(this.orderInfo.orderNo, this.selectedOption.name, payType)
        if (payType === this.payTypeWechat) {
          this.generateWechatQRCode(paramsRes.data.invokeParam.codeUrl)
        } else if (payType === this.payTypeAlipay) {
          this.generateAliPayBlock(paramsRes.data.invokeParam.biz_content)
        }
        let that = this
        this.orderCount = setInterval(()=>{  // 倒计时
          that.digitTimer--
        },1000)
      } catch (e) {
        this.resetProgress()
      }
      this.loading = false
    },
    checkPaymentStatus() {
      // 检查购买状况
      paymentStatus(this.orderInfo.orderNo).then(res => {
        if (res.data.isDone) {
          this.$store.dispatch("user/updateVipInfo")
          this.resetProgress()
          this.getUserTimes() // 重新获取次数
          ElMessage.success("订单支付成功")
          this.showPurchaseDialog = false
        } else {
          ElMessage.error("暂未查询到支付记录")
        }
      })
    },
    confirm(type){
      // 点击按钮
      if(!this.userInfo.id){
        // 未登录
        ElMessage.warning('你尚未登录!')
        this.$router.push({
          path: "/login",
          query: { redirect: window.location.href },
        });
        return
      }
      this.startPurchaseProgress(type)
    },
  }
}
</script>

<style lang="scss" scoped>
.new-vip-container{
  width: 100%;
  padding-bottom: 100px;
  margin-top: -2px;
  .banner{
    width: 100%;
  }
  .vip-table{
    width: 1200px;
    .top-bar{
      position: sticky;
      top: 44px;
      width: 100%;
      height: 183px;
      border: 1px solid #EFF1F7;
      border-bottom: none;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      .tab0{
        width: 394px;
        height: 100%;
        background: #FFFFFF;
        text-align: center;
        border-right: 1px solid #EFF1F7;
        line-height: 183px;
      }
      .tab1{
        width: 200px;
        height: 100%;
        background: #FFFFFF;
        text-align: center;
        line-height: 183px;
        border-right: 1px solid #EFF1F7;
      }
      .tab2{
        width: 200px;
        height: 183px;
        background: #FFF3E6;
        text-align: center;
        color: #89602B;
        border-right: 1px solid #EFF1F7;
        p{
          margin: 40px 0;
        }
        button{
          width: 140px;
          height: 42px;
          background: #FAECD6;
          border-radius: 4px;
          border: none;
          cursor: pointer;
        }
      }
      .tab3{
        width: 200px;
        height: 183px;
        background: #FFFFFF;
        text-align: center;
        color: #666666;
        border-right: 1px solid #EFF1F7;
        p{
          margin: 40px 0;
        }
        button{
          width: 140px;
          height: 42px;
          background: #FAECD6;
          border-radius: 4px;
          border: none;
          cursor: pointer;
        }
      }
      .tab4{
        width: 200px;
        height: 183px;
        background: #FFF3E6;
        text-align: center;
        color: #89602B;
        border-right: 1px solid #EFF1F7;
        p{
          margin: 40px 0;
        }
        button{
          width: 140px;
          height: 42px;
          background: #FAECD6;
          border-radius: 4px;
          border: none;
          cursor: pointer;
        }
      }
    }
    .table{
      width: 100%;
      object-fit: cover;
      margin-bottom: 20px;
    }
  }
  .button-window{
    margin-top: 100px;
    width: 454px;
    height: 264px;
    background-image: url('../../assets/images/vip/type0/vip-buy-back.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top left;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 26px 0 17px;
    box-sizing: border-box;
    position: relative;
    .time-info{
      position: absolute;
      height: 32px;
      background: #FD4F3E;
      border-radius: 17px 17px 17px 0px;
      padding: 0 10px;
      line-height: 32px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      top: -24px;
      left: 24px;
      color: #FFFFFF;
    }
    p{
      font-size: 40px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #734806;
    }
    .tip{
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      margin-top: 2px;
      margin-bottom: 10px;
    }
    .price{
      font-size: 48px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FD4F3E;
    }
    .button{
      width: 276px;
      height: 50px;
      background: linear-gradient(90deg, #FDE3BC 0%, #FDE2BB 0%, #F8CD91 100%);
      border-radius: 37px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #734806;
      line-height: 50px;
      margin-top: 20px;
    }
  }
  .csy-1{
    background-image: url('../../assets/images/vip/csy/back-1.png');
    width: 530px;
    height: 150px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    justify-content: center;
    margin: 0 10px;
    .title{
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #111111;
      line-height: 25px;
    }
  }
  .csy-2{
    background-image: url('../../assets/images/vip/csy/back-2.png');
    width: 530px;
    height: 150px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    justify-content: center;
    margin: 0 10px;
    .title{
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #111111;
      line-height: 25px;
    }
  }
  .ctt-1{
    background-image: url('../../assets/images/vip/ctt/back-1.png');
    width: 530px;
    height: 150px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    justify-content: center;
    margin: 0 10px;
    .title{
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #111111;
      line-height: 25px;
    }
  }
  .ctt-2{
    background-image: url('../../assets/images/vip/ctt/back-2.png');
    width: 530px;
    height: 150px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    justify-content: center;
    margin: 0 10px;
    .title{
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #111111;
      line-height: 25px;
    }
  }
  .custom-button{
    width: 381px;
    height: 69px;
    background: linear-gradient(90deg, #FDE3BC 0%, #FDE2BB 0%, #F8CD91 100%);
    border-radius: 35px;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #734806;
    line-height: 69px;
    text-align: center;
    margin: 106px auto 0;
    cursor: pointer;
  }
  .title-part{
    width: 100%;
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #111111;
    line-height: 28px;
    padding-bottom: 13px;
    margin-bottom: 44px;
  }
  .custom-wrap{
    width: 1080px !important;
    padding: 0 !important;
    margin-bottom: 24px !important;
  }
  .new-bottom-type{
    width: 1200px;
    border-bottom: 1px solid #D8D8D8;
    margin-top: 15px;
    margin-bottom: 70px;
    .type{
      height: 42px;
      background: #F5F5F5;
      border-radius: 4px 4px 0px 0px;
      border: 1px solid #D3D3D3;
      border-bottom: none;
      font-size: 23px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #111111;
      line-height: 41px;
      padding: 0 30px;
      margin: 0 30px;
      cursor: pointer;
    }
    .active{
      background-color: #ffffff !important;
      color: #FF9018 !important;
    }
  }
  .vip-introduce{
    width: 1200px;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 60px;
    box-sizing: border-box;
    .custom-window{
      width: 195px;
      height: 137px;
      background: #FFFFFF;
      box-shadow: 0px 0px 3px 0px #F4E9E3;
      align-items: center;
      border: 1px solid #F2F2F2;
      img{
        width: 52px;
        height: 52px;
        margin-bottom: 10px;
      }
      font-size: 17px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #111111;
    }
    .kqt-1{
      width: 380px !important;
      height:  120px !important;
      background-image: url('../../assets/images/vip/kqt/back1.png');
      background-size: 100% 100%;
      .title{
        margin-bottom: 0 !important;
      }
    }
    .kqt-2{
      width: 380px !important;
      height:  120px !important;
      background-image: url('../../assets/images/vip/kqt/back2.png');
      background-size: 100% 100%;
      .title{
        margin-bottom: 0 !important;
      }
    }
    .kqt-3{
      width: 380px !important;
      height:  120px !important;
      background-image: url('../../assets/images/vip/kqt/back3.png');
      background-size: 100% 100%;
      .title{
        margin-bottom: 0 !important;
      }
    }
    .kqt-4{
      width: 380px !important;
      height:  120px !important;
      background-image: url('../../assets/images/vip/kqt/back4.png');
      background-size: 100% 100%;
      .title{
        margin-bottom: 0 !important;
      }
    }
    .kqt-5{
      width: 380px !important;
      height:  120px !important;
      background-image: url('../../assets/images/vip/kqt/back5.png');
      background-size: 100% 100%;
      .title{
        margin-bottom: 0 !important;
      }
    }
    .szkc-1{
      background-image: url('../../assets/images/vip/szkc/back1.png');
      background-size: 100% 100%;
    }
    .szkc-2{
      background-image: url('../../assets/images/vip/szkc/back2.png');
      background-size: 100% 100%;
    }
    .szkc-3{
      background-image: url('../../assets/images/vip/szkc/back3.png');
      background-size: 100% 100%;
    }
    .szkc-4{
      background-image: url('../../assets/images/vip/szkc/back4.png');
      background-size: 100% 100%;
    }
    .expert-1{
      background-image: url('../../assets/images/vip/expert/expert1.png');
      background-size: 100% 100%;
    }
    .expert-2{
      background-image: url('../../assets/images/vip/expert/expert2.png');
      background-size: 100% 100%;
    }
    .expert-3{
      background-image: url('../../assets/images/vip/expert/expert3.png');
      background-size: 100% 100%;
    }
    .expert-4{
      background-image: url('../../assets/images/vip/expert/expert4.png');
      background-size: 100% 100%;
    }
    .expert-5{
      background-image: url('../../assets/images/vip/expert/expert5.png');
      background-size: 100% 100%;
    }
    .expert-6{
      background-image: url('../../assets/images/vip/expert/expert6.png');
      background-size: 100% 100%;
    }
    .expert-7{
      background-image: url('../../assets/images/vip/expert/expert7.png');
      background-size: 100% 100%;
    }
    .expert-8{
      background-image: url('../../assets/images/vip/expert/expert8.png');
      background-size: 100% 100%;
    }
    .introduce-part{
      width: 530px;
      height: 150px;
      box-sizing: border-box;
      padding-left: 34px;
      background-repeat: no-repeat;
      .right{
        p{
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #111111;
          max-width: 356px;
        }
        .title{
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          color: #111111;
          margin-bottom: 19px;
        }
      }
    }
    .type-0-0{
      background-image: url('../../assets/images/vip/type0/vip-0-0-right.png');
      background-size: 187px 154px;
    }
    .type-0-1{
      background-image: url('../../assets/images/vip/type0/vip-0-1-right.png');
      background-size: 211px 162px;
    }
    .type-1-2{
      background-image: url('../../assets/images/vip/type1/vip-1-2-right.png');
      background-size: 211px 162px;
    }
    .type-2-0{
      background-image: url('../../assets/images/vip/type2/vip-2-0-right.png');
      background-size: 530px 170px;
    }
    .type-2-1{
      background-image: url('../../assets/images/vip/type2/vip-2-1-right.png');
      background-size: 530px 170px;
    }
    .type-2-2{
      background-image: url('../../assets/images/vip/type2/vip-2-2-right.png');
      background-size: 530px 170px;
    }
    .type-2-4{
      background-image: url('../../assets/images/vip/type2/vip-2-4-right.png');
      background-size: 530px 170px;
    }
    .type-2-5{
      background-image: url('../../assets/images/vip/type2/vip-2-5-right.png');
      background-size: 530px 170px;
    }
  }
  .vip-title{
    width: 1200px;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #402100;
    justify-content: center;
    margin-bottom: 40px;
  }
  .vip-types{
    margin-top: -50px;
    justify-content: center;
    margin-bottom: 40px;
    box-sizing: border-box;
    width: 900px;
    .window{
      margin: 0 10px;
      width: 196px;
      height: 60px;
      background: linear-gradient(180deg, #FFE0BF 0%, #FFFFFF 100%);
      box-shadow: 0px 3px 10px 3px rgba(255,228,190,0.5);
      border-radius: 10px;
      border: 2px solid #FFFFFF;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #402100;
      margin-top: 11px;
      cursor: pointer;
    }
    .active{
      background: linear-gradient(180deg, #FF8400 0%, #FFCC95 100%);
      box-shadow: 0px 3px 10px 3px rgba(255,220,168,0.5);
      border-radius: 10px;
      border: 2px solid #FFBD75;
      color: #ffffff !important;
    }
  }
}
:deep() .el-overlay::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
:deep() .el-overlay {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
.dialog-top {
  min-width: 600px;
  border-radius: 6px 6px 0px 0px;
  text-align: center;
  width: calc(100% + 50px);
  margin-left: -25px;
  margin-top: -55px;
  background: #e8effd;
  padding: 26px 0;
  font-size: 36px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #101010;
  position: relative;
  .close-btn {
    position: absolute;
    right: 40px;
    top: 33px;
    font-size: 24px;
  }
}

.pay-modal {
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2001;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pay-window {
  width: 400px;
  overflow: hidden;
  border-radius: 8px;
}
.pay-window-title {
  background: #ffe4ce;
  height: 71px;
  width: 100%;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f57b15;
  position: relative;
  img {
    width: 15px;
    height: 15px;
    position: absolute;
    right: 30px;
    cursor: pointer;
  }
}

:deep() .el-dialog__header {
  min-width: 600px !important;
  background: #ffffff;
}
:deep() .el-dialog__body {
  min-width: 600px !important;
  background: #ffffff;
}
:deep() .el-dialog__footer {
  min-width: 650px !important;
  background: #ffffff;
}
.form-container::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.form-container {
  padding: 0 20%;
  // height: calc(90vh - 118px);
  overflow-y: scroll;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  .form-item {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 500;
    margin-bottom: 5px;
    color: #101010;
    .require {
      color: #e3373f;
    }
  }
  .form-margin {
    margin-top: 40px;
  }
  :deep() .el-input__inner {
    background: #f2f2f2 !important;
  }
  :deep() .el-textarea__inner {
    background: #f2f2f2 !important;
  }
  :deep() .el-input__count-inner {
    background: #f2f2f2 !important;
  }
  :deep() .el-textarea {
    .el-input__count {
      background: #f2f2f2 !important;
    }
  }
}
.dialog-footer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  .submit-button {
    background: $--color-theme;
    box-shadow: 0px 4px 20px 0px rgba(39, 89, 221, 0.4);
    border-radius: 26px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    width: 288px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dialog-tip {
    margin-top: 30px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #8a919f;
  }
}
.pos {
  position: relative;
  display: flex;
  align-items: center;
  ::v-deep .el-input__inner {
    width: 50%;
    margin-top: 10px;
  }
  span {
    padding: 5px 30px;
    width: 100px;
    display: flex;
    justify-content: center;
    background: $--color-theme;
    border-radius: 25px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    position: absolute;
    right: 0px;
    cursor: pointer;
  }
  .count-down {
    background: #8991a0;
    width: 100px;
  }
}
.pay-window-content {
  width: 100%;
  background: white;
  padding: 30px;
  box-sizing: border-box;
}
.pay-info-item {
  width: 100%;
  margin-bottom: 25px;
}
.pay-info-item-label {
  margin-bottom: 10px;
  font-size: 20px;
  color: #666666;
  font-weight: 500;
  color: #0f1c34;
}
.pay-info-item-value {
  width: 90%;
  // padding: 0 20px 0 20px;
  color: #3b4352;
  font-size: 18px;
  box-sizing: border-box;
}
.price {
  font-size: 32px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #e3373f;
}
.ready-pay-button-group {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
}
.button {
  width: 150px;
  height: 50px;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: white;
  cursor: pointer;
  img {
    width: 36px;
    width: 30px;
  }
}
.wechat-button {
  background: rgb(70, 174, 55);
}
.alipay-button {
  background: rgb(0, 162, 231);
}
.bottom-tips {
  font-size: 14px;
  line-height: 18px;
  color: #666666;
  margin-top: 22px;
}
.url-font {
  color: #f57b15;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold; // 去下划线加粗
}
.wait-for-payment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.timer {
  color: #666666;
  font-size: 16px;
}
.timer span {
  color: #db0923;
}
.pay-check-button {
  background: rgb(245, 123, 21);
}
.pay-link {
  display: flex;
  cursor: pointer;
}
.alipay-icon {
  margin-right: 10px;
  height: 22px;
  width: 22px;
}
.alipay-icon img {
  height: 100%;
  width: 100%;
}
.qrcode-container {
  height: 75px;
  width: 75px;
  margin: 10px 0;
}
.protocol-name {
  color: #0273c2;
}
</style>