<template>
  <div class="container">
    <img src="../../assets/images/login/logo.png" class="logo" @click="openHomePage"/>
    <div class="login-window" v-if="!bindPhone">
      <p class="title-top">欢迎来到科企岛</p>
      <el-form :rules="rules" :model="loginForm">
        <el-form-item prop="phoneNumber">
          <div class="phone">
            <el-input @input="handlePhoneChange" v-model="loginForm.phoneNumber" placeholder="请输入手机号" class="input" maxlength="11">
            </el-input>
            <img class="icon" src="../../assets/images/login/icon.png" v-if="loginForm.phoneNumber !== ''" @click="loginForm.phoneNumber = ''" />
          </div>
        </el-form-item>
        <el-form-item prop="captcha">
          <div class="code" style="margin-top: 20px;">
            <el-input @input="handleCaptcha" v-model="loginForm.captcha" placeholder="请输入验证码" class="input" maxlength="6" @keyup.enter="login()"></el-input>
            <div class="code-button" :class="{'disabled': !show || !validPhone(loginForm.phoneNumber)}" @click="getCode()">
              <span v-if="(show && !validPhone(loginForm.phoneNumber)) || !sendFlag" style="color: #c0c4cc">获取验证码</span>
              <span v-else-if="show && validPhone(loginForm.phoneNumber) && !repeatFlag && sendFlag">获取验证码</span>
              <span v-else-if="!show" style="color: #c0c4cc">{{ count }}</span>
              <span v-else-if="show && validPhone(loginForm.phoneNumber) && repeatFlag">重新发送</span>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div class="protocols">
        登录即表示已阅读并同意
        <a class="protocol-name" target="_blank" href="/yhxy">《科企岛用户协议》</a>
        、
        <a class="protocol-name" target="_blank" href="/yszc">《科企岛隐私政策》</a>
        及
        <span>
          <a class="protocol-name" target="_blank" href="/nrsm">《科企岛内容声明》</a>。未注册的手机号或第三方帐号验证后将自动创建新帐号
        </span>
      </div>
      <div class="login-button" @click="login()">
        登录/注册
      </div>
      <div class="bottom-line">
        <p class="title">其他登录方式</p>
        <div class="other-ways flex">
          <img src="../../assets/images/login/wechat.png" class="icon" @click="showWxLogin" />
          <img src="../../assets/images/login/qq.png" class="icon" style="margin-left: 20px;" @click="qqLogin"/>
        </div>
      </div>
    </div>
    <div class="login-window" v-else style="height: 448px;padding-bottom: 0;">
      <p class="title-top">绑定手机号</p>
      <el-form :rules="rules" :model="loginForm">
        <el-form-item prop="phoneNumber">
          <div class="phone">
            <el-input @input="handlePhoneChange" v-model="loginForm.phoneNumber" placeholder="请输入手机号" class="input" maxlength="11">
            </el-input>
            <img class="icon" src="../../assets/images/login/icon.png" v-if="loginForm.phoneNumber !== ''" @click="loginForm.phoneNumber = ''" />
          </div>
        </el-form-item>
        <el-form-item prop="captcha">
          <div class="code" style="margin-top: 20px;">
            <el-input @input="handleCaptcha" v-model="loginForm.captcha" placeholder="请输入验证码" class="input" maxlength="6" @keyup.enter="login()"></el-input>
            <div class="code-button" :class="{'disabled': !show || !validPhone(loginForm.phoneNumber)}" @click="getCode()">
              <span v-if="(show && !validPhone(loginForm.phoneNumber)) || !sendFlag" style="color: #c0c4cc">获取验证码</span>
              <span v-else-if="show && validPhone(loginForm.phoneNumber) && !repeatFlag && sendFlag">获取验证码</span>
              <span v-else-if="!show" style="color: #c0c4cc">{{ count }}</span>
              <span v-else-if="show && validPhone(loginForm.phoneNumber) && repeatFlag">重新发送</span>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div class="protocols">
        登录即表示已阅读并同意
        <a class="protocol-name" target="_blank" href="/yhxy">《科企岛用户协议》</a>
        、
        <a class="protocol-name" target="_blank" href="/yszc">《科企岛隐私政策》</a>
        及
        <span>
          <a class="protocol-name" target="_blank" href="/nrsm">《科企岛内容声明》</a>。未注册的手机号或第三方帐号验证后将自动创建新帐号
        </span>
      </div>
      <div class="login-button" @click="login()">
        绑定手机号
      </div>
    </div>
    <div class="footer-line-2">
      <p>
        copyrigt ©️ 2021科企岛(上海)网络技术有限公司 |
        <a class="protocol-name" target="_blank" href="https://www.keqidao.com/sitemap.xml">&nbsp;网站XML地图</a>
        &nbsp;|
        <a class="protocol-name" target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index" rel="nofollow">&nbsp;沪ICP备2021003328号-1 </a>
        &nbsp;|
        沪网公安备案 <a class="protocol-name" target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo" rel="nofollow">&nbsp;3101150200888号&nbsp;</a>
      </p>
    </div>
    <el-dialog v-model="showWxCode" :append-to-body="true">
      <div id="wx_login" style="width: 100%;" @click="refreshCode"></div>
  </el-dialog>
  </div>
</template>

<script>
import { validatePhone } from '@/utils/common/validate'
import { getPhoneCode,weChatLogin,QQLogin,checkPhone } from '@/api/user'
import { ElMessage } from 'element-plus'
import { weChatAppid,qqAppid,requestVersion,kqdPath } from '@/utils/consts';
import { routerWindow } from "@/utils/tool";

export default {
  name: "login",
  data() {
    return {
      showWxCode: false,  // 二维码弹窗
      btnClicked: false,  // 是否点击了按钮
      showCode: true,  // 显示二维码
      codeLoading: false, // 二维码加载
      unfinished: false, // 尚未完成登录
      apiLoading: false, // 加载过程
      isRegistered: '',  // 已经有过注册
      disableCode: false, // 微信验证出错
      accessToken: '',    // QQ accessToken
      expires_in: '',     // QQ expires_in
      otherType: '',      // 第三方登录类型 QQ 微信
      confirmBind: null, // 确认绑定帐号信息
      redirectURI: '',   // 重定位地址
      getUserInfo: {},   // 获取的用户信息
      bindWindow: false,  // 绑定窗口
      loginQQ: false, // QQ登录
      loginQrcode: false, // 显示二维码
      bindPhone: false, // 绑定手机号
      loginForm: {  // 登录表单 电话与验证码
        phoneNumber: "",
        captcha: ""
      },
      rules: { // 表单验证规则
        phoneNumber: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
        ],
        captcha:[
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ]
      },
      sendFlag: true, // 发送验证码标志
      TIME_COUNT: 6, // 验证码倒计时60s
      count: "",  // 计时器显示
      show: true, // 判断是否点击了发送按钮
      redirect: undefined,  // 重定位路径
      otherQuery: undefined,  // 其他参数
      repeatFlag: false // 是否重复点击验证码
    }
  },
  async created(){  // 设定 navbar
    this.$store.commit('message/SET_ACTIVENAV','-')
    let fullPath = this.$route.fullPath
    if(JSON.stringify(this.$route.query.uatTest) === '"false"'){
      window.location.href = 'https://uwww.keqidao.com' + fullPath
      return
    }
    this.redirectURI = encodeURIComponent(kqdPath + fullPath)
    await this.judgeQQ()
    await this.judgeWechat()
  },
  async mounted(){
    this.codeLoading = true
    /* eslint-disable-next-line */
    // await new WxLogin({
    //   self_redirect: false,
    //   id: "wx_login",
    //   appid: weChatAppid,
    //   scope: "snsapi_login",
    //   redirect_uri: this.redirectURI,
    //   style: "black"
    // });
    this.codeLoading = false
  },
  watch: {
    $route: { // 根据路径内容，获取所有参数
      handler: function (route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
          if(this.redirect === '/industry-setup'){  // 返回首页  企业入驻页面也返回 设定行业页面
            this.redirect = '/'
            this.otherQuery = undefined
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    showWxLogin(){
      // 初始化微信二维码
      this.showWxCode = true
      this.$nextTick(async()=>{
        this.codeLoading = true
        /* eslint-disable-next-line */
        await new WxLogin({
          self_redirect: false,
          id: "wx_login",
          appid: weChatAppid,
          scope: "snsapi_login",
          redirect_uri: this.redirectURI,
          style: "black"
        });
        this.codeLoading = false
      })
    },
    openHomePage(){
      // 打开首页
      routerWindow(this.$router,'/',{})
    },
    async refreshCode(){ // 刷新二维码
      this.codeLoading = true
      /* eslint-disable-next-line */
      await new WxLogin({
        self_redirect: false,
        id: "wx_login",
        appid: weChatAppid,
        scope: "snsapi_login",
        redirect_uri: this.redirectURI,
        style: "black"
      });
      this.codeLoading = false
    },
    async oAuthLogin(params){ // 登录后缓存
      this.$store.dispatch("user/otherLogin", params).then(() => {
        let userInfo = localStorage.getItem('userInfos')
        userInfo = JSON.parse(userInfo).memberInfo
        let { organizationType, role } = userInfo
        // 没有身份的跳去选择角色，助手除外
        if (organizationType === null) {
          if (role === 4) {
            return
          } else {
            this.$router.push({ path: '/' })
          }
        } else {
          this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
          this.$store.dispatch("user/updateVipInfo")
          // setTimeout(() => {  // 延迟刷新 防止回到首页
          //   location.reload()
          // }, 1000);
        }
      }).catch((error)=>{
        console.log('loginErrorL',error);
      })
    },
    erroPage(){ // 出现绑定错误等
      let that = this
      this.disableCode = true
      if(this.$route.query.redirect){
        this.$router.push('/tempLogin')  // 改变路由
        setTimeout(()=>{
          that.$router.push({path:'/login',query: {'redirect': this.$route.redirect}})
        },500)
      }
      else{
        this.$router.push('/tempLogin')
        setTimeout(()=>{
          that.$router.push('/login')
        },500)
      }
    },
    async judgeQQ(){  // QQ判断
      const path = this.$route.fullPath
      const tokenStart = path.indexOf('access_token')
      const tokenEnd = path.indexOf('expires_in')
      if(tokenStart !== -1 && tokenEnd !== -1){ // QQ登录 截取token
        this.codeLoading = true
        this.apiLoading = true
        this.otherType = 'QQ'
        const token = path.slice(path.indexOf('#') + 14, tokenEnd - 1)
        const expires_in = path.slice(tokenEnd + 11)
        this.accessToken = token
        this.expires_in = expires_in
        let res = {}
        try {
          res = await QQLogin(token,expires_in,9)
          console.log(res);
        } catch (error) {
          this.apiLoading = false
          this.codeLoading = false
          if(error.message === 'bindPhone'){
            this.bindPhone = true // 未绑定手机号
            this.showCode = false
            return
          }
        }
        this.codeLoading = false
        this.apiLoading = false
        if(res.code === 'Sx200'){
          await this.oAuthLogin(res)
        }
      }
    },
    async judgeWechat(){      // 微信判断
      if(this.$route.query.code && this.$route.query.state){
        this.apiLoading = true
        this.codeLoading = true
        this.otherType = '微信'
        let res = {}
        try {
          res = await weChatLogin(this.$route.query.code,1)
          console.log(res);
        } catch (error) {
          console.log(error);
          this.apiLoading = false
          this.codeLoading = false
          if(error.message === 'bindPhone'){
            this.bindPhone = true // 未绑定手机号
            this.showCode = false
            return
          }
          else if(error.message === 'codeError'){ // code失效
            this.erroPage()
          }
        }
        this.codeLoading = false
        this.apiLoading = false
        if(res.code === 'Sx200'){
          await this.oAuthLogin(res)
        }
      }
      else return
    },
    wechatLogin(){  // 微信登录
      this.loginQrcode = true
      this.$nextTick(()=>{
        /* eslint-disable-next-line */
        new WxLogin({
          self_redirect: false,
          id: "wx_login",
          appid: weChatAppid,
          scope: "snsapi_login",
          redirect_uri: this.redirectURI,
          style: "black"
        });
      })

    },
    qqLogin(){  // qq登录
      window.location.href = `https://graph.qq.com/oauth2.0/show?which=Login&display=pc&client_id=${qqAppid}&response_type=token&scope=all&redirect_uri=${this.redirectURI}`
    },
    cancelLogin(){  // 取消登录按钮
      if(this.bindPhone){ // 绑定手机状态
        this.unfinished = true
        return
      }
      this.$router.push('/')  // 回到首页，防止重复返回登录页面
    },
    handlePhoneChange(e) {  // 手机号处理，只填入数字
      if (this.show && this.repeatFlag) {
        this.repeatFlag = false
      }
      let pattern = new RegExp("^[0-9]|-$")
      this.loginForm.phoneNumber = Array.from(e).filter(v => pattern.test(v)).join("")
    },
    handleCaptcha(e) {  // 验证码处理，只输入数字
      let pattern = new RegExp("^[0-9]*$")
      this.loginForm.captcha = Array.from(e).filter(v => pattern.test(v)).join("")
    },
    clearPhoneNumber() {  // 清空手机号
      this.loginForm.phoneNumber = ""
    },
    clearCaptcha() {  // 清空验证码
      this.loginForm.captcha = ""
    },
    validPhone(phone) { // 判断手机号是否合法
      if (!validatePhone(phone)) {
        return false;
      } else if (phone === "") {
        return false;
      } else {
        return true;
      }
    },
    getCode() { // 验证码倒计时60s
      if(this.disableCode){ // code无效
        return
      }
      if (this.sendFlag) {
        const params = {
          phone: this.loginForm.phoneNumber
        }
        getPhoneCode(params).then((res) => {
          if (res.code === 'Sx200') {
            ElMessage.success('验证码已发送!')
            this.repeatFlag = true;
            if (!this.timer) {
              this.count = this.TIME_COUNT;
              this.show = false;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= this.TIME_COUNT) {
                  this.count--;
                } else {
                  this.show = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          }
        });
      }
    },
    confirm(type){  // 是否需要绑定
      if(type === 0) {
        this.confirmBind = false
      }
      else if(type === 2){
        this.erroPage()
        return
      }
      else if(type === 3){
        this.unfinished = false
        return
      }
      else {
        this.confirmBind = true
      }
      this.bindWindow = false
      this.login()
    },
    async login() { // 登录按钮
      if(this.disableCode){ // code无效
        return
      }
      this.btnClicked = true
      if(this.loginForm.phoneNumber.length === 0){
        ElMessage.warning('请输入手机号！')
        return
      }
      if(this.loginForm.captcha.length === 0){
        ElMessage.warning('请输入验证码！')
        return
      }
      if(this.confirmBind == null && this.otherType.length > 0){ // 判断是否需要绑定
        let res = await checkPhone(this.loginForm.phoneNumber)  // 判断手机是否绑定
        if(res.data === true) { // 已注册，显示绑定
          this.isRegistered = '此手机号码已注册，'
          this.bindWindow = true
          return
        }
        if(res.data === false){ // 未注册，直接替换
          this.confirmBind = true
        }
      }
      if(this.bindPhone){ // 绑定电话状态
        let res = {}
        this.btnClicked = false
        if(this.otherType === '微信'){
          try {
            res = await weChatLogin(this.$route.query.code,6,this.loginForm.phoneNumber,this.loginForm.captcha,this.confirmBind)
          } catch (error) {
            console.log(error);
            if(error.message === 'codeError'){ // code失效
              this.erroPage()
              return
            }
          }
          if(res.code === 'Sx200'){
            await this.oAuthLogin(res)
          }
        }
        else if(this.otherType === 'QQ'){
          try {
            res = await QQLogin(this.accessToken,this.expires_in,10,this.loginForm.phoneNumber,this.loginForm.captcha,this.confirmBind)
          } catch (error) {
            console.log(error);
            if(error.message === 'codeError'){ // code失效
              this.erroPage()
              return
            }
          }
          if(res.code === 'Sx200'){
            await this.oAuthLogin(res)
          }
        }
        return
      }
      const params = {
        machineCode: "",
        version: requestVersion,
        canal: 1,
        type: 0,
        code: this.loginForm.captcha,
        phone: this.loginForm.phoneNumber,
      }
      this.$store.dispatch("user/login", params).then(() => {
        let userInfo = localStorage.getItem('userInfos')
        userInfo = JSON.parse(userInfo).memberInfo
        let { organizationType, role,memberIndustry } = userInfo
        // 没有身份的跳去选择角色，助手除外 或者未选择行业前不打开窗口
        let noIndustry = false
        if(!(memberIndustry != null && memberIndustry.length != 0)) noIndustry = true
        if(userInfo.organizationId) noIndustry = false
        if (organizationType === null) {
          if (role === 4) {
            return
          } else if(noIndustry){
            this.$router.push({path: `/industry-setup`, query: {...this.$route.query}})
          } else {
            this.$router.push({ path: '/'})
          }
        } else {
          this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
          this.$store.dispatch("user/updateVipInfo")
          // setTimeout(() => {  // 延迟刷新 防止回到首页
          //   location.reload()
          // }, 1000);
        }
      })
    },
    getOtherQuery(query) {  // 获取路径内所有参数
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  min-width: 1903px;
  width: 100%;
  height: 1073px;
  background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/04/25/1684993728811665');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left top;
  position: relative;
  .logo{
    position: absolute;
    left: 19%;
    top: 8%;
    width: 134px;
    height: 42px;
    cursor: pointer;
  }
  .login-window{
    width: 476px;
    height: 531px;
    background: #FFFFFF;
    box-shadow: 0px 4px 18px 0px rgba(211,228,245,0.5);
    border-radius: 10px;
    position: absolute;
    right: 19%;
    top: 26%;
    padding: 44px 50px 30px;
    box-sizing: border-box;
    .title-top{
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #111111;
      line-height: 33px;
      margin-bottom: 38px;
    }
    :deep(.el-form-item){
      margin-bottom: 0 !important;
    }
    .phone{
      width: 100%;
      position: relative;
      .icon{
        width: 14px;
        height: 14px;
        position: absolute;
        right: 15px;
        top: 20px;
        margin-top: -7px;
        cursor: pointer;
      }
      .input{
        font-size: 14px;
        :deep(.el-input__inner){
          border: 1px solid #DDE2E9 !important;
          height: 40px !important;
          line-height: 40px !important;
        }
      }
    }
    .code{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .input{
        font-size: 14px;
        width: 269px !important;
        :deep(.el-input__inner){
          border: 1px solid #DDE2E9 !important;
          height: 40px !important;
          line-height: 40px !important;
          width: 269px !important;
        }
      }
      .code-button{
        width: 96px;
        height: 40px;
        background: #F4F7FA;
        border-radius: 4px;
        border: 1px solid #DDE2E9;
        box-sizing: border-box;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  .protocols {
    box-sizing: border-box;
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    line-height: 24px;
    font-size: 12px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #666666;
    text-align: left;
    word-break: break-all;
    a{
      color: #167CFF;
      cursor: pointer;
      &:visited{
        color: #167CFF !important;
      }
    }
  }
  .login-button{
    width: 100%;
    height: 40px;
    background: $--color-theme;
    border-radius: 4px;
    margin-top: 37px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 40px;
    cursor: pointer;
    text-align: center;
    margin-bottom: 74px;
  }
  .bottom-line{
    width: 100%;
    .title{
      width: 100%;
      text-align: center;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 12px;
      margin-bottom: 17px;
      position: relative;
      height: 12px;
      &::before{
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -0.5px;
        width: 137px;
        height: 1px;
        background: #EDEDED;
      }
      &::after{
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -0.5px;
        width: 137px;
        height: 1px;
        background: #EDEDED;
      }
    }
    .other-ways{
      width: 100%;
      justify-content: center;
      .icon{
        width: 28px;
        height: 28px;
        cursor: pointer;
      }
    }
  }
  .footer-line-2{
    width: 100%;
    padding: 20px 0;
    text-align: center;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    position: absolute;
    bottom: 1.7%;
    a{
      color: #999999;
    }
    a:hover{
      color: $--color-theme !important;
    }
    a:visited{
      color: #999999;
    }
  }
}
</style>