<template>
    <div class="search-list-container">
        <!-- 搜索栏 -->
        <div class="search-bar">
            <div class="input">
                <el-input placeholder="请输入关键词搜索" v-model="keywords" @keydown.enter="newSearch()"></el-input>
                <div class="search-button" @click="newSearch()"><i class="el-icon-search"></i></div>
            </div>
        </div>
        <!-- 筛选项目 -->
        <div class="filter-bar">
            <el-select v-model="selections[0].val" placeholder="选择省份"  clearable :style="`width: 168px;`">
                <el-option
                    v-for="item in selections[0].provinceArr"
                    :key="item.id"
                    :label="item.provinceName"
                    :value="item.provinceName">
                </el-option>
            </el-select>
            <el-select v-model="selections[1].val" :disabled="!selections[0].val" placeholder="选择城市"  clearable :style="`width: 168px;`">
                <el-option
                    v-for="item in selections[1].cityArr"
                    :key="item.id"
                    :label="item.cityName"
                    :value="item.cityName">
                </el-option>
            </el-select>
            <el-select v-model="selections[2].val" :disabled="!selections[1].val" placeholder="选择区域"  clearable :style="`width: 168px;`">
                <el-option
                    v-for="item in selections[2].areaArr"
                    :key="item.id"
                    :label="item.areaName"
                    :value="item.areaName">
                </el-option>
            </el-select>
        </div>
        <companyList :list="list" :type="type" @changeType="changeType" :loading="loading" :total="total"></companyList>
        <!-- 分页 -->
        <div class="page-container" v-if="type !== 1 && total > 0">
            <div class="pagination">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="total"
                    v-model:current-page="offset"
                    :page-size="10"
                    @current-change="pageChange"
                    >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import companyList from "@/components/companyList/companyList.vue"
import { ElMessage } from 'element-plus'
import { searchCompany } from "@/api/search"
import { getprovince, getcity, getarea } from "@/api/demand";

export default {
    components:{
        companyList,    // 公司列表
    },
    mounted(){
        this.initialPage = true
        this.getSelection()
        this.getQuery()
        this.getList()
    },
    watch:{
        $route(){
            this.getQuery()
            if(this.type === 1) return  // 排除搜园区
            this.getList()
        },
        keywords(val){
            if(!val || val.length === 0){   // 输入空监听
                let that = this
                setTimeout(()=>{    // 延迟判断防止重复路由
                    if(that.$route.path !== '/search-company') return
                    that.$router.push({
                        path:'/search-company',
                        query:{
                            keywords: '',
                            page: 1,
                            type: that.type,
                            province: this.provinceName || '',
                            city: this.cityName || '',
                            area: this.areaName || '',
                        }
                    })
                },100)
            }
        },
        provinceName(val){
            this.selections[1] = {
                cityArr: [],
                val: '',
            }
            this.selections[2] = {
                areaArr: [],
                val: '',
            }
            if(val){
                const province = this.selections[0].provinceArr.find((item)=>item.provinceName === val)
                if(province){
                    getcity(province.provinceCode).then(res=>{
                        this.selections[1].cityArr = res.data
                        if(this.$route.query.city && this.initialPage){
                            this.selections[1].val = this.$route.query.city
                        }
                        else if(this.initialPage) {
                            this.initialPage = false
                            this.getList()
                        }
                    })
                }
            }
            if(this.$route.query.city && this.initialPage){
                return
            }
            this.filterChange()
        },
        cityName(val){
            this.selections[2] = {
                areaArr: [],
                val: '',
            }
            if(val){
                const city = this.selections[1].cityArr.find((item)=>item.cityName === val)
                if(city){
                    getarea(city.cityCode).then(res=>{
                        this.selections[2].areaArr = res.data
                        if(this.$route.query.area && this.initialPage){
                            this.selections[2].val = this.$route.query.area
                        }
                        else if(this.initialPage) {
                            this.initialPage = false
                            this.getList()
                        }
                    })
                }
            }
            if(this.$route.query.area && this.initialPage){
                this.selections[2].val = this.$route.query.area
            }
            this.filterChange()
        },
        areaName(){
            if(this.initialPage) {
                this.initialPage = false
                this.getList()
            }
            this.filterChange()
        }
    },
    computed:{
        provinceName(){
            return this.selections[0].val
        },
        cityName(){
            return this.selections[1].val
        },
        areaName(){
            return this.selections[2].val
        }
    },
    data(){
        return{
            selections:[
                {
                    provinceArr: [],
                    val: '',
                },
                {
                    cityArr: [],
                    val: '',
                },
                {
                    areaArr: [],
                    val: '',
                }
            ],
            initialPage: false, //初始化
            type: 0 , // 默认搜企业
            keywords: '',    // 搜索关键词
            loading: false, // 加载
            list: [],    // 列表
            offset: 1,  // 分页
            total: 0,   // 总数
            limit: 10   // 限制个数
        }
    },
    methods:{
        getSelection(){
            getprovince().then(res=>{
                this.selections[0].provinceArr = res.data
                this.selections[0].val = this.$route.query.province || ''
                if(this.selections[0].val === '') {
                    this.initialPage = false
                    this.getList()
                }
            })
        },
        changeType(val){   // 修改搜索类型
            this.$router.push({
                path:'/search-company',
                query:{
                    keywords: this.keywords,
                    page: 1,
                    type: val,
                    province: this.provinceName || '',
                    city: this.cityName || '',
                    area: this.areaName || '',
                }
            })
        },
        pageChange(val){    // 翻页
            if(!localStorage.getItem('userInfos') && val > 3){
                ElMessage.warning('未登录只能查看3页信息，登录以查看更多企业信息!')
                this.offset = 3
                this.$router.push({
                    path:'/search-company',
                    query:{
                        keywords: this.keywords,
                        page: 3,
                        type: this.type,
                        province: this.provinceName || '',
                        city: this.cityName || '',
                        area: this.areaName || '',
                    }
                })
                return
            }
            this.offset = val
            this.$router.push({
                path:'/search-company',
                query:{
                    keywords: this.keywords,
                    page: this.offset,
                    type: this.type,
                    province: this.provinceName || '',
                    city: this.cityName || '',
                    area: this.areaName || '',
                }
            })
        },
        filterChange(){
            this.$router.push({
                path:'/search-company',
                query:{
                    keywords: this.keywords,
                    page: 1,
                    type: this.type,
                    province: this.provinceName || '',
                    city: this.cityName || '',
                    area: this.areaName || '',
                }
            })
        },
        newSearch(){    // 根据keywords跳转
            if(this.keywords.length === 0){ // 空判断
                ElMessage.warning('请输入搜索关键词!')
                return
            }
            if(this.keywords.trim().length === 0){  // 空格过滤
                this.keywords = ''
                return
            }
            if(this.keywords === this.$route.query.keywords){
                ElMessage.warning('已经搜索当前关键词!')
                return
            }
            this.$router.push({
                path:'/search-company',
                query:{
                    keywords: this.keywords,
                    page: 1,
                    type: this.type,
                    province: this.provinceName || '',
                    city: this.cityName || '',
                    area: this.areaName || '',
                }
            })
        },
        getQuery(){ // 获取路由参数
            this.keywords = !this.$route.query.keywords || this.$route.query.keywords.length === 0 ? '' : this.$route.query.keywords
            this.offset = this.$route.query.page ? Number(this.$route.query.page) : 1
            this.type = this.$route.query.type ? Number(this.$route.query.type) : 0
        },
        async getList(){ // 获取列表
            if(this.initialPage) return
            this.loading = true
            let res = undefined
            let userInfo = localStorage.getItem('userInfo')
            userInfo = JSON.parse(userInfo)
            let city = this.cityName === this.provinceName ? '' : this.cityName
            let param = {
                "address": this.areaName || '',
                "city": city,
                "esShow": true,
                "keyword": this.keywords || '',
                "limit": this.limit,
                "memberId": userInfo?.id || '-1',
                "offset": this.offset,
                "orderByCreateTime": true,
                "province": this.provinceName || '',
                "type": 10,
            }
            try {
                switch (this.type) {
                    case 0:
                        res = await searchCompany(param) // 有关键词搜索
                        break;
                    case 2:
                        param.type = 11     // 找资本
                        res = await searchCompany(param) // 有关键词搜索
                        break
                    default:
                        break;
                }
            } catch (error) {
                console.error('search company error:', error);
                this.list = []
                this.total = 0
                this.loading = false
                return
            }
            this.list = res.data.result
            this.total = res.data.total
            this.loading = false
        },
    }
}
</script>

<style lang="scss" scoped>
.search-list-container{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.search-bar{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    z-index: 2000;
    .input{
        display: flex;
        align-items: center;
        border-radius: 4px;
        border: 1px solid $--color-theme;
        margin-left: 30px;
        :deep() .el-input{
            width: 424px;
            font-size: 16px;
        }
        :deep() .el-input__inner{
            border: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            height: 50px;
            padding-right: 0;
        }
        .search-button{
            width: 80px;
            height: 50px;
            background: $--color-theme;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 16px;
            color: #ffffff;
        }
    }
}
.filter-bar{
    width: 1200px;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: url('../../assets/images/search/filter-back-new.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 20px;
    height: 70px;
    padding: 0px 210px;
    box-sizing: border-box;
}
.page-container{
    display: flex;
    justify-content: center;
    width: 100%;
}
.pagination{
    border: 1px solid #D8D8D8;
    margin-top: -2px;
    width: 1200px;
    display: flex;
    justify-content: center;
    background: #ffffff;
    padding: 50px 0;
    margin-bottom: 20px;
    :deep() .btn-next{
        background: #ffffff;
    }
    :deep() .btn-prev{
        background: #ffffff;
    }
    :deep() .number{
        background: #ffffff !important;
    }
    :deep() .active{
        background: $--color-theme !important;
    }
}
</style>