<template>
    <!-- 结果数据展示 && !loading -->
    <div v-if="total > 0" class="total-num fc-999 fs-18">
        已为您匹配<span class="color">{{total}}</span>个相关{{typeTitle}}
    </div>
    <div class="company-container" :class="{'margin-bottom-80': list.length === 0}">
        <div class="company-lists">
            <!-- 分栏选择 -->
            <!-- <div class="search-tabs">
                <div class="tab" :class="{'active': type === 0}" @click="changeType(0)">企业</div>
                <div class="tab" :class="{'active': type === 1}" @click="changeType(1)">园区</div>
                <div class="tab" :class="{'active': type === 2}" @click="changeType(2)">资本</div>
            </div> -->
            <!-- 详情内容 -->
            <div v-if="type === 0" v-loading="loading" class="loading-company" :class="{'no-detail': noDetail}">
                <!-- 结果展示 -->
                <div class="company-card" v-for="(item,index) in list" :key="item.companyId">
                    <div class="left">
                        <div class="infos">
                            <div class="top">
                                <div class="name" v-html="item.name" @click="jumpToDetail(item)">
                                </div>
                            </div>
                            <div class="middle">
                                <div class="info">
                                    <div class="item" v-if="item.legalRepresentative">
                                        法定代表人：<span v-html="item.legalRepresentative"></span>
                                    </div>
                                    <div class="item" v-if="item.registerCapital && item.registerCapital !== '-'">
                                        注册资本：{{item.registerCapital}}
                                    </div>
                                    <div class="item" v-if="item.establishDate && item.establishDate.length > 1">
                                        成立日期：{{item.establishDate}}
                                    </div>
                                    <div class="item" v-if="item.organizationCode">
                                        企业代码：{{item.organizationCode}}
                                    </div>
                                    <div class="item" v-if="companyPhone(item)">
                                        电&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;话：{{companyPhone(item)}}
                                    </div>
                                    <div class="item" v-if="item.industry">
                                        行&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;业：<span v-html="item.industry"></span>
                                    </div>
                                    <div class="item" v-if="companyEmail(item)">
                                        邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱：{{companyEmail(item)}}
                                    </div>
                                </div>
                                <button class="send" @click="openSendEmail(item)">发邮件</button>
                            </div>
                            <div class="address" v-if="item.address">
                                地&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;址：{{item.address}}
                            </div>
                            <div class="introduce" v-if="item.introduce" :id="'introduce' + index">
                                简&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;介：<span v-html="item.introduce"></span>
                            </div>
                        </div>
                        <div v-if="showAllBtn[index]" class="show-all">
                            <p @click="showALLIntroduce(item)" style="line-height: 20px;">更多</p>
                        </div>
                    </div>
                </div>
                <!-- 空占位 -->
                <el-empty v-if="list?.length === 0" description=" " style="width:1200px;position:relative;">
                    <div class="empty-company-words">
                        <p>暂无相关企业</p>
                    </div>
                </el-empty>
                <!-- 研究方向全部 -->
                <el-dialog v-model="showAllWindow" :append-to-body="true">
                    <div style="max-height: 500px;overflow:auto;width: 100%;">{{introduce}}</div>
                </el-dialog>
            </div>
            <div v-if="type === 1" class="empty">
                <el-empty description=" " style="width:1200px;position:relative;">
                    <div class="empty-words">
                        <p>园区信息暂未开放，如有园区对接需求，请点击下方按钮发布需求信息，</p>
                        <p>我们会把您的需求信息推荐给相匹配的园区</p>
                    </div>
                </el-empty>
                <button class="publish-button" @click="jumpToPost('/postdemand',environment === 'uat' ? 234488340742145 : 172021064269825)">发布政策服务需求</button>
            </div>
            <div v-if="type === 2" v-loading="loading" class="empty" :class="{'no-detail': noDetail}">
                <!-- <div v-if="total > 0 && !loading" class="total-num fc-999">
                    已为您匹配{{total}}个相关{{typeTitle}}
                </div> -->
                <div class="company-card" v-for="(item,index) in list" :key="item.companyId">
                    <div class="left">
                        <div class="infos">
                            <div class="top">
                                <div class="name" v-html="item.fullName" @click="jumpToDetail(item)">
                                </div>
                            </div>
                            <div class="middle">
                                <div class="info">
                                    <div class="item" v-if="item.institutionalHeadquarters">
                                        机构总部：<span v-html="item.institutionalHeadquarters"></span>
                                    </div>
                                    <div class="item" v-if="item.natureOfOrganization">
                                        机构性质：<span v-html="item.natureOfOrganization"></span>
                                    </div>
                                    <div class="item" v-if="item.establishDate">
                                        创立时间：{{item.establishDate}}
                                    </div>
                                    <div class="item" v-if="item.phone">
                                        电话号码：{{item.phone}}
                                    </div>
                                    <div class="item" v-if="item.investmentStage">
                                        投资阶段：<span v-html="item.investmentStage"></span>
                                    </div>
                                    <div class="item" v-if="item.fax">
                                        传真号码：<span v-html="item.fax"></span>
                                    </div>
                                    <div class="item" v-if="item.address">
                                        地&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;址：<span v-html="item.address"></span>
                                    </div>
                                </div>
                                <!-- <button class="send" @click="openSendEmail(item)">发邮件</button> -->
                            </div>
                            <div class="introduce" v-if="item.introduce" :id="'introduce' + index">
                                简&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;介：<span v-html="item.introduce"></span>
                            </div>
                        </div>
                        <div v-if="showAllBtn[index]" class="show-all">
                            <p @click="showALLIntroduce(item)" style="line-height: 20px;">更多</p>
                        </div>
                    </div>
                </div>
                <!-- 简介全部 -->
                <el-dialog v-model="showAllWindow" :append-to-body="true">
                    <div style="max-height: 100px;overflow:auto;width: 100%;">{{introduce}}</div>
                </el-dialog>
                <el-empty description=" " style="width:1200px;position:relative;" v-if="list.length === 0">
                    <div class="empty-words-capital">
                        <p>暂无相关内容</p>
                    </div>
                </el-empty>
                <!-- <button class="publish-button" v-if="list.length === 0" @click="jumpToPost('/postdemand',environment === 'uat' ? 170542772584449 : 172020017790977)">发布资本投资需求</button> -->
                <div style="height: 94px;" v-if="list.length === 0"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { environment } from "@/utils/consts";
import { routerWindow } from "@/utils/tool";
import { ElMessage } from "element-plus";

export default {
    emits: ['changeType'],
    props: ['list','type','loading','total','noDetail'],
    data(){
        return{
            environment,    // 环境
            showAllBtn: [], // 是否展示更多按钮
            showAllWindow: false,   // 展示更多弹窗
            introduce: ''   , // 更多简介
        }
    },
    watch:{
        showAllWindow(val){ // 弹窗关闭清空简介
            if(!val){
                this.introduce = ''
            }
        },
        loading(val){   // 监听加载获取按钮
            if(!val){
                this.setAllBtn()
            }
        },
    },
    computed:{
        typeTitle(){
            switch (this.type) {
                case 0:
                    return '企业';
                case 1:
                    return '园区';
                case 2:
                    return '资本'
                default:
                    return '企业';
            }
        },
    },
    mounted(){
        this.setAllBtn()
    },
    methods:{
        jumpToDetail(company){
            // 去详情页面
            if(this.noDetail) return
            if(!localStorage.getItem('userInfos')){
                ElMessage.warning('登陆后VIP才能查看企业详情，请先登录!')
                return
            }
            else if(!JSON.parse(localStorage.getItem('userInfos')).memberInfo.isVip){
                // 非VIP
                this.$store.commit('message/SET_IM_WINDOW_DIALOG', true)
                return
            }
            let type = 0
            type = this.type
            routerWindow(this.$router,'/company-detail',{
                type: type,
                id: company.companyId,   // 传入公司ID
            })
        },
        showALLIntroduce(item){ // 展示跟多简介
            this.introduce = item.introduce
            this.showAllWindow = true
        },
        setAllBtn(){    // 设置更多按钮
            if(this.type === 1) return
            this.showAllBtn = []
            if(this.list.length === 0) return
            this.showAllBtn = this.list.map(()=>{
                return false
            })
            this.$nextTick(()=>{
                this.showAllBtn = this.showAllBtn.map((item,index)=>{
                    let detailDom = document.getElementById('introduce' + index)
                    item = detailDom.clientHeight < detailDom.scrollHeight;
                    return item
                })
            })
        },
        companyPhone(item){ // 电话判断
            let tycPhone = item.tycPhone + ''
            if(tycPhone === '暂无信息' || !item.tycPhone || !tycPhone || tycPhone == 'null'){   // 先判断tycPhone
                let contactPhone = item.contactPhone + ''
                if(!contactPhone || !item.contactPhone || contactPhone.length === 0 || contactPhone + ''.indexOf('无') !== -1 || contactPhone == 'null') return false
                return contactPhone
            }
            return tycPhone.indexOf('无') === -1 ? tycPhone : false
        },
        companyEmail(item){ // Email修改
            let email = item.email
            if(email === '暂无信息' || !email){   // 先判断email
                let aqcEmail = item.aqcEmail
                if(!aqcEmail || aqcEmail.length === 0 || aqcEmail.length === 1) return false
                return aqcEmail
            }
            const reg = new RegExp("'",'g');//g,表示全部替换。
            if(email.indexOf("'") !== -1 || email.indexOf('"') !== -1) email = JSON.parse(email.replace(reg,'"'))[0]
            return email
        },
        jumpToPost(path,id){   // 页面跳转
            routerWindow(this.$router,path,{typeId:id})
        },
        openSendEmail(item){    // 发送邮件
            if(!localStorage.getItem('userInfos')){
                ElMessage.warning('登陆后才能发送邮件，请先登录!')
                return
            }
            this.$store.dispatch("popup/setPopup", {
                type: 5,
                parameter: item,
            });
        },
        // changeType(type){   // 切换分栏
        //     this.$emit('changeType',type)
        // }
    }
}
</script>

<style lang="scss" scoped>
.margin-bottom-80{
    margin-bottom: 80px !important;
}
.total-num{
    width: 1200px;
    margin: 0 auto;
    padding-top: 50px;
    text-align: left;
    .color{
        color: #FF4443;
    }
}
.company-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
    margin-top: 15px;
    margin-bottom: 0px;
    .company-lists{
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #FFFFFF;
        border: 1px solid #D8D8D8;
        border-radius: 4px;
        .company-card{
            width: 1200px;
            box-sizing: border-box;
            padding: 50px 50px;
            border-bottom: #D8D8D8 1px solid;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .loading-company{
            min-height: 400px;
            width: 1200px;
            position: relative;
            :deep() .el-empty{
                padding: 50px 0 0 0 !important;
            }
            .empty-company-words{
                position: absolute;
                width: 100%;
                top: 284px;
                left: 0;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 25px;
            }
        }
        .search-tabs{
            display: flex;
            align-items: center;
            width: 1200px;
            height: 133px;
            border-bottom: #D8D8D8 1px solid;
            .tab{
                width: 33.33%;
                font-size: 24px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 133px;
                text-align: center;
                cursor: pointer;
                position: relative;
            }
            .active{
                color: $--color-theme !important;
            }
            .active::after{
                position: absolute;
                content: "";
                width: 44px;
                height: 4px;
                background: $--color-theme;
                border-radius: 2px;
                bottom: 0;
                left: 50%;
                margin-left: -22px;
            }
        }
    }
}
.left{
    display: flex;
    align-items: center;
    position: relative;
    width: 1100px;
    .avatar{
        width: 100px;
        height: 100px;
        border-radius: 4px;
        margin-right: 50px;
    }
    .infos{
        display: flex;
        flex-direction: column;
    }
}
.send{
    width: 96px;
    height: 34px;
    background: $--color-theme;
    box-shadow: 0px 2px 4px 1px rgba(2,58,95,0.1500);
    border-radius: 17px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 34px;
    cursor: pointer;
    border: none;
}
.no-detail{
    .name{
        cursor: text !important;
        &:hover{
            color: #333333 !important;
        }
    }
}
.top{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .name{
        cursor: pointer;
        max-width: 50%;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 25px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:hover{
            color: $--color-theme;
        }
    }
}
.show-all{
    position: absolute;
    cursor: pointer;
    bottom: 10px;
    right: 0;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: $--color-theme;
    height: 20px;
    line-height: 20px;
}
.address{
    width: 1070px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; //多行在这里修改数字即可
    overflow:hidden;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    margin-bottom: 10px;
    word-break: break-all;
}
.introduce{
    width: 1070px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; //多行在这里修改数字即可
    overflow:hidden;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    margin-bottom: 10px;
    word-break: break-all;
}
.middle{
    width: 1100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .info{
        width: 1000px;
        display: flex;
        flex-wrap: wrap;
    }
    .item{
        width: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        margin-bottom: 10px;
    }
}
.empty{
    display: flex;
    flex-direction: column;
    align-items: center;
    :deep() .el-empty{
        padding: 50px 0 0 0 !important;
    }
    .publish-button{
        width: 220px;
        height: 44px;
        background: $--color-theme;
        box-shadow: 0px 2px 4px 1px rgba(2,58,95,0.1500);
        border-radius: 4px;
        border: none;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 44px;
        text-align: center;
        margin-top: 150px;
        margin-bottom: 143px;
        cursor: pointer;
    }
    .empty-words{
        position: absolute;
        left: 50%;
        margin-left: -288px;
        top: 284px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 25px;
    }
    .empty-words-capital{
        position: absolute;
        left: 50%;
        top: 284px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        margin-left: -100px;
        font-weight: 400;
        color: #999999;
        line-height: 25px;
        width: 200px;
        text-align: center;
    }
}
</style>