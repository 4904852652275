<template>
  <div class="search-list-container">
      <!-- 搜索栏 -->
      <div class="search-bar">
          <div class="input">
              <el-input placeholder="请输入关键词搜索" v-model="keywords" @keydown.enter="newSearch()"></el-input>
              <div class="search-button" @click="newSearch()"><i class="el-icon-search"></i></div>
          </div>
      </div>
      <!-- @changeType="changeType" -->
      <companyList :list="list" :type="type" :loading="loading" :total="total" :noDetail="true"></companyList>
      <!-- 分页 -->
      <div class="page-container" v-if="type !== 1 && total > 0">
          <div class="pagination">
              <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="total"
                  v-model:current-page="offset"
                  :page-size="10"
                  @current-change="pageChange"
                  >
              </el-pagination>
          </div>
      </div>
  </div>
</template>

<script>
import companyList from "@/components/companyList/companyList.vue"
import { ElMessage } from 'element-plus'
import { search } from "@/api/search"

export default {
  components:{
      companyList,    // 公司列表
  },
  mounted(){
      this.$store.dispatch('user/setNavbarBread',[
          {
              path: '/',
              name: '首页',
          },
          {
              path: '',
              name: '搜企业',
          }
      ])
      this.getQuery()
      this.getList()
  },
  watch:{
    total(val){
      console.log(val,'hehe222');
    },
      $route(){
          this.getQuery()
          if(this.type === 1) return  // 排除搜园区
          this.getList()
      },
      keywords(val){
          if(!val || val.length === 0){   // 输入空监听
              let that = this
              setTimeout(()=>{    // 延迟判断防止重复路由
                  if(that.$route.path !== '/search-capital') return
                  that.$router.push({
                      path:'/search-capital',
                      query:{
                          keywords: '',
                          page: 1,
                          type: that.type
                      }
                  })
              },100)
          }
      }
  },
  data(){
      return{
          type: 2 , // 默认搜资本
          keywords: '',    // 搜索关键词
          loading: false, // 加载
          list: [],    // 列表
          offset: 1,  // 分页
          total: 0,   // 总数
          limit: 10   // 限制个数
      }
  },
  methods:{
      changeType(val){   // 修改搜索类型
          this.$router.push({
              path:'/search-capital',
              query:{
                  keywords: this.keywords,
                  page: 1,
                  type: val
              }
          })
      },
      pageChange(val){    // 翻页
          this.offset = val
          this.$router.push({
              path:'/search-capital',
              query:{
                  keywords: this.keywords,
                  page: this.offset,
                  type: this.type
              }
          })
      },
      newSearch(){    // 根据keywords跳转
          if(this.keywords.length === 0){ // 空判断
              ElMessage.warning('请输入搜索关键词!')
              return
          }
          if(this.keywords.trim().length === 0){  // 空格过滤
              this.keywords = ''
              return
          }
          if(this.keywords === this.$route.query.keywords){
              ElMessage.warning('已经搜索当前关键词!')
              return
          }
          this.$router.push({
              path:'/search-capital',
              query:{
                  keywords: this.keywords,
                  page: 1,
                  type: this.type
              }
          })
      },
      getQuery(){ // 获取路由参数
          this.keywords = !this.$route.query.keywords || this.$route.query.keywords.length === 0 ? '' : this.$route.query.keywords
          this.offset = this.$route.query.page ? Number(this.$route.query.page) : 1
          this.type = 2 // this.$route.query.type ? Number(this.$route.query.type) : 2
      },
      async getList(){ // 获取列表
          this.loading = true
          let res = undefined
          let userInfo = localStorage.getItem('userInfo')
          userInfo = JSON.parse(userInfo)
          let param = {
              "keyword": this.keywords,
              "limit": this.limit,
              "memberId": userInfo.id,
              "offset": this.offset,
              "type": 10  // 找公司
          }
          try {
              switch (this.type) {
                  case 0:
                      res = await search(param) // 有关键词搜索
                      break;
                  case 2:
                      param.type = 11     // 找资本
                      res = await search(param) // 有关键词搜索
                      break
                  default:
                      break;
              }
          } catch (error) {
              console.error('search company error:', error);
              this.list = []
              this.total = 0
              this.loading = false
              return
          }
          this.list = res.data.result
          this.total = res.data.total
          this.loading = false
      },
  }
}
</script>

<style lang="scss" scoped>
.search-list-container{
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.search-bar{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  z-index: 2000;
  .input{
      display: flex;
      align-items: center;
      border-radius: 4px;
      border: 1px solid $--color-theme;
      margin-left: 30px;
      :deep() .el-input{
          width: 424px;
          font-size: 16px;
      }
      :deep() .el-input__inner{
          border: none;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          height: 50px;
          padding-right: 0;
      }
      .search-button{
          width: 80px;
          height: 50px;
          background: $--color-theme;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          font-size: 16px;
          color: #ffffff;
      }
  }
}
.page-container{
  display: flex;
  justify-content: center;
  width: 100%;
}
.pagination{
  border: 1px solid #D8D8D8;
  margin-top: -2px;
  width: 1200px;
  display: flex;
  justify-content: center;
  background: #ffffff;
  padding: 50px 0;
  margin-bottom: 20px;
  :deep() .btn-next{
      background: #ffffff;
  }
  :deep() .btn-prev{
      background: #ffffff;
  }
  :deep() .number{
      background: #ffffff !important;
  }
  :deep() .active{
      background: $--color-theme !important;
  }
}
</style>