<template>
  <div class="content">
    <p class="title">产品优势与特点</p>
    <div class="buttons">
      <button class="button" :class="{'active': cur === 0}" @click="cur = 0">产品优势</button>
      <button class="button" :class="{'active': cur === 1}" @click="cur = 1">产品特点</button>
    </div>
    <div class="parts" @mouseenter="show=true" @mouseleave="show=false">
      <div class="back" v-if="show">
        <img src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/00/22/1705915491271110" class="animate__animated animate__fadeInUp animate__faster" />
      </div>
      <div class="part-content" v-if="cur === 0">
        <div class="small-part" @mouseenter="cIndex=0" @mouseleave="cIndex=-1">
          <p class="p-0">数据驱动——精准数据决策</p>
          <p class="p-1">基于大数据和 AI 技术，确保高效、准确的推荐与匹配</p>
          <div class="bottom">
            <div class="left">
              <p class="animate__animated animate__fadeIn" v-if="cIndex === 0">立即咨询</p>
              <img v-if="!show" src="@/assets/images/G60/announce/right.png"/>
              <img v-else src="@/assets/images/G60/announce/r-255.png" />
            </div>
            <div class="right">
              <img src="@/assets/images/G60/announce/ad.png"/>
            </div>
          </div>
        </div>
        <div class="small-part" @mouseenter="cIndex=1" @mouseleave="cIndex=-1">
          <p class="p-0">用户友好——简洁易用</p>
          <p class="p-1">直观界面，便捷操作，降低使用门槛</p>
          <div class="bottom">
            <div class="left">
              <p class="animate__animated animate__fadeIn" v-if="cIndex === 1">立即咨询</p>
              <img v-if="!show" src="@/assets/images/G60/announce/right.png"/>
              <img v-else src="@/assets/images/G60/announce/r-255.png" />
            </div>
            <div class="right">
              <img src="@/assets/images/G60/announce/ad-1.png" />
            </div>
          </div>
        </div>
        <div class="small-part" @mouseenter="cIndex=2" @mouseleave="cIndex=-1">
          <p class="p-0">一站式服务——全生命周期支持</p>
          <p class="p-1">从初创到成熟，为企业提供全面的技术支持和运营服务</p>
          <div class="bottom">
            <div class="left">
              <p class="animate__animated animate__fadeIn" v-if="cIndex === 2">立即咨询</p>
              <img v-if="!show" src="@/assets/images/G60/announce/right.png"/>
              <img v-else src="@/assets/images/G60/announce/r-255.png" />
            </div>
            <div class="right">
              <img src="@/assets/images/G60/announce/ad-2.png"/>
            </div>
          </div>
        </div>
        <div class="small-part" @mouseenter="cIndex=3" @mouseleave="cIndex=-1">
          <p class="p-0">安全可靠——数据安全保障</p>
          <p class="p-1">采用先进加密技术，确保用户数据的安全与隐私</p>
          <div class="bottom">
            <div class="left">
              <p class="animate__animated animate__fadeIn" v-if="cIndex === 3">立即咨询</p>
              <img v-if="!show" src="@/assets/images/G60/announce/right.png"/>
              <img v-else src="@/assets/images/G60/announce/r-255.png" />
            </div>
            <div class="right">
              <img src="@/assets/images/G60/announce/ad-3.png"/>
            </div>
          </div>
        </div>
        <div class="small-part" @mouseenter="cIndex=4" @mouseleave="cIndex=-1">
          <p class="p-0">专业支持——即时专业帮助 </p>
          <p class="p-1">拥有专业的技术支持和售后服务团队，提供全方位支持</p>
          <div class="bottom">
            <div class="left">
              <p class="animate__animated animate__fadeIn" v-if="cIndex === 4">立即咨询</p>
              <img v-if="!show" src="@/assets/images/G60/announce/right.png"/>
              <img v-else src="@/assets/images/G60/announce/r-255.png" />
            </div>
            <div class="right">
              <img src="@/assets/images/G60/announce/ad-4.png"/>
            </div>
          </div>
        </div>
        <div class="small-part" @mouseenter="cIndex=5" @mouseleave="cIndex=-1">
          <p class="p-0">灵活定制——满足个性需求</p>
          <p class="p-1">提供多种版本选择，满足企业不同发展阶段的需求</p>
          <div class="bottom">
            <div class="left">
              <p class="animate__animated animate__fadeIn" v-if="cIndex === 5">立即咨询</p>
              <img v-if="!show" src="@/assets/images/G60/announce/right.png"/>
              <img v-else src="@/assets/images/G60/announce/r-255.png" />
            </div>
            <div class="right">
              <img src="@/assets/images/G60/announce/ad-5.png"/>
            </div>
          </div>
        </div>
      </div>
      <div class="part-content" v-else>
        <div class="small-part" @mouseenter="cIndex=0" @mouseleave="cIndex=-1">
          <p class="p-0">海量科研专家和项目数据支持</p>
          <p class="p-1">接入海量科研专家和项目数据，为企业提供强大支持</p>
          <div class="bottom">
            <div class="left">
              <p class="animate__animated animate__fadeIn" v-if="cIndex === 0">立即咨询</p>
              <img v-if="!show" src="@/assets/images/G60/announce/right.png"/>
              <img v-else src="@/assets/images/G60/announce/r-255.png" />
            </div>
            <div class="right">
              <img src="@/assets/images/G60/announce/ad.png"/>
            </div>
          </div>
        </div>
        <div class="small-part" @mouseenter="cIndex=1" @mouseleave="cIndex=-1">
          <p class="p-0">智能匹配，精准解决企业技术难题</p>
          <p class="p-1">利用智能匹配技术，精准对接企业技术难题和科研解决方案</p>
          <div class="bottom">
            <div class="left">
              <p class="animate__animated animate__fadeIn" v-if="cIndex === 1">立即咨询</p>
              <img v-if="!show" src="@/assets/images/G60/announce/right.png"/>
              <img v-else src="@/assets/images/G60/announce/r-255.png" />
            </div>
            <div class="right">
              <img src="@/assets/images/G60/announce/ad-1.png"/>
            </div>
          </div>
        </div>
        <div class="small-part" @mouseenter="cIndex=2" @mouseleave="cIndex=-1">
          <p class="p-0">实时更新前沿科技资讯，助力企业决策</p>
          <p class="p-1">持续更新前沿科技资讯，为企业决策提供有力支持</p>
          <div class="bottom">
            <div class="left">
              <p class="animate__animated animate__fadeIn" v-if="cIndex === 2">立即咨询</p>
              <img v-if="!show" src="@/assets/images/G60/announce/right.png"/>
              <img v-else src="@/assets/images/G60/announce/r-255.png" />
            </div>
            <div class="right">
              <img src="@/assets/images/G60/announce/ad-2.png"/>
            </div>
          </div>
        </div>
        <div class="small-part" @mouseenter="cIndex=3" @mouseleave="cIndex=-1">
          <p class="p-0">多种收费模式，满足不同客户需求</p>
          <p class="p-1">提供多种收费模式，灵活满足不同企业的预算和需求</p>
          <div class="bottom">
            <div class="left">
              <p class="animate__animated animate__fadeIn" v-if="cIndex === 3">立即咨询</p>
              <img v-if="!show" src="@/assets/images/G60/announce/right.png"/>
              <img v-else src="@/assets/images/G60/announce/r-255.png" />
            </div>
            <div class="right">
              <img src="@/assets/images/G60/announce/ad-3.png"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default{
  data(){
    return{
      cur: 0,
      show: false,
      cIndex: -1,
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  width: 100%;
  padding-top: 90px;
  .title{
    width: 100%;
    text-align: center;
    font-size: 32px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    color: #111111;
    line-height: 45px;
    margin-bottom: 44px;
  }
  .buttons{
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 40px;
    .button{
      width: 117px;
      height: 45px;
      border-radius: 10px;
      border: 1px solid #F67E2B;
      box-sizing: border-box;
      cursor: pointer;
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #F67E2A;
      margin: 0 6px;
      background-color: transparent;
    }
    .active{
      width: 117px;
      height: 45px;
      background: linear-gradient(316deg, #F67E2A 0%, #FFECE0 100%, #FFECE0 100%);
      border-radius: 10px;
      box-sizing: border-box;
      cursor: pointer;
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      border: none;
    }
  }
  .back{
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    background-color: #647598;
    width: 100%;
    height: 100%;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .parts{
    position: relative;
    width: 100%;
    background: #F8F9FB;
    display: flex;
    justify-content: center;
    overflow: hidden;
    &:hover{
      .p-0{
        color: #ffffff !important;
      }
      .p-1{
        color: #ffffff !important;
      }
      .bottom{
        .right{
          img{
            display: none;
          }
        }
      }
    }
    .part-content{
      z-index: 10;
      width: 1200px;
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid #F1F1F1;
      border-left: 1px solid #F1F1F1;
      cursor: pointer;
      .small-part{
        width: 400px;
        border: 1px solid #F1F1F1;
        border-top: 0;
        border-left: 0;
        box-sizing: border-box;
        padding: 35px 30px 0 30px;
        &:hover{
        background-color: rgba(255,255,255,0.4);
      }
        .p-0{
          font-size: 18px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          color: #111111;
          line-height: 30px;
          margin-bottom: 10px;
          white-space: nowrap;
        }
        .p-1{
          white-space: nowrap;
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          color: #666666;
          line-height: 30px;
          margin-bottom: 10px;
          margin-bottom: 33px;
        }
      }
      .bottom{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .left{
          padding-top: 66px;
          display: flex;
          align-items: center;
          p{
            font-size: 16px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 22px;
            display: none;
            margin-right: 4px;
          }
          img{
            width: 22px;
            height: 14px;
          }
        }
        .right{
          width: 134px;
          height: 141px;
          img{
            width: 134px;
            height: 141px;
          }
        }
      }
    }
  }
}
</style>