<template>
  <div class="login-container">

    <div v-if="showIndustry" class="login-panel" :style="`${step === 0 ? 'width: 690px;height: 615px;' : ''}`">
      <div class="login-title">
        <div style="color: #111111; font-size: 18px">{{ step === 0 ? '请选择您所属身份类型' : typeTitle }}</div>
      </div>
      <div class="back-icon" v-if="step === 1" @click="step = 0">
        <img src="@/assets/images/login/back.png" />
      </div>
      <div class="login-form-new" v-if="step === 1" v-loading="loading">
        <div class="p-options-list">
          <div class="p-option" @click="selectedPOIndex = i" :class="{'p-option-selected': i === selectedPOIndex}" v-for="(po, i) in pOptions" :key="po.id">
            {{po.val}}<span class="select-num">{{po.selectNum > 0 ? '(' + po.selectNum + ')' : ''}}</span>
          </div>
        </div>
        <div class="c-options-list">
          <div @click="selectIndustry(co)" class="c-option" :class="{'c-option-selected': JSON.stringify(selectedIndustrys).indexOf(co.id) !== -1}" v-for="co in cOptions" :key="co.id">
            {{ co.val }}
          </div>
        </div>
      </div>
      <div class="login-form-new type-list" v-else-if="step === 0" v-loading="loading">
        <div class="type-container" v-for="(item,index) in typeList" :key="item.id" @click="selectIdentity(index)">
          <div class="type-icon" :class="{'type-active': cur === index}">
            <img src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/01/27/1709025937435662" v-if="cur === index" class="active-icon"/>
            <div>
              <p>{{ item.des }}</p>
              <p v-if="item.des === '科技创业者'">大学生创客</p>
            </div>
            <img :src="staticPath + item.spaceBackgroudImg" />
          </div>
        </div>
      </div>
      <div v-if="step === 0" class="next-line">
        <button @click="nextStep">下一步</button>
      </div>
      <div class="confirm-button-container" v-if="step === 1">
        <el-button @click="confirmIndustry()" :disabled="selectedIndustrys.length === 0" :class="{'confirm-button': true}" round>立即进入科企岛</el-button>
      </div>
    </div>

    <div class="bottom-decoration">
      <div class="line"></div>
      <div>
        科技赋能产业 让技术创造价值
      </div>
      <div class="line"></div>
    </div>

  </div>
</template>

<script>
import { updateProfile } from '@/config/WebTIMConfig'    // 对腾讯个人信息进行更新
import { getOptionsByType, industryType,getSelectFatherSonByType } from "@/api/config";
import { bindIndustry } from "@/api/user";
import { ElMessage } from 'element-plus';
import { getIdentityTypes } from "@/utils/tool";
import { staticPath } from "@/utils/consts";


export default {
  name: "Industry",
  data() {
    return {
      cur: -1,
      staticPath,
      step: 0,
      loading: false,
      typeList: [],
      typeInfo: {},
      typeTitle: '选择行业',
      isNewType: false,
      selectedIndustrys:[], // 多选行业
      pOptions: [],
      selectedPOIndex: 0,
      redirect: undefined,
      otherQuery: undefined,
      showIndustry: false
    }
  },
  created(){
    this.$store.commit('message/SET_ACTIVENAV','-')
  },
  watch: {
    step(val){
      if(val === 0){
        this.typeInfo = {}
        this.pOptions = []
        this.selectedIndustrys = []
        this.selectedPOIndex = 0
      }
    },
    $route: {
      handler: function (route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true
    }
  },
  computed: {
    cOptions() {
      return this.pOptions.length > 0 ?this.pOptions[this.selectedPOIndex].children: []
    }
  },

  mounted() {
    this.getIndustryId()
    this.getOptions()
  },
  methods: {
    nextStep(){
      if(this.cur === -1){
        ElMessage.warning('请选择身份类型!')
        return
      }
      const item = this.typeList[this.cur]
      this.typeInfo = item
      this.getOptionNew(item)
      this.step = 1
    },
    selectIdentity(index){
      if(this.cur === index){
        this.cur = -1
        return
      }
      this.cur = index
    },
    getIndustryId() {
      let userinfo = localStorage.getItem('userInfos')
      userinfo = JSON.parse(userinfo)
      if (userinfo.memberInfo.memberIndustry == null || userinfo.memberInfo.memberIndustry.length == 0) { // 如果没有选择绑定过行业
				// console.log(true);
        this.showIndustry = true
      } else {
        this.showIndustry = false
      }
    },
    selectIndustry(co) {
      let index = -1
      this.selectedIndustrys.find((item,i)=>{  // 从数组中判断是否已经选择过
        if(item.id === co.id){
          index = i
          return
        }
      })
      if(index === -1){   // 说明没有选择过
        if(this.selectedIndustrys.length === 10){ // 最多选择10个行业
          ElMessage.warning('最多选择10个行业')
          return
        }
        this.selectedIndustrys.push({
          id:co.id,
          val:co.val
        })
        this.pOptions[this.selectedPOIndex].selectNum ++  // 左侧计数 + 1
      }
      else{ // 如果选择过，再次点击则删除
        this.selectedIndustrys.splice(index,1)
        this.pOptions[this.selectedPOIndex].selectNum -- // 左侧计数 - 1
      }
    },
    getOptionNew(item){
      const isJson = (str)=>{
        if (typeof str == 'string') {
          try {
            let obj=JSON.parse(str);
            if(typeof obj == 'object' && obj ){
              return true;
            }else{
              return false;
            }
          } catch(e) {
            return false;
          }
        }
        return false
      }
      let value = item.extendConfigJson
      if(isJson(value)){
        value = JSON.parse(value)
      }
      this.typeTitle = value.iconTitle || '选择行业'
      this.typeInfo.extendConfigJson = value
      if(value.dictType && value.dictType + '' !== '6'){
        this.loading = true
        this.isNewType = true
        getSelectFatherSonByType(value?.dictType).then(res=>{
          this.loading = false
          let arr = []
          res.data.forEach(item=>{
            if(item.child?.length > 0){
              arr = [...arr,...item.child]
            }
            arr.push(item)
          })
          let data = arr
          let idMap = {}
          for (let o of data) {
            if (o.pid === 0) {
              o.children = []
              let newLength = this.pOptions.push(o)
              idMap[o.id] = newLength - 1
            }
          }
          for (let o of data) {
            if (o.pid !== 0) {
              if (idMap[o.pid]) {
                this.pOptions[idMap[o.pid]].children.push(o)
              }else if (idMap[o.pid] == 0) {
                this.pOptions[idMap[o.pid]].children.push(o)
              }
            }
          }
          this.pOptions = this.pOptions.map((item)=>{
            return {
              ...item,
              selectNum: 0  // 每个类型选项个数
            }
          })
        })
      }
      else{
        this.loading = true
        this.isNewType = false
        getOptionsByType(industryType).then(res => {
          this.loading = false
          let data = res.data.result
          let idMap = {}
          for (let o of data) {
            if (o.pid === 0) {
              o.children = []
              let newLength = this.pOptions.push(o)
              idMap[o.id] = newLength - 1
            }
          }
          for (let o of data) {
            if (o.pid !== 0) {
              if (idMap[o.pid]) {
                this.pOptions[idMap[o.pid]].children.push(o)
              }else if (idMap[o.pid] == 0) {
                this.pOptions[idMap[o.pid]].children.push(o)
              }
            }
          }
          this.pOptions = this.pOptions.map((item)=>{
            return {
              ...item,
              selectNum: 0  // 每个类型选项个数
            }
          })
        })
      }
    },
    getOptions() {
      this.loading = true
      getIdentityTypes().then(res=>{
        this.typeList = res
      }).finally(()=>{
        this.loading = false
      })
    },
    confirmIndustry() {
      const params = {
        industryIds: this.selectedIndustrys.map((item)=>parseInt(item.id)),
        preselectionOrganizationTypeId: this.typeInfo.id
      }
      if(this.isNewType){
        params.industryIds = [parseInt(this.typeInfo.extendConfigJson.defaultIndustryId)]
        params.preselectionIndustryIds = this.selectedIndustrys.map((item)=>parseInt(item.id))
      }
      this.loading = true
      bindIndustry(params).then(() => {
        // console.log('set-industry/返回数据', res)
        let temp = JSON.parse(localStorage.getItem('userInfos'))
        if (!temp.memberInfo.memberIndustry) {
          temp.memberInfo.memberIndustry = []
        }
        temp.memberInfo.memberIndustry = this.selectedIndustrys // 缓存行业选项
        localStorage.setItem("userInfos", JSON.stringify(temp))
        this.$store.dispatch('user/setIndustry', this.selectedIndustrys).then(() => { // 存入VUEX
          // this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
          let userInfo = localStorage.getItem('userInfos')
          userInfo = JSON.parse(userInfo).memberInfo
          updateProfile() // 选择行业后更新默认头像
          let { role } = userInfo
          if (role !== 4) {
            this.$router.push({ path: '/' })
          }
        })
      }).finally(()=>{
        this.loading = false
      })
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    }
  }
}
</script>

<style scoped lang="scss">
.fix-top {
  position: fixed;
  top: 0;
}

.login-container {
  min-height: 100vh;
  width: 100%;
  background: url("../../assets/images/bg.jpg");

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.login-panel {
  height: 560px;
  width: 760px;
  overflow: hidden;
  position: relative;
  .back-icon{
    position: absolute;
    top: 22px;
    right: 28px;
    cursor: pointer;
    img{
      width: 18px;
      height: 18px;
    }
  }
}

.login-title {
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid #ebebeb;
}

.code-button :hover,
.code-button :active {
  display: inline-block;
  color: $--color-theme;
}

.login-form-new {
  background: white;
  height: 420px;
  display: flex;
}
.next-line{
  width: 100%;
  padding-bottom: 36px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  button{
    width: 157px;
    height: 38px;
    background: $--color-theme;
    border-radius: 4px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    border: none;
    cursor: pointer;
  }
}
.type-list{
  height: 480px !important;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 0 74px;
  .type-container{
    flex-grow: 0;
    flex-shrink: 0;
    width: 50%;
    height: 90px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    padding: 9px 0;
  }
  .type-active{
    border: 1px solid $--color-theme !important;
    .active-icon{
      width: 16px;
      height: 16px;
      position: absolute;
      right: 8px;
      top: 8px;
    }
  }
  .type-icon{
    width: 230px;
    height: 90px;
    background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/01/27/1709024324784456');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    position: relative;
    cursor: pointer;
    border: 1px solid #ffffff;
    &:hover{
      border: 1px solid $--color-theme;
    }
    p{
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #111111;
      line-height: 22px;
    }
    img{
      width: 70px;
      height: 70px;
    }
  }
}

.confirm-button-container {
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-top: 1px #A0A0A0 solid;
}

.confirm-button {
  background: $--color-theme;
  font-size: 20px;
  color: white;
  border: none;
}

.bottom-decoration {
  margin-top: 40px;

  color: white;
  font-size: 24px;
  width: 720px;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.line {
  height: 0;
  width: 124px;
  border-bottom: 1px white solid;
}

.p-options-list {
  color: #333333;
  width: 260px;
  height: 420px;
  overflow-y: scroll;
  overflow-x: hidden;
  background: rgb(245, 245, 248);
}

.p-options-list::-webkit-scrollbar {
  width: 0
}

.p-options-list {
  -ms-overflow-style: none;
}

.p-options-list {
  overflow: -moz-scrollbars-none;
}

.p-option {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  height: 54px;
  cursor: pointer;
  .select-num{
    color: $--color-theme;
  }
}

.p-option:hover {
  background: white;
}

.p-option-selected {
  color: #333333;
  background: white;
}

.c-options-list {
  width: 500px;
  height: 420px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.c-option {
  height: 54px;
  color: #333333;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px 0 15px;
  cursor: pointer;
}

.c-option:hover {
  color: $--color-theme;
}

.c-option-selected {
  color: $--color-theme;
  background: #e4e4e4;
}
</style>
