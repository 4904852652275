<template>
  <div class="container">
    <div class="top">
      <div class="words">
        <img src="@/assets/images/SEI/title.png" class="title" />
        <p class="m-b-23">SEI Club 是为科学家、企业家、投资家、产业专家提供的一个新经济环境 下的高质量、高诚信的品牌化高端科创精英俱乐部。俱乐部旨在促进不同领域的 精英人士之间的沟通与合作，推动科技创新和商业发展。</p>
        <p class="m-b-23">SEI Club 的成员包括来自各个领域的科学家、投资家和企业家，他们在各 自领域具有卓越的成就和经验。通过定期举办国内外论坛、商学院沙龙、企业游 学、专家研讨会、产业博览会等活动，俱乐部为成员提供了一个互相交流、分享 经验和合作机会的平台。</p>
        <p >SEI Club 的活动内容涵盖了科技创新、技术成果转化、投资与融资、商业 发展等多个方面。成员可以通过参与讲座、演讲、项目洽谈等活动，拓展人脉、 获取行业动态和商业机会。此外，俱乐部还与政府、高校、科研院所、高科技企 业等建立了广泛的合作关系，可为成员提供更多的资源、支持和产学研服务。通 过俱乐部的平台，成员可以与各界精英建立联系，共同推动科技创新和商业发展， 促进社会经济的繁荣和进步。</p>
      </div>
    </div>
    <div class="form">
      <div class="title">构建工作台—数字科创中心</div>
      <p class="tip">以下为必填<span>*</span>内容</p>
      <div class="form-item flex-cc">
        <p class="form-title"><span>*</span>您的学校：</p>
        <input v-model="form.schoolName" class="input" placeholder="请输入您的学校" maxlength="40"/>
      </div>
      <div class="form-item flex-cc">
        <p class="form-title"><span>*</span>您的学院：</p>
        <input v-model="form.collegeName" class="input" placeholder="请输入您的学院" maxlength="40"/>
      </div>
      <div class="form-item flex-cc">
        <p class="form-title"><span>*</span>您的姓名：</p>
        <input v-model="form.name" class="input" placeholder="请输入您的姓名" maxlength="40"/>
      </div>
      <div class="form-item flex-cc">
        <p class="form-title"><span>*</span>实验室名称：</p>
        <input v-model="form.labName" class="input" placeholder="请输入实验室名称" maxlength="40"/>
      </div>
      <div class="form-item flex-cc">
        <p class="form-title"><span>*</span>手机号：</p>
        <input v-model="form.phone" class="input" placeholder="请输入手机号" type="text"  maxlength="11"/>
      </div>
      <div class="form-item flex-cc">
        <p class="form-title"></p>
        <input v-model="form.code" class="input code" placeholder="请输入验证码" type="text"  maxlength="6" />
        <div v-if="showCode" class="code-button" @click="getPhoneCode">验证码</div>
        <div v-else class="count-down">{{ count }}秒</div>
      </div>
      <div class="form-item flex-cc">
        <p class="form-title"><span>*</span>需求分类（可多选）：</p>
        <el-checkbox v-model="form.checkbox1">校企合作</el-checkbox>
        <el-checkbox v-model="form.checkbox2">项目融资</el-checkbox>
        <el-checkbox v-model="form.checkbox3">政策服务</el-checkbox>
        <el-checkbox v-model="form.checkbox4">人才输出</el-checkbox>
      </div>
      <div class="line-tip">
        以下为选填内容（可以让企业家、投资家主动跟您合作）
      </div>
      <div class="form-item flex">
        <p class="form-title p-t-10">技术成果介绍：</p>
        <textarea v-model="form.introduce" class="textarea" type="textarea" maxlength="500"  />
      </div>
      <div class="form-item flex-cc">
        <p class="form-title">请输入您的科研方向：</p>
        <input v-model="form.label" class="input" placeholder="请输入您的科研方向，以“，”分割" maxlength="40"/>
      </div>
      <div class="form-item flex-cc">
        <p class="form-title">学历：</p>
        <el-select v-model="form.education.val" placeholder="请选择学历" class="select">
          <el-option
            v-for="item in form.education.list"
            :key="item.id"
            :label="item.val"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="form-item flex-cc">
        <p class="form-title">职称：</p>
        <el-select v-model="form.position.val" placeholder="请选择职称" class="select">
          <el-option
            v-for="item in form.position.list"
            :key="item.id"
            :label="item.val"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="form-item flex-cc">
        <p class="form-title">联系邮箱：</p>
        <input v-model="form.email" class="input" placeholder="请输入联系邮箱" maxlength="40"/>
      </div>
      <div class="form-item flex-cc">
        <p class="form-title">实验室地址：</p>
        <el-cascader style="width: 517px;" class="select" ref="cascaderAddr" :props="props" placeholder="请选择实验室地址" v-model="form.address" @visible-change="visibleChange" @change="visibleChange" @expand-change="visibleChange"></el-cascader>
      </div>
      <div class="confirm-button" @click="submit" v-loading="loading">
        确认并创建数字科创中心
      </div>
    </div>
  </div>
</template>


<script>
import { ElMessage } from 'element-plus';
import { getPhoneCode } from "@/api/user";
import { getprovince, getSelect, getcity, getarea } from "@/api/demand";
import { SEIactivity } from "@/api/activity";
import { bindLab } from "@/api/user";
import { getOrganizationTypes } from "@/api/organization";

export default{
  data(){
    return{
      count: 60,
      showCode: true, // 是否显示倒计时
      timer: null,
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          setTimeout(() => {
            if (node.level == 0) {
              getprovince().then(response => {
                const cities = []
                for (let item of response.data) {
                  cities.push({
                    value: item.provinceCode,
                    label: item.provinceName,
                    leaf: false
                  })
                }
                resolve(cities);
              });
            }
            if (node.level == 1) {
              getcity(node.value).then(response => {
                const areas = [];
                for (let item of response.data) {
                  areas.push({
                    value: item.cityCode,
                    label: item.cityName,
                    leaf: false
                  })
                }
                resolve(areas);
              });
            } else if (node.level == 2) {
              getarea(node.value).then(response => {
                const areas = []
                for (let item of response.data) {
                  areas.push({
                    value: item.areaCode,
                    label: item.areaName,
                    leaf: true
                  })
                }
                resolve(areas);
              });
            }
          }, 100);
        }
      },
      labTypes: [],
      loading: false,
      form:{
        address: '',
        schoolName: '',
        collegeName: '',
        name: '',
        labName: '',
        phone: '',
        code: '',
        introduce: '',
        label: '',
        checkbox1: false,
        checkbox2: false,
        checkbox3: false,
        checkbox4: false,
        education:{
          list: [],
          val: '',
          label: '',
        },
        position: {
          list: [],
          val: '',
          label: '',
        },
        email: '',
      }
    }
  },
  computed:{
    phoneNumber(){
      return this.form.phone
    },
    phoneCode(){
      return this.form.code
    },
  },
  watch:{
    phoneNumber(val){
      if(val?.length <= 0) return
      this.form.phone = this.form.phone.replace(/[^\d]/g,'')
    },
    phoneCode(val){
      if(val?.length <= 0) return
      this.form.code = this.form.code.replace(/[^\d]/g,'')
    },
  },

  mounted(){
    this.getSelections()
  },
  methods:{
    submit(){
      if(!this.form.schoolName){
        ElMessage.warning('请输入您的学校')
        return
      }
      else if(!this.form.collegeName){
        ElMessage.warning('请输入您的学院')
        return
      }
      else if(!this.form.name){
        ElMessage.warning('请输入您的姓名')
        return
      }
      else if(!this.form.labName){
        ElMessage.warning('请输入您的实验室名称')
        return
      }
      else if(!this.form.phone){
        ElMessage.warning('请输入手机号')
        return
      }
      else if(!this.form.code){
        ElMessage.warning('请输入验证码')
        return
      }
      else if(!this.form.code){
        ElMessage.warning('请输入验证码')
        return
      }
      else if(!this.form.checkbox1 && !this.form.checkbox2 && !this.form.checkbox3 && !this.form.checkbox4){
        ElMessage.warning('请选择需求分类')
        return
      }
      let flag = false
      if(this.form.label && this.form.education.val && this.form.position.val && this.form.email && this.form.address) flag = true
      let demandType = ''
      if(this.form.checkbox1) demandType += '校企合作;'
      if(this.form.checkbox2) demandType += '项目融资;'
      if(this.form.checkbox3) demandType += '政策服务;'
      if(this.form.checkbox4) demandType += '人才输出;'
      this.loading = true
      SEIactivity({
        "specialType": '2',
        "specialTypeStr": '来源:SEI club-' + '科学家' + '-' + this.form.schoolName + '-' + this.form.collegeName + '-' + demandType,
        "code": this.form.code,
        "companyName": this.form.labName,
        "contactWay": this.form.phone,
        "name": this.form.name,
        "position": this.form.position.list.find((item)=>item.id + '' === this.form.position.val + '')?.val || '',
        "powerPointPresentation": "否",
        "productRecommendations": "否",
      }).then(res=>{
        console.log(res,'hehe');
        this.$store.dispatch("user/resLogin", {
          res
        })
        if(flag){
          // 提交成功
          this.registerLab(res.data?.memberInfo)
        }
        else{
          ElMessage.success('提交成功!')
          this.loading = false
          this.$router.push('/')
        }
      })
    },
    registerLab(data){
      // 注册身份
      if(!data) return
      const address = this.form.address
      Date.prototype.Format = function (fmt) {
        var o = {
          "M+": this.getMonth() + 1, //月份
          "d+": this.getDate(), //日
          "H+": this.getHours(), //小时
          "m+": this.getMinutes(), //分
          "s+": this.getSeconds(), //秒
          "q+": Math.floor((this.getMonth() + 3) / 3), //季度
          "S": this.getMilliseconds() //毫秒
        };
        if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (var k in o)
          if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return fmt;
      }
      const param = {
        "addressAreaId": parseInt(address[2]),
        "addressAreaName": this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels[2],
        "addressCityId": parseInt(address[1]),
        "addressCityName": this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels[1],
        "addressProvinceId": parseInt(address[0]),
        "addressProvinceName": this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels[0],
        "belong": this.form.schoolName,
        "createMan": data.name,
        "educationId": this.form.education.val,
        "email": this.form.email,
        "labTypeId": this.labTypes[0].id,
        "label": this.form.label.split('，'),
        "license": "/defaultlogo.png",
        "name": this.form.labName,
        "orgIntroduction": this.form.introduce,
        "phone": this.form.phone,
        "positionId": this.form.position.val,
        "qualifications": ['/defaultlogo.png'],
        "salesmanId": data.id,
        "scaleId": 100,
        "signTime": new Date().Format("yyyy-MM-dd HH:mm:ss"),
        "summary": "",
        "unionId": this.form.phone,
        "verifyStatus": 0
      }
      bindLab(param).catch(err=>{
        console.log(err,'创建身份错误');
      }).finally(()=>{
        ElMessage.success('提交成功!')
        this.loading = false
        this.$router.push('/')
      })
    },
    visibleChange(){
      this.$nextTick(() => {
        const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
        Array.from($el).map((item) => item.removeAttribute('aria-owns'));
      });
    },
    getSelections(){
      let requestdata = {
        "limit": 100,
        "offset": 1,
        "type": 4
      }
      getSelect(requestdata).then(res=>{
        this.form.education.list = res.data.result;
      })
      requestdata.type = 10
      getSelect(requestdata).then(res=>{
        this.form.position.list = res.data.result;
      })
      getOrganizationTypes(0).then(res=>{
        this.labTypes = res.data.list
      })
    },
    getPhoneCode() {
      const TIME_COUNT = 60;
      var reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (reg.test(this.form.phone) == false) {
        ElMessage.warning("您输入的手机号码格式不正确，请重新输入");
        return;
      }
      getPhoneCode({ phone: this.form.phone })
        .then(() => {
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.showCode = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.showCode = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
          ElMessage.success("发送成功");
        })
        .catch((err) => {
          ElMessage.error({ message: err.message });
        });
    },
  }
}
</script>

<style lang="scss" scoped>
.container{
  width: 100%;
  background: #F4F7FC;
  padding-bottom: 80px;
  .top{
    width: 100%;
    padding: 86px 0 35px;
    background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/08/27/1695777231544051');
    background-size: cover;
    background-repeat: no-repeat;
    .words{
      width: 1200px;
      box-sizing: border-box;
      padding-left: 80px;
      margin: 0 auto;
      .title{
        width: 464px;
        height: 38px;
        margin-bottom: 50px;
      }
      .m-b-23{
        margin-bottom: 23px;
      }
      p{
        width: 682px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 23px;
        letter-spacing: 1px;
      }
    }
  }
  .form{
    width: 1200px;
    background-color: #ffffff;
    margin: 0 auto;
    margin-top: 10px;
    padding-bottom: 110px;
    .title{
      width: 1123px;
      margin: 0 auto;
      padding: 36px 0 34px;
      text-align: center;
      border-bottom: 1px solid #EDEDED;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #111111;
      line-height: 25px;
    }
    .tip{
      padding: 16px 0 26px;
      margin-left: 143px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #111111;
      line-height: 20px;
      span{
        color: #EB3030;
      }
    }
    .form-item{
      width: 100%;
      padding: 13px 0;
      :deep(.el-checkbox){
        margin-right: 60px;
      }
      .p-t-10{
        padding-top: 10px;
      }
      .form-title{
        width: 360px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 20px;
        text-align: right;
        span{
          color: #E21313;
        }
      }
      .textarea{
        width: 517px;
        height: 100px;
        border-radius: 4px;
        border: 1px solid #E1E1E1;
        box-sizing: border-box;
        padding: 10px 13px;
        resize: none;
      }
      .select{
        :deep(.el-input__inner){
          font-size: 14px !important;
          &::placeholder{
            color: #c8c9cc;
          }
        }
      }
      .input{
        width: 517px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #E1E1E1;
        box-sizing: border-box;
        padding: 0 13px;
        &::placeholder{
          color: #c8c9cc;
        }
      }
      .code{
        width: 399px !important;
        margin-right: 12px;
      }
      .code-button{
        width: 106px;
        height: 40px;
        background: $--color-theme;
        border-radius: 4px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
      }
      .count-down{
        width: 106px;
        height: 40px;
        background: #2282ffe1;
        border-radius: 4px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 40px;
        text-align: center;
        cursor: not-allowed;
      }
    }
    .confirm-button{
      margin: 43px auto 0;
      width: 202px;
      height: 40px;
      background: $--color-theme;
      cursor: pointer;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 40px;
      text-align: center;
    }
    .line-tip{
      width: 1116px;
      height: 36px;
      background: #F7FAFF;
      box-sizing: border-box;
      margin: 34px auto 15px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: $--color-theme;
      padding-left: 104px;
      line-height: 36px;
    }
  }
}
</style>