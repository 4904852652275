<template>
  <div class="flex-rc" style="background: #f8f8f8;">
    <div class="company-detail-container">
      <!-- 上方面包路由 -->
      <div class="flex route-bread fs-14">
        <div @click="jumpBread('/')" class="cursor-p show-hover">首页 >&nbsp;</div>
        <div @click="jumpBread('/user-center')" class="cursor-p show-hover">数字科创中心(产学研) >&nbsp;</div>
        <div @click="jumpBread('-1')" class="cursor-p show-hover">搜企业 >&nbsp;</div>
        <div>{{companyName}}</div>
      </div>
      <div v-loading="loading">
        <!-- 上方详情 -->
        <CompanyCard v-if="companyInfo.name" :company="companyInfo"></CompanyCard>
        <!-- 下方简介 -->
        <div class="introduction-title fs-18 fc-333">
          <span>公司简介</span>
        </div>
        <div class="introduction-context fs-18 fc-333" v-if="companyInfo.name && companyInfo.introduce">
          <span v-html="companyInfo.introduce"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { delHtmlTag } from "@/utils/tool";
import CompanyCard from '@/views/company/component/CompanyCard';
import { routerWindow } from "@/utils/tool";
import { getCompanyDetail } from "@/api/company.js";
import to from "await-to-js";

var fromCenter = false;
export default {
  components:{
    CompanyCard
  },
  beforeRouteEnter(to,from){
    // 判断路由来源
    if(from.path === '/user-center'){
      fromCenter = true
    }
  },
  data(){
    return{
      companyInfo: {},  // 企业信息
      loading: false, // 加载中
    }
  },
  computed:{
    companyName(){
      // 企业名称
      return this.companyInfo.name ?  delHtmlTag(this.companyInfo.name) : ''
    }
  },
  created(){
    // this.$store.dispatch('user/setNavbarBread',[
    //   {
    //     path: '/',
    //     name: '首页',
    //   },
    //   {
    //     path: '/search-company',
    //     name: '搜企业',
    //   },
    //   {
    //     path: '',
    //     name: '正文',
    //   },
    // ])
    this.getInfo()
  },
  methods:{
    jumpBread(route){
      // 面包屑跳转
      if(route === '-1'){
        if(fromCenter){
          routerWindow(this.$router,'/search-company',{})
          return
        }
        else{
          this.$router.go(-1)
          return
        }

      }
      this.$router.push(route)
    },
    async getInfo(){
      // 获取缓存中数据，若没有返回搜企业页面
      this.loading = true
      const [err,res] = await to(getCompanyDetail({id:this.$route.query.id}))
      this.loading = false
      if(err){
        console.error('获取导入公司信息错误',err);
        return
      }
      this.companyInfo = res.data
    }
  }
}
</script>

<style lang="scss" scoped>
.company-detail-container{
  width: 1200px;
  position: relative;
  .introduction-title{
    width: 100%;
    margin-top: 15px;
    background: #FFFFFF;
    padding: 25px 0 24px 30px;
    box-sizing: border-box;
    border-bottom: 1px solid #D8D8D8;
    position: relative;
  }
  .introduction-title::before{
    content: '';
    position: absolute;
    width: 72px;
    height: 2px;
    background: #333333;
    border-radius: 1px;
    left: 30px;
    bottom: -1px;
  }
  .introduction-context{
    background: #FFFFFF;
    width: 100%;
    box-sizing: border-box;
    padding: 24px 30px 40px 30px;
    margin-bottom: 20px;
    line-height: 32px;
  }
  .route-bread{
    padding: 24px 0;
    .show-hover:hover{
      color: $--color-theme;
    }
  }
}
</style>