<template>
    <div class="container">
        <div class="part-1">
            <p class="left">产品同质化严重，无竞争力？</p>
            <p class="top"><span style="padding-left: 23px;">没有高校科研院所的前沿技术合作渠道?</span></p>
            <p class="right">企业研发成本居高不下？</p>
            <p class="bottom-1"><span style="padding-left: 20px;">无法低成本高效引入所需要的科技成果？</span></p>
            <p class="bottom-2"><span style="padding-left: 11px;">企业技术突破创新难?</span></p>
            <p class="center-word">您的企业是否面临如下的问题？<br/>科企岛数字科创平台帮你解决！</p>
        </div>
        <div class="part-2">
            <p class="title-1">科企岛数字化科创服务平台</p>
            <p class="title-2">赋予企业强大的技术力量/让企业获得创新突破和技术升级</p>
            <div class="flex-rc-cc">
                <div class="left flex-w">
                    <div class="num right-line">
                        <img class="num-pic" src="../../assets/images/project/company/num-1.png" />
                        <p class="num-word">专家教授</p>
                    </div>
                    <div class="num" style="padding-left: 55px;">
                        <img class="num-pic" src="../../assets/images/project/company/num-2.png" />
                        <p class="num-word">细分领域研究方向</p>
                    </div>
                    <div class="num right-line">
                        <img class="num-pic" src="../../assets/images/project/company/num-3.png" />
                        <p class="num-word">技术成果</p>
                    </div>
                    <div class="num" style="padding-left: 55px;">
                        <img class="num-pic" src="../../assets/images/project/company/num-4.png" />
                        <p class="num-word">科研院所前沿项目</p>
                    </div>
                </div>
                <img src="../../assets/images/project/company/num-right.png" class="right" />
            </div>
        </div>
        <div class="part-3">
            <p class="title">在线创建企业专属技术研究院，将高校科研人才与企业成功串联</p>
            <div class="flex-rc">
                <img src="../../assets/images/project/company/part-3.png" />
            </div>
        </div>
        <div class="part-4">
            <p class="title">简单/丰富/价值/效率</p>
            <img src="../../assets/images/project/company/part-4.png" class="img" />
        </div>
        <div class="part-5">
            <p class="title">科企岛企业服务内容</p>
            <div class="flex-w flex-cc part-5-line">
                <div class="option">
                    <img src="../../assets/images/project/company/icon-1.png" />
                    <p>获取技术专家资源</p>
                </div>
                <div class="option">
                    <img src="../../assets/images/project/company/icon-2.png" />
                    <p>发布技术需求优先响应</p>
                </div>
                <div class="option">
                    <img src="../../assets/images/project/company/icon-3.png" />
                    <p>行业发展趋势研究报告</p>
                </div>
                <div class="option">
                    <img src="../../assets/images/project/company/icon-4.png" />
                    <p>专家智能化推荐</p>
                </div>
                <div class="option">
                    <img src="../../assets/images/project/company/icon-5.png" />
                    <p>为企业提供融资需求</p>
                </div>
                <div class="option">
                    <img src="../../assets/images/project/company/icon-6.png" />
                    <p>实验室仪器设备对接服务</p>
                </div>
                <div class="option" style="width: 583px;">
                    <img src="../../assets/images/project/company/icon-7.png" />
                    <p>每年举办百余场科技大赛为大量企业引进技术资源高端人才</p>
                </div>
            </div>
        </div>
        <div class="part-6">
            <p class="title">请提交您的“企业技术”需求</p>
            <div class="form">
                <el-form :model="form" :rules="rules">
                    <el-form-item label="姓名" prop="memberName">
                        <el-input v-model="form.memberName" placeholder="请输入姓名" autocomplete="off" />
                    </el-form-item>
                    <el-form-item label="联系方式" prop="contactWay">
                        <el-input v-model="form.contactWay" placeholder="请输入联系方式" autocomplete="off" maxlength="11" />
                    </el-form-item>
                    <div class="form-code">
                        <el-form-item>
                            <el-input v-model="form.code" autocomplete="off" placeholder="请输入验证码" maxlength="6"/>
                        </el-form-item>
                        <el-button v-if="showCode" type="primary" @click="getPhoneCode">发送验证码</el-button>
                        <span v-else style="color: #B0B5C0">{{count}}S</span>
                    </div>
                    <el-form-item label="企业名称"  prop="contactWay">
                        <el-input v-model="form.companyName" placeholder="请输入企业名称" autocomplete="off" />
                    </el-form-item>
                    <el-form-item label="您的需求" class="form-textarea">
                        <el-input v-model="form.demandContent" resize="none" placeholder="请输入您的需求" autocomplete="off" type="textarea" />
                    </el-form-item>
                    <el-form-item  class="submit-button">
                        <el-button type="primary" @click="submit">立即定制解决方案</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import { getToken } from '@/utils/auth'
import { ElMessage } from 'element-plus'
import { customService, getPhoneCode } from "@/api/user";  // 获取用户信息，更新名字头像
// import {environment} from "@/utils/consts";
import { enterpriseSolutionsApi } from "@/api/activity"

export default {
    data(){
        return{
            rules:{
               memberName:[{ required: true, message: '请输入姓名', trigger: 'blur' },],
               contactWay:[{ required: true, message: '请输入联系方式', trigger: 'blur' },],
               companyName:[{ required: true, message: '请输入企业名称', trigger: 'blur' },] 
            },
            imgLoaded: false,   //背景加载
            showCustom: false,  // 定制弹窗
            customName: '', // 姓名
            customPhone: '', // 电话
            dialogFormVisible: false,
            form: {},
            count: 60,
            showCode: true, //是否显示倒计时
            timer: null,
        }
    },
    methods:{
        getPhoneCode () {
            const TIME_COUNT = 60;
              var reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
            console.log(reg.test(this.form.contactWay))
            if(reg.test(this.form.contactWay)==false){
                ElMessage.warning('您输入的手机号码格式不正确，请重新输入');
                return
            }
            getPhoneCode({phone: this.form.contactWay}).then(() => {
                if (!this.timer) {
                    this.count = TIME_COUNT;
                    this.showCode = false;
                    this.timer = setInterval(() => {
                        if (this.count > 0 && this.count <= TIME_COUNT) {
                            this.count --;
                        } else {
                            this.showCode = true;
                            clearInterval(this.timer)
                            this.timer = null
                        }
                    }, 1000)
                }
                ElMessage.success('发送成功')
            }).catch(err => {
                ElMessage.error({ message: err.message})
            })
        },
        submit() {
            const { memberName, contactWay, code,  companyName } = this.form
            if(!memberName) {
                ElMessage.warning('名字不能为空')
                return
            }
            if(!contactWay) {
                ElMessage.warning('电话不能为空')
                return
            }
            if(!code) {
                ElMessage.warning('验证码不能为空')
                return
            }
            if(!companyName) {
                ElMessage.warning('企业名称不能为空')
                return
            }
            enterpriseSolutionsApi(this.form).then(()=>{
                ElMessage.success('提交成功')
                this.dialogFormVisible = false
                this.form = {}
            })

        },
        async commitInfo(){ // 提交名单
            if(this.customPhone.length !== 11){
                ElMessage.warning('请输入正确格式的手机号码!')
                return
            }
            let res = await customService(this.customName,this.customPhone)
            if(res.code !== "Sx200") return
            ElMessage.success('提交成功，请耐心等候客服与您联系!')
            this.customName = ''
            this.customPhone = '' // 提交后清空
            this.showCustom = false
        },
        inputChange(e){  // 只能输入数字
            let a = e.key.replace(/[^\d]/g, "");
            if (!a && e.keyCode !== 8) {
                e.preventDefault();
            }
        },
        imgLoad(){
            this.imgLoaded = true
        },
        checkLogin(){
            if(getToken() === ""){
                ElMessage.warning('你尚未登录!')
                this.$router.push({path: '/login',query: {redirect: '/industrial-garden'}})
            }
            else{
                // uat: 185580801622017 线上: 180517873975297
                // this.$store.dispatch("message/startCommunication", {id: 185580801622017,serverId:'',title:'',quickChat:false, unlimited: true})  // 在线联系不需要需求id、标题及承接需求quickChat
                this.showCustom = true
            }
        },
        goToQuestion(){
            window.open('https://www.wjx.top/vj/OtsAUv5.aspx','_blank')
        },
    }
}
</script>

<style scoped lang="scss">
.pos {
    position: relative;
    display: flex;
    align-items: center;
    span {
        color: #F57B15;
        font-size: 18px;
        position: absolute;
        right: 15px;
        cursor: pointer;
    }
}
img{
    object-fit: cover;
}
.container{
    width: 100%;
    min-width: 1200px;
    margin-top: 0;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .part-1{
        width: 100%;
        height: 600px;
        position: relative;
        background-image: url('../../assets/images/project/company/part-1.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        .bottom-1{
            position: absolute;
            left: 20%;
            bottom: 96px;
            width: 401px;
            height: 87px;
            background-image: url('../../assets/images/project/company/up-1.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #111111;
            line-height: 28px;
            box-sizing: border-box;
            padding-top: 40px;
        }
        .bottom-2{
            position: absolute;
            right: 26%;
            bottom: 122px;
            width: 239px;
            height: 86px;
            background-image: url('../../assets/images/project/company/up-2.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #111111;
            line-height: 30px;
            box-sizing: border-box;
            padding-top: 37px;
        }
        .right{
            position: absolute;
            right: 6%;
            top: 212px;
            width: 321px;
            height: 74px;
            background-image: url('../../assets/images/project/company/left.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #111111;
            line-height: 74px;
            box-sizing: border-box;
            padding-left: 42px;
        }
        .left{
            position: absolute;
            left: 8.75%;
            top: 143px;
            width: 382px;
            height: 82px;
            background-image: url('../../assets/images/project/company/right.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            font-size: 26px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #111111;
            line-height: 82px;
            box-sizing: border-box;
            padding-left: 15px;
        }
        .top{
            position: absolute;
            left: 45.4%;
            top: 94px;
            width: 380px;
            height: 77px;
            background-image: url('../../assets/images/project/company/down.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #111111;
            line-height: 25px;
            letter-spacing: 1px;
            padding-top: 24px;
        }
        .center-word{
            width: 448px;
            height: 118px;
            font-size: 32px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #111111;
            line-height: 59px;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -69px;
            margin-left: -224px;
        }
    }
    .part-2{
        height: 729px;
        width: 100%;
        background-color: #ffffff;
        .title-1{
            width: 100%;
            text-align: center;
            font-size: 32px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #111111;
            line-height: 45px;
            margin-top: 82px;
        }
        .title-2{
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #999999;
            line-height: 25px;
            margin-top: 19px;
            margin-bottom: 110px;
            width: 100%;
            text-align: center;
        }
        .left{
            width: 510px;
            .num{
                width: 160px;
                margin-bottom: 73px;
                .num-pic{
                    margin-bottom: 21px;
                }
                .num-word{
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 16px;
                }
            }
            .right-line{
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    top: 10px;
                    right: 0;
                    width: 1px;
                    height: 43px;
                    background: #D8D8D8;
                }
            }
        }
        .right{
            width: 683px;
            height: 347px;
        }
    }
    .part-3{
        width: 100%;
        height: 791px;
        background-color: #F4F7FC;
        .title{
            width: 100%;
            text-align: center;
            font-size: 32px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #111111;
            line-height: 45px;
            margin-top: 67px;
            margin-bottom: 53px;
        }
    }
    .part-4{
        width: 100%;
        position: relative;
        .img{
            width: 100%;
        }
        .title{
            position: absolute;
            top: 62px;
            width: 100%;
            text-align: center;
            font-size: 32px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #111111;
            line-height: 45px;
        }
    }
    .part-5{
        width: 100%;
        height: 733px;
        background-color: #F4F7FC;
        .title{
            width: 100%;
            text-align: center;
            font-size: 32px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #111111;
            line-height: 45px;
            margin-top: 75px;
            margin-bottom: 61px;
        }
        .part-5-line{
            width: 1200px;
            margin: 0 auto;
            justify-content: space-between;
            .option{
                width: 276px;
                height: 180px;
                background: linear-gradient(47deg, #FFFFFF 0%, #F8F9FB 52%, #F4F2F9 69%, #EEF5F9 100%, #F8F9FB 100%);
                box-shadow: 4px 7px 18px 0px #EBF1FC;
                border-radius: 8px;
                border: 4px solid #FFFFFF;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-bottom: 89px;
                p{
                    margin-top: 13px;
                    font-size: 18px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #111111;
                    line-height: 25px;
                }
            }
        }
    }
    .part-6{
        width: 100%;
        padding-bottom: 102px;
        background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/06/11/1689057048588474');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        .title{
            width: 100%;
            margin: 56px 0 51px;
            text-align: center;
            font-size: 32px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 45px;
        }
    }
    .download{
        width: 100%;
        margin-top: 21px;
        margin-bottom: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        p{
            font-size: 62px;
            font-family: MicrosoftYaHei;
            font-weight: bold;
            font-style: italic;
            color: #333333;
        }
        .title{
            font-size: 62px;
            font-family: DOUYU;
            font-weight: 600;
            color: #333333;
            margin-top: 10px;
        }
        .codes{
            display: flex;
            justify-content: center;
            width: 75%;
            padding: 0 12.5%;
            margin: 50px 0;
            .code{
                width: 10%;
                padding: 10px 10px 5px;
                background: #FBCC31;
                text-align: center;
                img{
                    width: 100%;
                }
                color: #ffffff;
                font-size: 16px;
                font-weight: 600;
            }
        }
    }
    .form{
        margin: 0px auto;
        width: 1200px;
        background: #FFFFFF;
        border-radius: 8px;
        padding: 37px 0 49px;
        p{
            font-size: 44px;
            line-height: 44px;
            margin: 45px 0px;
        }
        .el-form{
            padding: 0px 20px;
        }
        .form-code{
            display: flex;
            justify-content: space-between;
        }
        button{
            background: $--color-theme;
            border: none;
            height: 40px;
            border-radius: 4px;
        }
        :deep(.el-form){
            width: 362px;
            margin: 0 auto;
            .submit-button{
                width: 162px !important;
                margin: 0 auto !important;
            }
        }
        :deep(.el-form-item__label){
            font-size: 14px;
            color: #111111;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            line-height: 14px;
            margin-bottom: 12px;
        }
        :deep(.el-input__inner){
            background: #ffffff;
        }
        :deep(.el-textarea__inner){
            background: #ffffff;
            height: 100%;
        }
        :deep(.el-textarea){
            height: 100px;
        }
    }
    .img-padding{
        padding: 0px 360px;
    }
}
.custom-dialog{
    border-radius: 15px;
    .custom-top{
      img{
        width: 1072px;
        margin-top: -100px;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      .title{
        font-size: 40px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        color: #0E0E0E;
      }
      .container-dialog{
        width: 50%;
        margin: 20px;
        .infos{
          font-size: 28px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #4B2F08;
        }
        input{
          background: #FFFEFB;
          border: 2px solid #FFE8BD;
          border-radius: 36px;
          margin-top: 10px;
          width: 100%;
          height: 36px;
          font-size: 24px;
          text-align: center;
        }
      }
      .commit-button{
        width: 460px;
        height: 40px;
        background: #EBD692;
        border-radius: 36px;
        font-size: 30px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #563B15;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        cursor: pointer;
      }
    }
}
</style>