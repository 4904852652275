<template>
  <div class="content">
    <div class="top">
      <img src="@/assets/images/incubator/vip/title.png"/>
      <p>科技成果孵化、早起项目孵化、成熟项目赋能</p>
    </div>
    <div class="part">
      <div class="title" style="margin: 66px 0 44px;">
        会员服务
      </div>
      <div class="table">
        <div class="left">
          <div class="table-t">
            服务亮点
          </div>
          <div class="t-l" style="border-top: 1px solid #E8E8E8;">
            专业商业计划书梳理<br/>
            让您的项目更具吸引力，无限次修改直至完美
          </div>
          <div class="t-d">
            每月定期路演机会<br/>
            直面投资人，展现项目价值，赢得资金支持
          </div>
          <div class="t-l">
            独家投资人资源<br/>
            获取顶级投资机构联系方式，融资对接更精准
          </div>
          <div class="t-d">
            赠送科企投条融媒体服务<br/>
            提升品牌曝光，传播企业声音，增强市场影响力
          </div>
          <div class="t-l">
            专属管家全程指导<br/>
            专属管家全程陪伴，提供个性化指导与建议
          </div>
          <div class="t-d">
            SEI Club活动参与资格<br/>
            与高端企业家、投资人、科研专家交流，拓展人脉资源
          </div>
          <div class="t-l">
            产业园科技招商优先权<br/>
            享受政策优惠，快速入驻优质产业园
          </div>
          <div class="t-d">
            区域数字科创服务支持<br/>
            精准对接区域资源，助力企业地域性发展
          </div>
        </div>
        <div class="middle">
          <div class="table-t">
            <p class="p-0">科创启航套餐</p>
            <p class="p-1">¥<span>{{ product0.marketPrice }}</span>/年</p>
            <button class="b-0" @click="clickPay(0)">立即下单</button>
          </div>
          <div class="t-l">
            1个月内，无限次修改
          </div>
          <div class="t-d">
            1家/月
          </div>
          <div class="t-l">
            25位/月
          </div>
          <div class="t-d">
            1万元版本
          </div>
          <div class="t-l">
            <img src="@/assets/images/incubator/vip/middle.png" />
          </div>
          <div class="t-d">
          </div>
          <div class="t-l">
          </div>
          <div class="t-d">
          </div>
        </div>
        <div class="right">
          <div class="table-t">
            <p class="p-0">科创领航套餐</p>
            <p class="p-1">¥<span>{{ product1.marketPrice }}</span>/年</p>
            <button class="b-0" @click="clickPay(1)">立即下单</button>
          </div>
          <div class="t-l" style="border-top: 1px solid #FEE1B9;">
            2个月内，无限次修改
          </div>
          <div class="t-d">
            3家/月
          </div>
          <div class="t-l">
            60位/月
          </div>
          <div class="t-d">
            3万元版本
          </div>
          <div class="t-l">
            <img src="@/assets/images/incubator/vip/t-right.png" />
          </div>
          <div class="t-d">
            <img src="@/assets/images/incubator/vip/t-right.png" />
          </div>
          <div class="t-l">
            <img src="@/assets/images/incubator/vip/t-right.png" />
          </div>
          <div class="t-d">
            <img src="@/assets/images/incubator/vip/t-right.png" />
          </div>
        </div>
      </div>
      <div class="read-line">
        <img src="@/assets/images/incubator/vip/correct.png" v-if="!read" @click="read = true"/>
        <img src="@/assets/images/incubator/vip/correct-0.png" v-else @click="read = false"/>
        <p class="p-0" @click="read = !read">我已阅读服务合同并同意</p>
        <button class="b-0" @click="showRead = true">
          服务合同
        </button>
      </div>
    </div>
    <div class="part">
      <div class="title" style="margin: 86px 0 58px;">
        会员单向增值服务
      </div>
      <div class="sections">
        <div class="section">
          <div class="w">
            <img src="@/assets/images/incubator/vip/i-0.png" style="width: 30px;height: 36px;margin-bottom: 30px;" />
            <p class="p-0">商业计划书梳理</p>
            <p class="p-1">定制商业计划书，内容精准、策略明<br/>确，让您的项目更具吸引力</p>
            <p class="p-2">¥<span>5800</span>/月</p>
          </div>
        </div>
        <div class="section">
          <div class="w">
            <img src="@/assets/images/incubator/vip/i-1.png" style="width: 40px;height: 30px;margin-bottom: 36px;" />
            <p class="p-0">线上项目路演机会</p>
            <p class="p-1">通过线上与投资人实时互动，展示项<br/>目价值,增加融资机会</p>
            <p class="p-2">¥<span>1800</span>/次</p>
          </div>
        </div>
        <div class="section">
          <div class="w">
            <img src="@/assets/images/incubator/vip/i-2.png" style="width: 49px;height: 40px;margin-bottom: 26px;" />
            <p class="p-0">投资人私密对接会</p>
            <p class="p-1">与投资人面对面深入交流，提高融资<br/>对接的效率和成功率</p>
            <p class="p-2">¥<span>6800</span>/次</p>
          </div>
        </div>
        <div class="section">
          <div class="w">
            <img src="@/assets/images/incubator/vip/i-3.png" style="width: 35px;height: 35px;margin-bottom: 31px;" />
            <p class="p-0">投资人联系方式</p>
            <p class="p-1">提供独家投资人联系方式，让您直接<br/>接触到顶级投资机构</p>
            <p class="p-2">¥<span>1800</span>/10位</p>
          </div>
        </div>
        <div class="section">
          <div class="w">
            <img src="@/assets/images/incubator/vip/i-4.png" style="width: 35px;height: 35px;margin-bottom: 31px;" />
            <p class="p-0">融媒体品牌曝光</p>
            <p class="p-1">利用融媒体平台广泛宣传您的企业，<br/>提高市场知名度和影响力</p>
            <p class="p-2">¥<span>11800</span>/次</p>
          </div>
        </div>
        <div class="section">
          <div class="w">
            <img src="@/assets/images/incubator/vip/i-5.png" style="width: 35px;height: 35px;margin-bottom: 31px;" />
            <p class="p-0">SEI Club活动邀请</p>
            <p class="p-1">SEI Club高端活动邀请函，与业内领<br/>袖面对面，拓展人脉资源</p>
            <p class="p-2">¥<span>8800</span>/次</p>
          </div>
        </div>
        <div class="form">
          <p class="f-t">蓝珊瑚单向服务定制申请</p>
          <div class="input-line">
            <div class="form-item"><span>*</span>联系人：</div>
            <input v-model="form.name" class="input" />
          </div>
          <div class="input-line">
            <div class="form-item"><span>*</span>联系电话：</div>
            <input v-model="form.phone" :maxlength="11" @input="handelNumber" class="input" />
          </div>
          <button class="b-0" @click="submit">立即定制</button>
        </div>
      </div>
    </div>
    <div v-if="showPay">
      <PayWindow :product="product" @closeWindow="closeWindow" :new-quantity="1" :noSuccessPop="true"></PayWindow>
    </div>
    <el-dialog v-model="showRead" :show-close="false" :close-on-press-escape="false" :top="'10vh'" :width="'75%'" :custom-class="'dialog-no-title dialog-transparent'">
      <object data="https://front-file-1304545655.cos.ap-shanghai.myqcloud.com/2024/02/26/1711432674155294/科企岛-蓝珊瑚创投通服务合同.pdf" type="application/pdf" width="100%" style="height: 80vh;">
        <p>此浏览器不支持 PDF 预览，请点击<a href="https://front-file-1304545655.cos.ap-shanghai.myqcloud.com/2024/02/26/1711432674155294/科企岛-蓝珊瑚创投通服务合同.pdf">这里</a>下载 PDF。</p>
      </object>
    </el-dialog>
  </div>
</template>

<script>
import PayWindow from '@/components/PayWindow.vue';
import { ElMessage } from 'element-plus';
import { activityRegister } from '@/api/activity';
import { allProducts } from "@/api/product";

export default{
  components:{
    PayWindow,
  },
  data(){
    return{
      read: false,
      showRead: false,
      showPay: false,
      product: {},
      products: [],
      form:{
        phone: '',
        name: '',
      }
    }
  },
  computed:{
    product0(){
      return this.products.length >= 2 ? this.products[0] : '26800'
    },
    product1(){
      return this.products.length >= 2 ? this.products[1] : '59800'
    },
  },
  mounted(){
    this.getAllProducts()
  },
  methods:{
    getAllProducts(){
      allProducts().then((res)=>{
        this.products = res.data.result.filter(item=>item.name.includes('蓝珊瑚创投通')).sort((a,b)=>a.marketPrice - b.marketPrice)
      })
    },
    submit(){
      if(!this.form.name){
        ElMessage.warning('请输入联系人')
        return
      }
      else if(!this.form.phone){
        ElMessage.warning('请输入电话')
        return
      }
      const param = {
        activityName: '蓝珊瑚单向服务定制申请',
        activityType: '38',
        code: '123',
        isCreateMember: false,
        contactWay: this.form.phone,
        companyName: '',
        name: this.form.name,
        jsonStr: JSON.stringify({}),
      }
      activityRegister(param).then(res=>{
        if (res.code === "Sx200") {
          ElMessage.success('定制申请成功！')
        }
      })
    },
    clickPay(type){
      if(!localStorage.getItem('userInfos')){
        this.$router.push({
          path: '/login',
          query:{
            redirectFull: JSON.stringify(window.location.href)
          }
        })
        return
      }
      if(!this.read || this.products.length < 2){
        ElMessage.warning('请阅读合同并勾选同意!')
        return
      }
      this.showPay = true
      this.product = this.products[type]
    },
    closeWindow(){
      this.showPay = false
      this.product = {}
    },
    handelNumber(){
      let pattern = new RegExp("^[0-9]|-$")
      this.form.phone = Array.from(this.form.phone).filter(v => pattern.test(v)).join("")
    },
  }
}
</script>

<style lang="scss" scoped>
.content{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 124px;
  .top{
    width: 100%;
    height: 320px;
    background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/02/20/1710925068270689');
    background-size: 100% 320px;
    background-repeat: no-repeat;
    box-sizing: border-box;
    padding-top: 86px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
      width: 471px;
      height: 88px;
      margin-bottom: 18px;
    }
    p{
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 22px;
      color: #EDC6A4;
      line-height: 30px;
      letter-spacing: 1px;
    }
  }
  .part{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title{
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 32px;
      color: #664624;
      line-height: 45px;
      letter-spacing: 1px;
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 20px;
      position: relative;
      &::before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 54px;
        height: 38px;
        background-image: url('../../assets/images/incubator/vip/left.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: -1;
      }
      &::after{
        z-index: -1;
        content: '';
        position: absolute;
        right: 0;
        bottom: -5px;
        width: 54px;
        height: 38px;
        background-image: url('../../assets/images/incubator/vip/right.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
    .table{
      display: flex;
      .left{
        width: 482px;
        .table-t{
          border-top: 2px solid #D5D5D5;
          width: 100%;
          height: 198px;
          background: #FAFAFA;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 20px;
          color: #333333;
          line-height: 28px;
        }
        .t-l{
          background-color: #ffffff;
          width: 100%;
          height: 80px;
          box-sizing: border-box;
          border-left: 1px solid #E8E8E8;
          border-bottom: 1px solid #E8E8E8;
          padding-top: 20px;
          padding-left: 62px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #111111;
          line-height: 20px;
        }
        .t-d{
          background: #FAFAFA;
          width: 100%;
          height: 80px;
          box-sizing: border-box;
          border-left: 1px solid #E8E8E8;
          border-bottom: 1px solid #E8E8E8;
          padding-top: 20px;
          padding-left: 62px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #111111;
          line-height: 20px;
        }
      }
      .middle{
        width: 312px;
        .table-t{
          border-top: 2px solid $--color-theme;
          width: 100%;
          height: 198px;
          background: linear-gradient( 136deg, #EEF3FF 0%, #D4DFFF 82%, #CEDAFF 100%);
          display: flex;
          flex-direction: column;
          align-items: center;
          .p-0{
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 20px;
            color: #2D498C;
            line-height: 28px;
            margin: 38px 0 15px;
          }
          .p-1{
            height: 42px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #2D498C;
            margin-bottom: 27px;
            span{
              font-size: 30px;
              font-weight: 600;
            }
          }
          .b-0{
            width: 170px;
            height: 36px;
            background: linear-gradient( 113deg, #B6D5FF 0%, #2280FF 100%);
            border-radius: 4px;
            border: none;
            cursor: pointer;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #FFFFFF;
          }
        }
        .t-l{
          background-color: #ffffff;
          width: 100%;
          height: 80px;
          box-sizing: border-box;
          border-left: 1px solid #E8E8E8;
          border-bottom: 1px solid #E8E8E8;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .t-d{
          background: #FAFAFA;
          width: 100%;
          height: 80px;
          box-sizing: border-box;
          border-left: 1px solid #E8E8E8;
          border-bottom: 1px solid #E8E8E8;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        img{
          width: 15px;
          height: 12px;
        }
      }
      .right{
        width: 312px;
        .table-t{
          border-top: 2px solid #F8B338;
          width: 100%;
          height: 198px;
          background: linear-gradient( 132deg, #FFFBF6 0%, #FFE8CD 100%);
          display: flex;
          flex-direction: column;
          align-items: center;
          .p-0{
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 20px;
            color: #664624;
            line-height: 28px;
            margin: 38px 0 15px;
          }
          .p-1{
            height: 42px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #664624;
            margin-bottom: 27px;
            span{
              font-size: 30px;
              font-weight: 600;
            }
          }
          .b-0{
            width: 170px;
            height: 36px;
            background: linear-gradient( 113deg, #FFE4B7 0%, #F8B338 100%);
            border-radius: 4px;
            border: none;
            cursor: pointer;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #664624;
          }
        }
        .t-l{
          background-color: #FFFDF8;
          width: 100%;
          height: 80px;
          box-sizing: border-box;
          border-left: 1px solid #FEE1B9;
          border-bottom: 1px solid #FEE1B9;
          border-right: 1px solid #FEE1B9;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .t-d{
          background: #FFF6E5;
          width: 100%;
          height: 80px;
          box-sizing: border-box;
          border-left: 1px solid #FEE1B9;
          border-bottom: 1px solid #FEE1B9;
          border-right: 1px solid #FEE1B9;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        img{
          width: 15px;
          height: 12px;
        }
      }
    }
    .read-line{
      width: 1106px;
      display: flex;
      align-items: center;
      margin-top: 16px;
      img{
        width: 14px;
        height: 14px;
        margin-right: 5px;
        cursor: pointer;
      }
      .p-0{
        cursor: pointer;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #111111;
        line-height: 20px;
        margin-right: 12px;
      }
      .b-0{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #2381FF;
        width: 73px;
        cursor: pointer;
        background: #EEF5FF;
        border-radius: 4px;
        border: 1px solid #2381FF;
      }
    }
    .sections{
      width: 1200px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .section{
        width: 282px;
        height: 240px;
        background: linear-gradient( 180deg, #FCF9F6 0%, #FCF7F0 100%);
        box-sizing: border-box;
        padding: 14px 16px;
        border-radius: 4px;
        margin-bottom: 26px;
        .w{
          width: 250px;
          height: 212px;
          border: 1px solid #FFEDDD;
          display: flex;
          flex-direction: column;
          align-items: center;
          box-sizing: border-box;
          padding-top: 18px;
          .p-0{
            font-size: 16px;
            color: #664624;
            margin-bottom: 10px;
          }
          .p-1{
            font-size: 12px;
            color: #664624;
            margin-bottom: 20px;
            text-align: center;
          }
          .p-2{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #664624;
            span{
              font-size: 22px;
            }
          }
        }
      }
      .form{
        width: 590px;
        height: 240px;
        background-image: url('../../assets/images/incubator/vip/form-back.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        padding-top: 23px;
        .f-t{
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #664624;
          line-height: 25px;
          margin-bottom: 26px;
        }
        .input-line{
          display: flex;
          align-items: center;
          margin-bottom: 17px;
          .form-item{
            width: 100px;
            text-align: right;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #111111;
            line-height: 14px;
            span{
              color: #EA0D0D;
            }
          }
          .input{
            width: 384px;
            height: 36px;
            background: #FFFFFF;
            border-radius: 4px;
            border: 1px solid #D8D8D8;
            box-sizing: border-box;
            font-size: 14px;
            padding: 0 15px;
          }
        }
        .b-0{
          margin-top: 3px;
          width: 179px;
          height: 36px;
          background: linear-gradient( 113deg, #FFE4B7 0%, #F8B338 100%);
          border-radius: 4px;
          border: none;
          cursor: pointer;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #664624;
        }
      }
    }
  }
}
</style>