<template>
  <div class="content">
    <p class="title">产品版本与定价</p>
    <p class="tip">选择适合您的版本，开启科创之旅</p>
    <div class="products">
      <div class="products-container">
        <div class="product-0">
          <img src="@/assets/images/G60/announce/vip-0.png" class="icon" />
          <div class="name">
            <p class="vip-name">企业版</p>
          </div>
          <div class="window">
            <div class="vip-value">
              ￥<span>59,800</span>/年
            </div>
            <div class="button-line">
              <button class="button" @click="toVip">立即定制</button>
            </div>
            <div class="line">
              <img src="@/assets/images/G60/announce/correct-1.png" />
              <p>专家数据：全面访问专家资源，找到最佳技术解决方案</p>
            </div>
            <div class="line">
              <img src="@/assets/images/G60/announce/correct-1.png" />
              <p>创新项目：发掘海量创新项目，把握投资与合作机遇。</p>
            </div>
            <div class="line">
              <img src="@/assets/images/G60/announce/correct-1.png" />
              <p>智能推荐：个性化专家推荐，精准匹配技术需求。</p>
            </div>
            <div class="line">
              <img src="@/assets/images/G60/announce/correct-1.png" />
              <p>科技资讯：实时行业动态，助力决策先行。</p>
            </div>
            <div class="line">
              <img src="@/assets/images/G60/announce/correct-1.png" />
              <p>团队管理：高效协作工具，提升团队效能。</p>
            </div>
            <div class="line">
              <img src="@/assets/images/G60/announce/correct-1.png" />
              <p>沟通记录：建立长期合作，信赖从这里开始。</p>
            </div>
            <div class="line">
              <img src="@/assets/images/G60/announce/correct-1.png" />
              <p>远程协作：在线会议与路演，无缝远程沟通。</p>
            </div>
            <div class="line">
              <img src="@/assets/images/G60/announce/correct-1.png" />
              <p>需求管理：一站式需求发布与管理，加速创新进程。 </p>
            </div>
            <div class="line">
              <img src="@/assets/images/G60/announce/correct-1.png" />
              <p>客户经理：一对一专业服务，全程无忧支持。</p>
            </div>
            <div class="line">
              <img src="@/assets/images/G60/announce/wrong.png" />
              <p>专属定制：深度需求分析，功能模块量身定制，持续优化迭代。</p>
            </div>
            <div class="line">
              <img src="@/assets/images/G60/announce/wrong.png" />
              <p>数据洞察：定制报表与多维度分析，数据驱动决策。 </p>
            </div>
            <div class="line">
              <img src="@/assets/images/G60/announce/wrong.png" />
              <p>API 集成：无缝对接企业内部系统，实现高效协同。</p>
            </div>
            <div class="line">
              <img src="@/assets/images/G60/announce/wrong.png" />
              <p>权限管理：多层级灵活设置，确保数据安全。</p>
            </div>
            <div class="line">
              <img src="@/assets/images/G60/announce/wrong.png" />
              <p>增值服务：优先响应、定制培训、市场活动优先参与等。</p>
            </div>
          </div>
        </div>
        <div class="product-1">
          <img src="@/assets/images/G60/announce/vip-1.png" class="icon" />
          <div class="name">
            <p class="vip-name">高级定制版</p>
            <p class="vip-tip">具体报价根据定制需求议定</p>
          </div>
          <div class="window">
            <div class="vip-value">
              ￥<span>99,800</span>/年
            </div>
            <div class="button-line">
              <button class="button" @click="openFormWindow">立即咨询</button>
            </div>
            <div class="line">
              <img src="@/assets/images/G60/announce/correct-1.png" />
              <p>专家数据：全面访问专家资源，找到最佳技术解决方案</p>
            </div>
            <div class="line">
              <img src="@/assets/images/G60/announce/correct-1.png" />
              <p>创新项目：发掘海量创新项目，把握投资与合作机遇。</p>
            </div>
            <div class="line">
              <img src="@/assets/images/G60/announce/correct-1.png" />
              <p>智能推荐：个性化专家推荐，精准匹配技术需求。</p>
            </div>
            <div class="line">
              <img src="@/assets/images/G60/announce/correct-1.png" />
              <p>科技资讯：实时行业动态，助力决策先行。</p>
            </div>
            <div class="line">
              <img src="@/assets/images/G60/announce/correct-1.png" />
              <p>团队管理：高效协作工具，提升团队效能。</p>
            </div>
            <div class="line">
              <img src="@/assets/images/G60/announce/correct-1.png" />
              <p>沟通记录：建立长期合作，信赖从这里开始。</p>
            </div>
            <div class="line">
              <img src="@/assets/images/G60/announce/correct-1.png" />
              <p>远程协作：在线会议与路演，无缝远程沟通。</p>
            </div>
            <div class="line">
              <img src="@/assets/images/G60/announce/correct-1.png" />
              <p>需求管理：一站式需求发布与管理，加速创新进程。 </p>
            </div>
            <div class="line">
              <img src="@/assets/images/G60/announce/correct-1.png" />
              <p>客户经理：一对一专业服务，全程无忧支持。</p>
            </div>
            <div class="line">
              <img src="@/assets/images/G60/announce/correct-1.png" />
              <p>专属定制：深度需求分析，功能模块量身定制，持续优化迭代。</p>
            </div>
            <div class="line">
              <img src="@/assets/images/G60/announce/correct-1.png" />
              <p>数据洞察：定制报表与多维度分析，数据驱动决策。 </p>
            </div>
            <div class="line">
              <img src="@/assets/images/G60/announce/correct-1.png" />
              <p>API 集成：无缝对接企业内部系统，实现高效协同。</p>
            </div>
            <div class="line">
              <img src="@/assets/images/G60/announce/correct-1.png" />
              <p>权限管理：多层级灵活设置，确保数据安全。</p>
            </div>
            <div class="line">
              <img src="@/assets/images/G60/announce/correct-1.png" />
              <p>增值服务：优先响应、定制培训、市场活动优先参与等。</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default{
  methods:{
    openFormWindow(){
      this.$store.dispatch('G60/openVipForm')
    },
    toVip(){
      window.open('https://www.keqidao.com/vip')
    },
  }
}
</script>

<style lang="scss" scoped>
.content{
  width: 100%;
  padding-top: 90px;
  .title{
    font-size: 32px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #111111;
    line-height: 45px;
    width: 100%;
    text-align: center;
    margin-bottom: 6px;
  }
  .tip{
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    margin-bottom: 37px;
    width: 100%;
    text-align: center;
  }
  .products{
    width: 100%;
    box-sizing: border-box;
    background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/00/22/1705906749805601');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 69px;
    padding-bottom: 53px;
    display: flex;
    justify-content: center;
    .products-container{
      width: 1200px;
      display: flex;
      justify-content: center;
    }
    .product-0{
      width: 528px;
      box-sizing: border-box;
      padding: 33px 0 39px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/00/22/1705906759306805');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .icon{
        width: 100px;
        height: 91px;
      }
      .name{
        height: 77px;
        width: 100%;
        .vip-name{
          width: 100%;
          text-align: center;
          font-size: 30px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #775118;
          line-height: 42px;
        }
      }
      .window{
        width: 458px;
        background: #FFFFFF;
        box-shadow: 0px 1px 17px 0px rgba(211,211,211,0.28);
        border-radius: 10px;
        box-sizing: border-box;
        padding-top: 34px;
        padding-bottom: 38px;
        .vip-value{
          width: 100%;
          text-align: center;
          font-size: 20px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #6A440B;
          line-height: 28px;
          height: 70px;
          span{
            font-size: 50px;
          }
        }
        .button-line{
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 15px;
          padding-bottom: 40px;
          .button{
            width: 252px;
            height: 49px;
            background: #E5B167;
            border-radius: 6px;
            border: none;
            cursor: pointer;
            font-size: 16px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
          }
        }
        .line{
          display: flex;
          width: 100%;
          box-sizing: border-box;
          padding: 11px;
          padding-left: 40px;
          padding-right: 0;
          align-items: center;
          img{
            width: 12px;
            height: 12px;
            margin-right: 17px;
          }
          p{
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #111111;
            line-height: 20px;
            white-space: nowrap;
          }
        }
      }
    }
    .product-1{
      margin-left: 35px;
      width: 528px;
      box-sizing: border-box;
      padding: 33px 0 39px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/00/22/1705906772130863');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .icon{
        width: 100px;
        height: 91px;
      }
      .name{
        height: 77px;
        width: 100%;
        .vip-name{
          width: 100%;
          text-align: center;
          font-size: 30px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 42px;
        }
        .vip-tip{
          width: 100%;
          text-align: center;
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 20px;
        }
      }
      .window{
        width: 458px;
        background: #FFFFFF;
        box-shadow: 0px 1px 17px 0px rgba(211,211,211,0.28);
        border-radius: 10px;
        box-sizing: border-box;
        padding-top: 34px;
        padding-bottom: 38px;
        .vip-value{
          width: 100%;
          text-align: center;
          font-size: 20px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #393939;
          line-height: 28px;
          height: 70px;
          span{
            font-size: 50px;
          }
        }
        .button-line{
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 15px;
          padding-bottom: 40px;
          .button{
            width: 252px;
            height: 49px;
            background: #393939;
            border-radius: 6px;
            border: none;
            cursor: pointer;
            font-size: 16px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
          }
        }
        .line{
          display: flex;
          width: 100%;
          box-sizing: border-box;
          padding: 11px;
          padding-left: 40px;
          padding-right: 0;
          align-items: center;
          img{
            width: 12px;
            height: 12px;
            margin-right: 17px;
          }
          p{
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #111111;
            line-height: 20px;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
</style>