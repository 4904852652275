<template>
  <div class="content">
    <p class="title">数字科创中心的价值</p>
    <p class="tip">最小的投入实现最大化的科技创新效益</p>
    <div class="bottom">
      <div class="left">
        <div class="line" style="margin-bottom: 38px;">
          <p class="p-0">降低研发成本</p>
          <p class="p-1">数字科创中心通过资源共享和智能匹配机制，减少了企业在设备和人才方面的冗余投入，从而显著降低了研发成本。</p>
        </div>
        <div class="line" style="margin-bottom: 38px;">
          <p class="p-0">加速创新进程</p>
          <p class="p-1">提供丰富的创新项目和科研专家资源，企业能够更快速地找到合适的合伙伴和技术解决方案，缩短了创新周期。</p>
        </div>
        <div class="line" style="margin-bottom: 38px;">
          <p class="p-0">提升创新质量 </p>
          <p class="p-1">借助先进的大数据和人工智能技术，数字科创中心能够确保推荐和匹配的准确性和高效性，从而提高了创新成果的质量。</p>
        </div>
        <div class="line">
          <p class="p-0">增强企业竞争力</p>
          <p class="p-1">通过降低成本、加速创新进程和提升创新质量，数字科创中心最终帮助企业在激烈的市场竞争中保持领先地位，实现可持续发展。</p>
        </div>
      </div>
      <div class="right">
        <img src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/00/22/1705903920948736" class="big" />
        <div class="circle back-0">
          <p>提升<br/>创新质量</p>
        </div>
        <div class="circle back-1">
          <p>降低<br/>研发成本</p>
        </div>
        <div class="circle back-2">
          <p>增强企业<br/>竞争力</p>
        </div>
        <div class="circle back-3">
          <p>加速<br/>创新进程</p>
        </div>
      </div>
    </div>
    <div class="values">
      <div class="value">
        <div class="imgs">
          <img src="@/assets/images/G60/announce/arrow-0.png" class="arrow" />
          <img src="@/assets/images/G60/announce/p-0.png" style="width: 72px;height: 33px;" />
        </div>
        <p>成本节约</p>
      </div>
      <div class="value">
        <div class="imgs">
          <img src="@/assets/images/G60/announce/arrow-1.png" class="arrow" />
          <img src="@/assets/images/G60/announce/p-1.png" style="width: 38px;height: 33px;" />
        </div>
        <p>效益提升</p>
      </div>
      <div class="value">
        <div class="imgs">
          <img src="@/assets/images/G60/announce/arrow-2.png" class="arrow" />
          <img src="@/assets/images/G60/announce/p-2.png" style="width: 72px;height: 33px;" />
        </div>
        <p>时间缩短</p>
      </div>
    </div>
    <p class="value-tip">*注：以上数据仅供参考，实际效果因企业规模、行业特点、团队能力等因素而异。</p>
    <div class="tables">
      <div class="left">
        <p class="tab-title">数字科创中心和传统方式的综合效益对比</p>
        <div class="tab-line">
          <div class="l has-back p-0">
            对比维度
          </div>
          <div class="m has-back p-0">
            传统方式
          </div>
          <div class="r has-back p-0">
            数字科创中心
          </div>
        </div>
        <div class="tab-line">
          <div class="f dark p-0">
            成本投入
          </div>
        </div>
        <div class="tab-line">
          <div class="l p-0">
            初始建设/人才成本
          </div>
          <div class="m p-1">
            高昂
          </div>
          <div class="r p-0">
            较低
          </div>
        </div>
        <div class="tab-line">
          <div class="l p-0 dark">
            运营成本
          </div>
          <div class="m p-1 dark">
            持续且较高
          </div>
          <div class="r p-0 dark">
            较低
          </div>
        </div>
        <div class="tab-line">
          <div class="l p-0">
            资金占用
          </div>
          <div class="m p-1">
            长期且大量
          </div>
          <div class="r p-0">
            短期且较少
          </div>
        </div>
        <div class="tab-line">
          <div class="f dark p-0">
            效益产出
          </div>
        </div>
        <div class="tab-line">
          <div class="l p-0">
            创新项目数量
          </div>
          <div class="m p-1">
            受限
          </div>
          <div class="r p-0">
            高效
          </div>
        </div>
        <div class="tab-line">
          <div class="l p-0 dark">
            创新质量
          </div>
          <div class="m p-1 dark">
            不稳定
          </div>
          <div class="r p-0 dark">
            提升
          </div>
        </div>
        <div class="tab-line">
          <div class="l p-0">
            市场响应速度
          </div>
          <div class="m p-1">
            较慢
          </div>
          <div class="r p-0">
            快速
          </div>
        </div>
        <div class="tab-line">
          <div class="l p-0 dark">
            竞争优势
          </div>
          <div class="m p-1 dark">
            有限
          </div>
          <div class="r p-0 dark">
            增强
          </div>
        </div>
        <div class="tab-line">
          <div class="f p-0">
            时间效率
          </div>
        </div>
        <div class="tab-line">
          <div class="l p-0 dark">
            项目启动时间
          </div>
          <div class="m p-1 dark">
            较长
          </div>
          <div class="r p-0 dark" >
            快速
          </div>
        </div>
        <div class="tab-line">
          <div class="l p-0">
            研发周期
          </div>
          <div class="m p-1">
            较长
          </div>
          <div class="r p-0">
            缩短
          </div>
        </div>
        <div class="tab-line">
          <div class="l p-0 dark">
            上市时间
          </div>
          <div class="m p-1 dark">
            延迟
          </div>
          <div class="r p-0 dark">
            提前
          </div>
        </div>
        <div class="flex" style="justify-content: space-between; margin-top: 16px;">
          <el-popover placement="top" :width="220" trigger="hover" content="">
            <template #reference>
              <div class="flex-cc">
              <img src="@/assets/images/G60/announce/icon-i.png" style="width: 14px;height: 14px;margin-right: 4px;" />
              <p class="remark">表格说明</p>
            </div>
            </template>
            <p class="tip-word">这个表格详细对比了数字科创中心与传统方式在成本投入、效益产出和时间效率三个主要方面的差异。它展示了数字科创中心如何通过较低的成本投入、高效的效益产出以及优化的时间效率为企业带来显著的优势。这个表格有助于企业全面了解数字科创中心的价值，并做出明智的决策。</p>
          </el-popover>
          <button class="button-tip" @click="openWindow(0)">详细版表格</button>
        </div>
      </div>
      <div class="right">
        <p class="tab-title">数字科创中心和传统方式的优势提升对比</p>
        <div class="tab-line">
          <div class="l has-back p-0">
            对比维度
          </div>
          <div class="m has-back p-0">
            传统方式
          </div>
          <div class="r has-back p-0">
            数字科创中心
          </div>
          <div class="r1 has-back p-0">
            备注说明
          </div>
        </div>
        <div class="tab-line">
          <div class="f dark p-0">
            成本节约
          </div>
        </div>
        <div class="tab-line">
          <div class="l p-0">
            初始建设/人才成本
          </div>
          <div class="m p-1">
            ———
          </div>
          <div class="r p-0">
            可节约高达90%
          </div>
          <div class="r1 p-0">
            无需大额初期投入
          </div>
        </div>
        <div class="tab-line">
          <div class="l p-0 dark">
            运营成本
          </div>
          <div class="m p-1 dark">
          </div>
          <div class="r p-0 dark">
            可节约50%-70%
          </div>
          <div class="r1 p-0 dark">
            无需承担持续费用
          </div>
        </div>
        <div class="tab-line">
          <div class="l p-0">
            总成本
          </div>
          <div class="m p-1">
          </div>
          <div class="r p-0">
            可节约60%-80%
          </div>
          <div class="r1 p-0">
            长期成本显著降低
          </div>
        </div>
        <div class="tab-line">
          <div class="f dark p-0">
            效益提升
          </div>
        </div>
        <div class="tab-line">
          <div class="l p-0">
            创新项目数量
          </div>
          <div class="m p-1">
          </div>
          <div class="r p-0">
            提升10倍
          </div>
          <div class="r1 p-0">
            通过平台资源实现
          </div>
        </div>
        <div class="tab-line">
          <div class="l p-0 dark">
            成功合作项目
          </div>
          <div class="m p-1 dark">
          </div>
          <div class="r p-0 dark">
            提升3-5倍
          </div>
          <div class="r1 p-0 dark">
            智能匹配提高质量
          </div>
        </div>
        <div class="tab-line">
          <div class="l p-0">
            市场响应速度
          </div>
          <div class="m p-1">
          </div>
          <div class="r p-0 w">
            速度提升5倍,<br/>
            响应时间缩短70%
          </div>
          <div class="r1 p-0">
            实时数据支持加速决策
          </div>
        </div>
        <div class="tab-line">
          <div class="l p-0 dark">
            竞争优势
          </div>
          <div class="m p-1 dark">
          </div>
          <div class="r p-0 dark">
            指标提升2-3倍
          </div>
          <div class="r1 p-0 dark">
            优化创新流程提升地位
          </div>
        </div>
        <div class="tab-line">
          <div class="f p-0">
            时间缩短
          </div>
        </div>
        <div class="tab-line">
          <div class="l p-0 dark">
            项目启动时间
          </div>
          <div class="m p-1 dark">
          </div>
          <div class="r p-0 dark">
            缩短50%-70%
          </div>
          <div class="r1 p-0 dark">
            即买即用，无需等待
          </div>
        </div>
        <div class="tab-line">
          <div class="l p-0">
            研发周期
          </div>
          <div class="m p-1">
          </div>
          <div class="r p-0 w">
            缩短30%-50%
          </div>
          <div class="r1 p-0">
            智能匹配提高研发效率
          </div>
        </div>
        <div class="tab-line">
          <div class="l p-0 dark">
            上市时间
          </div>
          <div class="m p-1 dark">
          </div>
          <div class="r p-0 dark">
            提前2-4个月
          </div>
          <div class="r1 p-0 dark">
            高效流程助力产品上市
          </div>
        </div>
        <div class="flex" style="justify-content: space-between; margin-top: 16px;">
          <el-popover placement="top" :width="220" trigger="hover" content="">
            <template #reference>
              <div class="flex-cc">
              <img src="@/assets/images/G60/announce/icon-i.png" style="width: 14px;height: 14px;margin-right: 4px;" />
              <p class="remark">表格说明</p>
            </div>
            </template>
            <p class="tip-word">这个表格专注于数字科创中心与传统方式在成本节约和效益提升方面的对比。它详细展示了数字科创中心如何显著节约初始建设、运营和总体成本，并通过提升创新项目数量、成功合作项目、市场响应速度和竞争优势等方面带来显著的效益提升。此外，表格还强调了数字科创中心在缩短项目启动时间、研发周期和产品上市时间方面的优势。这个表格有助于企业深入了解数字科创中心在成本节约和效益提升方面的潜力，并做出明智的商业决策。</p>
          </el-popover>
          <button class="button-tip" @click="openWindow(1)">详细版表格</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default{
  methods:{
    openWindow(type){
      this.$store.dispatch('G60/openTable',type)
    },
  }
}
</script>

<style lang="scss" scoped>
.content{
  width: 100%;
  padding-top: 90px;
  .title{
    font-size: 32px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #111111;
    line-height: 45px;
    margin-bottom: 5px;
    width: 100%;
    text-align: center;
  }
  .tip{
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    color: #999999;
    line-height: 20px;
    margin-bottom: 80px;
    width: 100%;
    text-align: center;
  }
  .bottom{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 45px;
    border-bottom: 1px solid #EBEBEB;
    .left{
      padding-top: 20px;
      width: 478px;
      .line{
        width: 100%;
        box-sizing: border-box;
        padding-left: 36px;
        .p-0{
          font-size: 18px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          color: #111111;
          line-height: 18px;
          margin-bottom: 14px;
          position: relative;
          &::before{
            content: '';
            width: 19px;
            height: 17px;
            position: absolute;
            left: -34px;
            top: 2px;
            background-image: url('../../../../assets/images/G60/announce/correct.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
        }
        .p-1{
          width: 100%;
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
        }
      }
    }
    .right{
      padding-top: 80px;
      position: relative;
      .big{
        width: 462px;
        height: 392px;
      }
      .circle{
        width: 113px;
        height: 113px;
        display: flex;
        align-items: center;
        p{
          font-size: 18px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 24px;
          width: 100%;
          text-align: center;
        }
      }
      .back-0{
        position: absolute;
        right: 46px;
        top: 0px;
        background-image: url('../../../../assets/images/G60/announce/back-0.png');
        background-size: 113px 113px;
        background-repeat: no-repeat;
        animation: move0 5s infinite;
      }
      @keyframes move0{
        0%{
          right: 46px;
          top: 0px;
        }
        50%{
          top: 50px;
        }
        100%{
          right: 46px;
          top: 0px;
        }
      }
      .back-1{
        position: absolute;
        right: 234px;
        top: 15px;
        background-image: url('../../../../assets/images/G60/announce/back-1.png');
        background-size: 113px 113px;
        background-repeat: no-repeat;
        animation: move1 2.5s infinite;
      }
      @keyframes move1{
        0%{
          right: 234px;
          top: 15px;
        }
        50%{
          top: 65px;
        }
        100%{
          right: 234px;
          top: 15px;
        }
      }
      .back-2{
        position: absolute;
        right: 400px;
        top: 120px;
        background-image: url('../../../../assets/images/G60/announce/back-2.png');
        background-size: 113px 113px;
        background-repeat: no-repeat;
        animation: move2 5s infinite;
      }
      @keyframes move2{
        0%{
          right: 400px;
          top: 120px;
        }
        50%{
          top: 170px;
        }
        100%{
          right: 400px;
          top: 120px;
        }
      }
      .back-3{
        position: absolute;
        right: 470px;
        bottom: 96px;
        background-image: url('../../../../assets/images/G60/announce/back-3.png');
        background-size: 113px 113px;
        background-repeat: no-repeat;
        animation: move3 2.5s infinite;
      }
      @keyframes move3{
        0%{
          right: 470px;
          bottom: 96px;
        }
        50%{
          bottom: 46px;
        }
        100%{
          right: 470px;
          bottom: 96px;
        }
      }
    }
  }
  .values{
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 30px;
    .value{
      margin: 0 120px;
      .imgs{
        display: flex;
        align-items: flex-end;
        margin-bottom: 12px;
        .arrow{
          width: 22px;
          height: 27px;
          margin-right: 7px;
        }
      }
      p{
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
      }
    }
  }
  .value-tip{
    width: 100%;
    text-align: center;
    margin-top: 17px;
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #F67E2A;
    line-height: 14px;
    margin-bottom: 45px;
  }
  .tables{
    width: 100%;
    display: flex;
    justify-content: space-between;
    .left{
      width: 456px;
      .tab-title{
        width: 100%;
        height: 60px;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #111111;
        line-height: 60px;
        text-align: center;
        background-image: url('../../../../assets/images/G60/announce/t-0.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .tab-line{
        width: 100%;
        display: flex;
        align-items: center;
        border-left: 1px solid #EBEBEB;
        .p-0{
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #111111;
        }
        .p-1{
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
        .l{
          width: 169px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border-right: 1px solid #EBEBEB;
          border-bottom: 1px solid #EBEBEB;
        }
        .m{
          width: 138px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border-right: 1px solid #EBEBEB;
          border-bottom: 1px solid #EBEBEB;
        }
        .r{
          width: 151px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border-right: 1px solid #EBEBEB;
          border-bottom: 1px solid #EBEBEB;
        }
        .f{
          width: 100%;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border-right: 1px solid #EBEBEB;
          border-bottom: 1px solid #EBEBEB;
          font-weight: bold;
        }
        .has-back{
          background: linear-gradient(129deg, #F3F7FF 0%, #A4C6FE 100%);
          border: 1px solid #FFFFFF !important;
          box-sizing: border-box;
        }
        .dark{
          background: #F6F6FB;
        }
      }
      .remark{
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 14px;
      }
    }
    .right{
      width: 689px;
      .remark{
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 14px;
      }
      .tab-title{
        width: 100%;
        height: 60px;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #111111;
        line-height: 60px;
        text-align: center;
        background-image: url('../../../../assets/images/G60/announce/t-1.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .tab-line{
        width: 100%;
        display: flex;
        align-items: center;
        border-left: 1px solid #EBEBEB;
        .p-0{
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #111111;
        }
        .p-1{
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
        .l{
          width: 169px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border-right: 1px solid #EBEBEB;
          border-bottom: 1px solid #EBEBEB;
        }
        .m{
          width: 138px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border-right: 1px solid #EBEBEB;
          border-bottom: 1px solid #EBEBEB;
        }
        .r{
          width: 191px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border-right: 1px solid #EBEBEB;
          border-bottom: 1px solid #EBEBEB;
        }
        .w{
          line-height: 15px !important;
          padding-top: 5px;
          box-sizing: border-box;
        }
        .r1{
          width: 193px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border-right: 1px solid #EBEBEB;
          border-bottom: 1px solid #EBEBEB;
        }
        .f{
          width: 100%;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border-right: 1px solid #EBEBEB;
          border-bottom: 1px solid #EBEBEB;
          font-weight: bold;
        }
        .has-back{
          background: linear-gradient(140deg, #FFF2D2 0%, #FEDD9E 100%);
          border: 1px solid #FFFFFF !important;
          box-sizing: border-box;
        }
        .dark{
          background: #F6F6FB;
        }
      }
    }
  }
}
.tip-word{
  font-size: 14px;
  color: #111111;
  line-height: 18px;
}
.button-tip{
  width: 86px;
  height: 27px;
  background: linear-gradient(180deg, #F1A954 0%, #FF4103 100%);
  border-radius: 4px;
  border: none;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
}
</style>