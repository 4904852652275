<template>
    <div class="container">
            <img @load="imgLoad" src="@/assets/images/service/y1.png" alt="" class="title">
            <div class="form">
            <p>请提交您的“产业园区”需求</p>
            <el-form :model="form" ref="form" :rules="rules">
                <el-form-item label="姓名" prop="memberName">
                    <el-input v-model="form.memberName" placeholder="请输入姓名" autocomplete="off" />
                </el-form-item>
                <el-form-item label="联系方式" prop="contactWay">
                    <el-input v-model="form.contactWay" placeholder="请输入联系方式" autocomplete="off" maxlength="11"/>
                </el-form-item>
                <div class="form-code">
                    <el-form-item>
                        <el-input v-model="form.code" autocomplete="off" placeholder="请输入验证码" maxlength="6"/>
                    </el-form-item>
                    <el-button v-if="showCode" type="primary"  @click="getPhoneCode">发送验证码</el-button>
                    <span v-else style="color: #B0B5C0">{{count}}S</span>
                </div>
                <el-form-item label="园区名称"  prop="companyName">
                    <el-input v-model="form.companyName" placeholder="请输入单位名称" autocomplete="off" />
                </el-form-item>
                <el-form-item label="您的需求" class="form-textarea">
                    <el-input v-model="form.demandContent" placeholder="请输入您的需求" autocomplete="off" type="textarea" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submit">立即定制园区解决方案</el-button>
                </el-form-item>
            </el-form>
        </div>
        <img src="@/assets/images/project/garden/img01.png" alt="" style="width: 1200px; margin:0 auto;margin-top: 90px;">
        <img src="@/assets/images/project/garden/img02.png" alt="" style="width:100%; margin:0 auto; margin-top: 46px;">
        <img src="@/assets/images/project/garden/img03.png" alt="" style="width: 1200px; margin:0 auto; margin-top: 38px;">
        <img src="@/assets/images/project/garden/img04.png" alt="" style="width:100%;margin:0 auto; margin-bottom: 50px;margin-left: -2px;">
        <img src="@/assets/images/service/footer2.png" alt="" style="margin-left: -2px;">
    </div>
</template>
<script>
import { getToken } from '@/utils/auth'
import { ElMessage } from 'element-plus'
import { getPhoneCode } from "@/api/user";  // 获取用户信息，更新名字头像
// import {environment} from "@/utils/consts";
import { getIndustrialPark } from "@/api/activity"

export default {
    data(){
        return{
            rules:{
               memberName:[{ required: true, message: '请输入姓名', trigger: 'blur' },],
               contactWay:[{ required: true, message: '请输入联系方式', trigger: 'blur' },],
               companyName:[{ required: true, message: '请输入单位名称', trigger: 'blur' },]
            },
            imgLoaded: false,   //背景加载
            showCustom: false,  // 定制弹窗
            customName: '', // 姓名
            customPhone: '', // 电话
            dialogFormVisible: false,
            form: {},
            count: 60,
            showCode: true, //是否显示倒计时
            timer: null,
        }
    },
    methods:{
        getPhoneCode () {
            const TIME_COUNT = 60;
            var reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
            console.log(reg.test(this.form.contactWay))
            if(reg.test(this.form.contactWay)==false){
                ElMessage.warning('您输入的手机号码格式不正确，请重新输入');
                return
            }
            getPhoneCode({phone: this.form.contactWay}).then(() => {
                if (!this.timer) {
                    this.count = TIME_COUNT;
                    this.showCode = false;
                    this.timer = setInterval(() => {
                        if (this.count > 0 && this.count <= TIME_COUNT) {
                            this.count --;
                        } else {
                            this.showCode = true;
                            clearInterval(this.timer)
                            this.timer = null
                        }
                    }, 1000)
                }
                ElMessage.success('发送成功')
            }).catch(err => {
                ElMessage.error({ message: err.message})
            })
        },
        submit(){
            const { memberName, contactWay, code,companyName } = this.form
            if(!memberName) {
                ElMessage.warning('名字不能为空')
                return
            }
            if(!contactWay) {
                ElMessage.warning('电话不能为空')
                return
            }
            if(!code) {
                ElMessage.warning('验证码不能为空')
                return
            }
            if(!companyName) {
                ElMessage.warning('单位不能为空')
                return
            }
            getIndustrialPark(this.form).then(()=>{
                ElMessage.success('提交成功')
                this.form = {}
            })
        },
        inputChange(e){  // 只能输入数字
            let a = e.key.replace(/[^\d]/g, "");
            if (!a && e.keyCode !== 8) {
                e.preventDefault();
            }
        },
        imgLoad(){
            this.imgLoaded = true
        },
        checkLogin(type){
            if(getToken() === ""){
                ElMessage.warning('你尚未登录!')
                this.$router.push({path: '/login',query: {redirect: '/industrial-garden'}})
            }
            else{
                // uat: 185580801622017 线上: 180517873975297
                if(type === 1){
                    this.$store.commit("message/SET_OPEN_SERVICE_WINDOW")
                    // let id = (environment === 'tprod' || environment === 'online' ) ? 180517873975297 : 185580801622017
                    // this.$store.dispatch("message/startCommunication", {id: id,serverId:'',title:'',quickChat:false, unlimited: true})  // 在线联系不需要需求id、标题及承接需求quickChat
                }
                else{
                    this.showCustom = true
                }
            }
        },
        goToQuestion(){
            window.open('https://www.wjx.top/vj/tRNSOxy.aspx', "_blank");
        }
    }
}
</script>

<style scoped lang="scss">
.container{
    width: 100%;
    min-width: 1200px;
    margin-top: 0;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 50px;
    .title{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .form{
        margin: -60px 360px 55px 360px;
        text-align: center;
        background: #fff;
        background: #FFFFFF;
        box-shadow: 0px 2px 72px 0px rgba(0, 0, 0, 0.07);
        border-radius: 8px;
        p{
            font-size: 44px;
            line-height: 44px;
            margin: 45px 0px;
        }
        .el-form{
            padding: 0px 20px;
        }
        .form-code{
            display: flex;
            justify-content: space-between;
        }
        button{
            background: $--color-theme;
            border: none;
            height: 40px;
            border-radius: 40px;
        }
    }
    .img-padding{
        padding: 0px 360px;
    }
}
::v-deep .el-input__inner{
                background: #f2f2f2;
            }
::v-deep .el-textarea__inner{
                background: #f2f2f2;
                height: 100%;
            }
::v-deep .el-textarea{
    height: 100px;
}
.button{
    background: #EAF2FD;
    border-radius: 10px;
    color: #4A1BD9;
    padding: 24px 26px;
    font-size: 1.2vw;
    cursor: pointer;
}
.custom-dialog{
    border-radius: 15px;
    .custom-top{
      img{
        width: 1072px;
        margin-top: -100px;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      .title{
        font-size: 40px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        color: #0E0E0E;
      }
      .container-dialog{
        width: 50%;
        margin: 20px;
        .infos{
          font-size: 28px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #4B2F08;
        }
        input{
          background: #FFFEFB;
          border: 2px solid #FFE8BD;
          border-radius: 36px;
          margin-top: 10px;
          width: 100%;
          height: 36px;
          font-size: 24px;
          text-align: center;
        }
      }
      .commit-button{
        width: 460px;
        height: 40px;
        background: #EBD692;
        border-radius: 36px;
        font-size: 30px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #563B15;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        cursor: pointer;
      }
    }
}
</style>