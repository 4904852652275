<template>
  <div class="content">
    <div class="top">
      <p class="title">感谢您的关注与支持，让我们共同探索数字科创的无限可能！</p>
      <button class="button" @click="toVip">立即定制，引领科创未来</button>
    </div>
    <div class="bottom">
      <div class="footer-line-1 flex">
        <img class="logo" src="@/assets/images/G60/announce/logo.png" />
        <div class="part" :style="`margin-right: 97px;`">
          <div class="title">
            关于我们
          </div>
          <p class="mgb-14"><a target="_blank" href="https://www.keqidao.com/personal-center">公司简介</a></p>
        </div>
        <div class="part" :style="`margin-right: 100px;`">
          <div class="title">
            隐私政策
          </div>
          <p class="mgb-14"><a target="_blank" :href="aHrefLocation('/yszc')">隐私政策</a></p>
          <p class="mgb-14"><a target="_blank" :href="aHrefLocation('/nrsm')">安全声明</a></p>
        </div>
        <!-- <div class="part" :style="`margin-right: 82px;`">
          <div class="title">
            服务与支持
          </div>
          <p class="mgb-14"><a target="_blank" href="/yhxy">产品文档</a></p>
          <p class="mgb-14"><a target="_blank" href="/yszc">常见问题解答</a></p>
          <p><a target="_blank" href="/nrsm">客户支持</a></p>
        </div> -->
        <div class="part" :style="`margin-right: 313px;`">
          <div class="title">
            联系我们
          </div>
          <p class="mgb-14">客服电话：400-6265-298</p>
          <p class="mgb-14">企业邮箱：keqidaoyunying1@keqidao.com</p>
          <p>公司地址：上海市松江区龙腾路1021号803</p>
        </div>
        <div class="part">
          <div class="flex">
            <div class="code" style="margin-right: 20px;">
              <img src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/00/22/1705911407382482" />
              <p>下载App</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="medias">
      <div class="media-content">
        <p class="tip">点击关注我们的社交媒体账号，您将能够与我们保持紧密联系，及时了解我们的最新动态。</p>
        <el-popover :width="100" trigger="hover">
          <template #reference>
            <img src="@/assets/images/G60/announce/wx-icon.png" style="width: 30px;height: 24px;margin-right: 41px;"/>
          </template>
          <div class="flex-rc">
            <img src="@/assets/images/G60/announce/wx.png" style="width: 90px;height: 90px;margin: 0 auto;display: inline-block;" />
          </div>
        </el-popover>
        <a style="margin-right: 41px;" href="https://www.zhihu.com/org/qi-dao-shang-hai-wang-luo-ji-zhu-you-xian-gong-si" target="_blank">
          <img src="@/assets/images/G60/announce/zhi-icon.png" style="width: 28px;height: 28px;cursor: pointer;"/>
        </a>
        <el-popover :width="100" trigger="hover">
          <template #reference>
            <img src="@/assets/images/G60/announce/tik-icon.png" style="width: 28px;height: 28px;margin-right: 41px;"/>
          </template>
          <div class="flex-rc">
            <img src="@/assets/images/G60/announce/tik.png" style="width: 90px;height: 90px;margin: 0 auto;display: inline-block;" />
          </div>
        </el-popover>
        <a style="margin-right: 41px;" href="https://www.toutiao.com/c/user/token/MS4wLjABAAAA8qP5TPqmC9lFX35X-A2nf2d7vyKjFE-p4QXicntiHbY/?" target="_blank">
          <img src="@/assets/images/G60/announce/tou-icon.png" style="width: 28px;height: 28px;cursor: pointer;"/>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { routerWindow, aHrefLocation } from "@/utils/tool";
export default {
  methods: {
    routerWindow,
    aHrefLocation,
    toVip(){
      window.open('https://www.keqidao.com/vip')
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;

  .top {
    width: 100%;
    height: 370px;
    background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/00/22/1705910751570346');
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 99px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;

    .title {
      font-size: 32px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #111111;
      line-height: 45px;
      margin-bottom: 60px;
    }

    .button {
      width: 202px;
      height: 48px;
      background: linear-gradient(180deg, #F1A954 0%, #FF4103 100%);
      border-radius: 4px;
      cursor: pointer;
      border: none;
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
    }
  }

  .bottom {
    display: flex;
    justify-content: center;
    .footer-line-1 {
      width: 1200px;
      box-sizing: border-box;
      padding: 60px 0 40px;
      justify-content: center;
      .logo{
        width: 106px;
        height: 58px;
        margin-right: 80px;
      }
      .part {
        .code {
          img {
            width: 80px;
            height: 80px;
            background: #D8D8D8;
            border-radius: 4px;
            overflow: hidden;
          }

          p {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 14px;
            margin-top: 3px;
            width: 80px;
            text-align: center;
            white-space: nowrap;
          }
        }

        .title {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          color: #111111;
          line-height: 20px;
          margin-bottom: 21px;
        }

        p {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
          white-space: nowrap;
        }

        a {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
          white-space: nowrap;
        }

        a:hover {
          color: $--color-theme !important;
        }

        a:visited {
          color: #333333;
        }

        .mgb-14 {
          margin-bottom: 14px;
        }
      }
    }
  }
  .medias{
    display: flex;
    justify-content: center;
    padding-bottom: 60px;
    .media-content{
      width: 1200px;
      .tip{
        font-size: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
        margin-bottom: 28px;
      }
    }
  }
}</style>