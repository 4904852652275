<template>
  <div class="content">
    <p class="title">联系我们</p>
    <p class="tip">想要了解更多关于我们的产品或定制专属SaaS软件服务？欢迎随时联系我们，我们的专业团队将竭诚为您提供咨询和个性化服务。</p>
    <div class="bottom">
      <div class="left" v-if="showForm">
        <div class="form-item">
          <p class="item"><span>*</span>姓名：</p>
          <input v-model="form.name" class="input" maxlength="30" />
        </div>
        <div class="form-item">
          <p class="item"><span>*</span>电话：</p>
          <input v-model="form.phone" class="input" maxlength="30" />
        </div>
        <div class="form-item">
          <p class="item"><span>*</span>公司：</p>
          <input v-model="form.company" class="input" maxlength="30" />
        </div>
        <div class="form-item">
          <p class="item"><span>*</span>留言：</p>
          <textarea v-model="form.message" class="textarea" maxlength="1000"></textarea>
        </div>
        <div class="flex-rc">
          <button class="button" @click="submit">确认提交</button>
        </div>
      </div>
      <div class="left success" v-else>
        <div class="success-window">
          <img src="@/assets/images/G60/announce/success-icon-1.png" class="icon" />
          <p class="p-title">提交成功!</p>
          <p class="word">感谢您对探角的关注与信任，</p>
          <p class="word">我们的客户经理将尽快与您取得联系，期待与您携手共创美好未来！</p>
        </div>
      </div>
      <img src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/00/22/1705925995510875" style="width: 589px;height: 368px;padding-top: 10px;" />
    </div>
  </div>
</template>
<script>
import { ElMessage } from 'element-plus'
import { activityRegister } from "@/api/activity";


export default {
  data() {
    return {
      form: {
        name: '',
        phone: '',
        company: '',
        message: '',
      },
      showForm: true,
    }
  },
  methods: {
    clearForm() {
      this.form = {
        name: '',
        phone: '',
        company: '',
        message: '',
      }
      this.showForm = false
      setTimeout(() => {
        this.showForm = true
      }, 3000)
    },
    submit() {
      if (!this.form.name) {
        ElMessage.warning('请输入姓名')
        return
      }
      else if (!this.form.phone) {
        ElMessage.warning('请输入电话')
        return
      }
      else if (!this.form.company) {
        ElMessage.warning('请输入公司')
        return
      }
      else if (!this.form.message) {
        ElMessage.warning('请输入留言')
        return
      }
      const param = {
        activityName: '数字科创中心联系我们',
        activityType: '36',
        code: '123',
        isCreateMember: false,
        contactWay: this.form.phone,
        companyName: this.form.company,
        name: this.form.name,
        jsonStr: JSON.stringify({
          '留言': this.form.message,
        }),
      }
      activityRegister(param).then((res) => {
        if (res.code === "Sx200") {
          this.clearForm()
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  padding-bottom: 100px;
  .title {
    width: 100%;
    text-align: center;
    font-size: 32px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #111111;
    line-height: 45px;
    margin-bottom: 10px;
  }

  .tip {
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 14px;
    margin-bottom: 70px;
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    .success{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .left {
      width: 565px;
      height: 420px;
      background: #FFFFFF;
      box-shadow: 0px 2px 10px 0px #EEEEEE;
      box-sizing: border-box;
      padding: 37px 0;

      .success-window {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .icon {
          width: 40px;
          height: 40px;
          margin-bottom: 10px;
        }

        .p-title {
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #111111;
          line-height: 22px;
          margin-bottom: 33px;
        }

        .word {
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #111111;
          line-height: 23px;
        }
      }

      .button {
        width: 179px;
        height: 40px;
        background: linear-gradient(180deg, #F1A954 0%, #FF4103 100%);
        border-radius: 4px;
        border: none;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        cursor: pointer;
      }

      .form-item {
        display: flex;
        width: 100%;
        padding-bottom: 18px;
        padding-left: 44px;

        .item {
          width: 57px;
          text-align: right;
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #111111;
          line-height: 14px;
          padding-top: 12px;

          span {
            color: #F22222;
          }
        }

        .input {
          width: 420px;
          height: 50px;
          border-radius: 4px;
          border: 1px solid #E2E8EF;
          background-color: #ffffff;
          font-size: 14px;
          color: #111111;
          box-sizing: border-box;
          padding: 0 15px;
          line-height: 38px;
        }

        .textarea {
          width: 420px;
          height: 84px;
          border-radius: 4px;
          border: 1px solid #E2E8EF;
          background-color: #ffffff;
          font-size: 14px;
          color: #111111;
          box-sizing: border-box;
          padding: 15px;
          line-height: 20px;
          resize: none;
        }
      }
    }
  }
}</style>