<template>
  <img src="../../../assets/images/company/company-detail.png" alt="企业详情" srcset="" class="left-img">
  <div class="company-card">
    <div class="flex-cc name-line">
      <p class="text-ol-ell fs-18" v-html="delHtmlTag(company.name)"></p>
      <button class="send-button fc-fff fs-14" @click="openSendEmail(company)">发邮件</button>
    </div>
    <!-- 搜企业 -->
    <div class="flex-w my-info-box" v-if="type === '0'">
      <div class="info-part text-othl-ell" v-if="company.legalRepresentative" style="width: 100%;margin: 0;margin-bottom: 10px;">
        <span class="fc-999" style="white-space: nowrap;">法定代表人：</span><span v-html="delHtmlTag(company.legalRepresentative)"></span>
      </div>
      <div class="info-part text-ol-ell" v-if="company.organizationCode">
        <span class="fc-999">企业代码：</span>{{company.organizationCode}}
      </div>
      <div class="info-part text-ol-ell" v-if="companyPhone(company)">
        <span class="fc-999">电&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;话：</span>{{companyPhone(company)}}
      </div>
      <div class="info-part text-ol-ell" v-if="company.industry">
        <span class="fc-999">行&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;业：</span><span v-html="delHtmlTag(company.industry)"></span>
      </div>
      <div class="info-part text-ol-ell" v-if="company.registerCapital && company.registerCapital !== '-'">
        <span class="fc-999">注册资本：</span>{{company.registerCapital}}
      </div>
      <div class="info-part text-ol-ell" v-if="companyEmail(company)">
        <span class="fc-999">邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱：</span>{{companyEmail(company)}}
      </div>
      <div class="info-part text-ol-ell" v-if="company.establishDate && company.establishDate.length > 1">
        <span class="fc-999">成立日期：</span>{{company.establishDate}}
      </div>
      <div class="info-part text-othl-ell" v-if="company.address" style="width: 100%;margin: 0;margin-bottom: 10px;">
        <span class="fc-999">地&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;址：</span>{{company.address}}
      </div>
    </div>
    <!-- 搜资本 -->
    <div class="flex-w my-info-box" v-else-if="type === '2'">
      <div class="info-part text-ol-ell" v-if="company.institutionalHeadquarters">
        <span class="fc-999">机构总部：</span><span v-html="delHtmlTa(company.institutionalHeadquarters)"></span>
      </div>
      <div class="info-part text-ol-ell" v-if="company.natureOfOrganization">
        <span class="fc-999">机构性质：</span><span v-html="delHtmlTag(company.natureOfOrganization)"></span>
      </div>
      <div class="info-part text-ol-ell" v-if="company.establishDate">
        <span class="fc-999">创立时间：</span>{{company.establishDate}}
      </div>
      <div class="info-part text-ol-ell" v-if="company.phone">
        <span class="fc-999">电话号码：</span>{{company.phone}}
      </div>
      <div class="info-part text-ol-ell" v-if="company.investmentStage">
        <span class="fc-999">投资阶段：</span><span v-html="delHtmlTag(company.investmentStage)"></span>
      </div>
      <div class="info-part text-ol-ell" v-if="company.fax">
        <span class="fc-999">传真号码：</span><span v-html="delHtmlTag(company.fax)"></span>
      </div>
      <div class="info-part text-ol-ell" v-if="company.address">
        <span class="fc-999">地&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;址：</span><span v-html="delHtmlTag(company.address)"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { delHtmlTag } from "@/utils/tool";
export default {
  props:{
    company:{
      default: {}
    }
  },
  data(){
    return{
    }
  },
  computed:{
    type(){
      // 企业类型
      if(this.$route.query.type) return this.$route.query.type + ''
      return '0'
    }
  },
  methods:{
    delHtmlTag,
    openSendEmail(item){
      // 发送邮件
      this.$store.dispatch("popup/setPopup", {
        type: 5,
        parameter: item,
      });
    },
    companyEmail(item){
        // Email修改
        let email = item.email
        if(email === '暂无信息' || !email){   // 先判断email
            let aqcEmail = item.aqcEmail
            if(!aqcEmail || aqcEmail.length === 0 || aqcEmail.length === 1) return false
            return aqcEmail
        }
        const reg = new RegExp("'",'g');//g,表示全部替换。
        if(email.indexOf("'") !== -1 || email.indexOf('"') !== -1) email = JSON.parse(email.replace(reg,'"'))[0]
        return email
    },
    companyPhone(item){
      // 判断电话
      let tycPhone = item.tycPhone + ''
      if(tycPhone === '暂无信息' || !item.tycPhone || !tycPhone || tycPhone == 'null'){   // 先判断tycPhone
        let contactPhone = item.contactPhone + ''
        if(!contactPhone || !item.contactPhone || contactPhone.length === 0 || contactPhone + ''.indexOf('无') !== -1 || contactPhone == 'null') return false
        return contactPhone
      }
      return tycPhone.indexOf('无') === -1 ? tycPhone : false
    },
  }
}
</script>

<style lang="scss" scoped>
.left-img{
  position: absolute;
  width: 41px;
  height: 110px;
  top: 10;
  z-index: 0;
  left: -36px;
}
.company-card{
  width: 100%;
  z-index: 100;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 20px 29px 40px 30px;
  position: relative;
  .name-line{
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
    .send-button{
      width: 96px;
      height: 34px;
      background: $--color-theme;
      box-shadow: 0px 2px 4px 1px rgba(2,58,95,0.15);
      border-radius: 17px;
      text-align: center;
      line-height: 34px;
      border: none;
    }
  }
  .info-part{
    width: 335px;
    font-size: 14px;
    margin-bottom: 10px;
    margin-right: 100px;
    display: flex;
    align-items: flex-start;
  }
  .my-info-box{
    width: 100%;
    background: #F3F8FF;
    box-sizing: border-box;
    padding: 20px 20px 10px 20px;
    padding-right: calc(100% - 1050px);
    position: relative;
  }
  // .my-info-box::after{
  //   content: '';
  //   width: 1px;
  //   height: calc(100% - 50px);
  //   background: #EEEEEE;
  //   border-radius: 1px;
  //   position: absolute;
  //   left: 400px;
  //   top: 25px;
  // }
}
</style>